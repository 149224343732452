import type { FC } from 'react';
import { SyntheticEvent, useCallback, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import {
  Checkbox,
  FieldArray,
  FormProvider,
  useFormContext,
} from '@fleet/shared';
import { TransLabel } from 'i18n/trans/label';
import { renderToString } from 'react-dom/server';
import { TransField } from 'i18n/trans/field';
import { TextField } from '@fleet/shared/form';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useSelector } from 'store/utils';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: '15rem',
    },
    bgGray: {
      background: theme.palette.background.default,
    },
  }),
  {
    name: 'SmsFulfillment',
  }
);

interface SmsFulfillmentProps {
  formId?: string;
  purchaserPhone?: string;
  isInline?: boolean;
  onSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => void;
}

export const SmsFulfillment: FC<SmsFulfillmentProps> = ({
  formId,
  onSubmit,
  isInline,
  purchaserPhone,
}) => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { purchaser } = useSelector(currentBookingSelector)!;
  const classes = useStyles();
  const phone =
    purchaserPhone ?? purchaser.contactInformation.phoneNumber.value;
  const sendToPayerLabel = useMemo(
    () =>
      renderToString(
        <TransLabel
          i18nKey="sendToPayer"
          values={{ postfix: phone && `(${phone})` }}
        />
      ),
    [phone]
  );
  const onSendToPayerChange = useCallback(
    (isChecked: boolean) => {
      form.change('smsConfirmationRecipient', isChecked ? [phone] : undefined);
    },
    [form, phone]
  );

  const validatePhoneNumber = useCallback(
    (value: string) => {
      if (!/^\+\d+$/.test(value)) {
        return t('field.invalidPhoneNumber', 'Invalid phone number format');
      }
      return null;
    },
    [t]
  );

  return (
    <Card elevation={0} className={classes.root}>
      <FormProvider {...form}>
        <CardContent
          id={formId}
          component="form"
          className={classNames({ [classes.bgGray]: isInline })}
          onSubmit={onSubmit}
        >
          <Typography variant="subtitle">
            <TransSubtitle i18nKey="sendBookingConfirmationViaSms" />
          </Typography>
          {phone && (
            <Checkbox
              sx={{ mt: 3 }}
              onChange={(e) => onSendToPayerChange(e.target.checked)}
              label={sendToPayerLabel}
            />
          )}
          <FieldArray name="additionalSmsConfirmationRecipients">
            {({ fields }) => (
              <>
                {fields.value?.map((_, idx) => (
                  <Stack
                    direction="row"
                    gap={2}
                    mt={3}
                    mb={3}
                    maxWidth={500}
                    key={idx}
                  >
                    <TextField
                      type="text"
                      placeholder="+"
                      name={`${fields.name}[${idx}]`}
                      label={<TransField i18nKey="mobileNumber" />}
                      required
                      onChange={(e) => {
                        let phoneNumber = e.target.value;
                        if (!phoneNumber.startsWith('+')) {
                          phoneNumber = '+' + phoneNumber;
                        }
                        fields.update(idx, phoneNumber);
                      }}
                      validate={validatePhoneNumber}
                    />
                    <IconButton
                      sx={{ alignSelf: 'flex-start', top: '24px' }}
                      onClick={() => fields.remove(idx)}
                    >
                      <Icon name="close" />
                    </IconButton>
                  </Stack>
                ))}
                <Divider sx={{ mb: 3, mt: 3 }} />
                <Button
                  variant="contained"
                  startIcon={<Icon name="add" />}
                  onClick={() => fields.push('')}
                >
                  <TransButton i18nKey="addNewRecipient" />
                </Button>
              </>
            )}
          </FieldArray>
        </CardContent>
      </FormProvider>
    </Card>
  );
};
