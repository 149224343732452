import { Icon } from '@fleet/shared';
import { useForm } from '@fleet/shared/form';
import { Button } from '@fleet/shared/mui';
import { Divider, Stack, Typography } from '@mui/material';
import { AlertCard } from 'components/AlertCard';
import { BookingTotalFee } from 'components/BookingTotalFee';
import { CartTotal } from 'components/CartTotal';
import { SearchTabsContext } from 'components/SearchTabsContext';
import { getBooking } from 'features/booking/bookingActions';
import {
  bookingExpiredSelector,
  currentBookingSelector,
  currentTripsSelector,
  isTravelPassBookingSelector,
} from 'features/booking/bookingSelectors';
import { bookingCheckoutLoadingSelector } from 'features/loading/loadingSelectors';
import { PassengerDetailsPayload } from 'features/trip/tripActions';
import { paymentStatusSelector } from 'features/trip/tripSelector';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransTitle } from 'i18n/trans/title';
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { JourneyOverview } from 'routes/tickets/checkout/JourneyOverview';
import { PayByLinkModal } from 'routes/tickets/checkout/PayByLinkModal';
import { TravelPassOverview } from 'routes/tickets/checkout/TravelPassOverview';
import { useDispatch, useSelector } from 'store/utils';
import { IS_DS_AT, IS_IMS_AT } from 'utils/common';
import { getOnDemandServiceTexts } from 'utils/trip';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { renderToString } from 'react-dom/server';
import { v4 } from 'uuid';
import keyBy from 'lodash/keyBy';
import { OnHoldBookingModal } from 'components/OnHoldBookingModal';
import { PayerDetailsForm } from 'components/PayerDetailsForm';
import {
  useConfirmationSend,
  usePurchaserInfo,
  useShowValidations,
  useSubmitBooking,
} from 'utils/overview';
import { TicketFulfillment } from 'components/ticketFulfillment/TicketFulfillment';
import { PaymentMethods } from 'components/PaymentMethods';

export interface TicketSelectionPayload {
  includeTickets: boolean;
  emailConfirmationRecipient: Array<string>;
  additionalEmailConfirmationRecipients: Array<string>;
  smsConfirmationRecipient: Array<string>;
  additionalSmsConfirmationRecipients: Array<string>;
  passengerSelection: Array<string>;
}

interface OverviewProps {
  goToNextStep: () => void;
  submitLabel: ReactNode;
}

export const Overview: FC<OverviewProps> = ({ goToNextStep, submitLabel }) => {
  const [paymentMethod, setPaymentMethod] = useState(
    IS_DS_AT ? 'external' : ''
  );
  const currentTrips = useSelector(currentTripsSelector);
  const paymentStatus = useSelector(paymentStatusSelector);
  const dispatch = useDispatch();
  const booking = useSelector(currentBookingSelector)!;
  const isTravelPassBooking = useSelector(isTravelPassBookingSelector);
  const loading = useSelector(bookingCheckoutLoadingSelector);
  const isBookingExpired = useSelector(bookingExpiredSelector);
  const { updateTab, currentTab } = useContext(SearchTabsContext);
  const { bookedTrips } = booking;
  const totalToPay = useMemo(() => {
    if (booking?.provisionalPrice) {
      return `${booking.provisionalPrice.amount} ${booking.provisionalPrice.currency}`;
    }
  }, [booking]);
  const handleConfirmationSend = useConfirmationSend(booking.id);
  const { form: ticketFulfillmentForm } = useForm<TicketSelectionPayload>({
    onSubmit: handleConfirmationSend,
    subscription: { invalid: true },
  });
  const showSuccessPage = useCallback(async () => {
    updateTab({ isCompleted: true });
    if (paymentMethod === 'payByLink') {
      dispatch(getBooking(booking.id));
    }
    goToNextStep();
  }, [booking.id, dispatch, goToNextStep, paymentMethod, updateTab]);
  const onSubmit = useSubmitBooking(
    booking.id,
    ticketFulfillmentForm,
    paymentMethod,
    showSuccessPage
  );
  const {
    form: payerDetailsForm,
    handleSubmit,
    values,
    invalid,
  } = useForm<PassengerDetailsPayload>({
    subscription: { values: true, invalid: true },
    onSubmit,
  });
  const handleAddJourney = useCallback(() => {
    updateTab({
      addingAdditionalJourney: true,
      name: renderToString(<TransSubtitle i18nKey="multipleJourneys" />),
      journeyRefs: Object.keys(keyBy(bookedTrips, 'journeyRef')),
      params: {
        ...currentTab?.params,
        passengerSpecifications: [
          {
            type: 'PERSON',
            externalReference: v4(),
          },
        ],
      },
    });
  }, [bookedTrips, currentTab?.params, updateTab]);
  const purchaserInfo = usePurchaserInfo(payerDetailsForm, values);
  const showValidations = useShowValidations(payerDetailsForm, invalid);
  const onDemandTexts = bookedTrips ? getOnDemandServiceTexts(bookedTrips) : [];

  return (
    <>
      <Stack spacing={2} divider={<Divider />}>
        <>
          <Typography variant="h2">
            <TransTitle i18nKey="overview" />
          </Typography>
          {!!onDemandTexts.length && (
            <AlertCard
              title={<TransAlert i18nKey="advanceOrderRequired" />}
              message={onDemandTexts}
            />
          )}
          {isTravelPassBooking ? (
            <>
              <TravelPassOverview />
              <BookingTotalFee isOverview />
            </>
          ) : (
            <>
              <Stack spacing={1}>
                {currentTrips.map((trip) => (
                  <JourneyOverview
                    isOverview
                    trip={trip}
                    key={trip.id}
                    canDelete={currentTrips.length > 1}
                  />
                ))}
                <BookingTotalFee isOverview />
              </Stack>
              <Button
                variant="outlined"
                sx={{ alignSelf: 'flex-start' }}
                startIcon={<Icon name="add" />}
                label={<TransButton i18nKey="addJourney" />}
                onClick={handleAddJourney}
              />
            </>
          )}

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ py: 3 }}
          >
            <Typography variant="h2">
              <TransField i18nKey="totalToPay" />:
            </Typography>
            <Typography variant="title">{totalToPay}</Typography>
          </Stack>
        </>
        <PayerDetailsForm form={payerDetailsForm} handleSubmit={handleSubmit} />
        <TicketFulfillment
          ticketFulfillmentForm={ticketFulfillmentForm}
          isPassengerDetailsFormInvalid={invalid}
          showValidations={showValidations}
          purchaserInfo={purchaserInfo}
          isSaleFlow={true}
        />
        <PaymentMethods
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
        />
      </Stack>
      <CartTotal>
        <>
          {IS_IMS_AT && <OnHoldBookingModal goToNextStep={goToNextStep} />}
          <Button
            variant="text"
            onClick={() => payerDetailsForm.restart()}
            label={<TransButton i18nKey="resetFields" />}
            disabled={isBookingExpired}
          />
          <Button
            variant="contained"
            type="submit"
            loading={loading}
            disabled={isBookingExpired || !paymentMethod}
            form="purchaserDetails"
            label={
              <>
                <Icon name="check" sx={{ mr: 1 }} />
                {submitLabel}
              </>
            }
          />
        </>
      </CartTotal>
      {IS_IMS_AT && paymentStatus && (
        <PayByLinkModal onPaymentSuccess={showSuccessPage} />
      )}
    </>
  );
};
