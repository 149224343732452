import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import { OverflowEllipsis } from 'components/OverflowEllipsis';
import { stopsSelector } from 'features/classification/classificationSelectors';
import { RECENT_TRIPS_LS_KEY, useLocalStorage } from 'hooks/useLocalStorage';
import {
  FC,
  MouseEvent,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Icon } from '@fleet/shared/mui';
import { IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { BookmarkedTrip, RecentTrip } from 'features/user/userActions';
import { Tooltip } from '@fleet/shared';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useSelector } from 'store/utils';

interface TripsCarouselProps {
  title: ReactNode;
  trips?: Array<BookmarkedTrip>;
  addToFavorites?: (trip: { origin: string; destination: string }) => void;
  removeFromFavorites?: (pairId: string) => void;
  onTripClick: (trip: RecentTrip | BookmarkedTrip) => void;
}

const useStyles = makeStyles(
  (theme) => ({
    tripsTabs: {
      position: 'relative',
      top: '-10px',
      '& .MuiTabs-indicator': {
        display: 'none',
      },
      '& .MuiTab-root': {
        padding: '10px 7px 2px 0',
        '&:not(:last-of-type)': {
          marginRight: '9px',
        },
      },
    },
    scrollBtnWrapper: {
      top: 'calc(50% - 0.75rem)',
      position: 'absolute',
      zIndex: 1,
      display: 'inline-flex',
      alignItems: 'center',
      '&:empty': {
        display: 'none',
      },
      '& > .MuiButtonBase-root': {
        height: 32,
        background: alpha(theme.palette.primary.light, 0.05),
        '&.Mui-disabled': {
          display: 'none',
        },
        '&:hover': {
          background: alpha(theme.palette.primary.light, 0.1),
        },
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        width: '3rem',
        height: '4rem',
      },
      '&:first-of-type': {
        left: 0,
        '&:before': {
          right: '-1rem',
          backgroundImage: `linear-gradient(to left, rgba(246, 246, 246, 0), 30%,rgb(246, 246, 246))`,
        },
      },
      '&:last-of-type': {
        right: 0,

        '&:before': {
          left: '-1rem',
          backgroundImage: `linear-gradient(to right, rgba(246, 246, 246, 0),30%,rgb(246, 246, 246))`,
        },
      },
    },
    tripCard: {
      margin: '0.5rem',
      padding: '1.25rem',
      overflow: 'visible',
      position: 'relative',
      fontWeight: 'normal',
      '&:hover': {
        '& $favBtn, & $deleteBtn': {
          opacity: 1,
        },
      },
      '& $favBtn, & $deleteBtn': {
        zIndex: 1,
        position: 'absolute',
        top: '-0.5rem',
        opacity: 0,
      },
    },
    favBtn: {
      left: '-0.375rem',
      stroke: theme.palette.primary.main,
      color: 'white',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    deleteBtn: {
      right: '-0.375rem',
      '&, &:hover': {
        color: theme.palette.error.main,
      },
    },
    active: {},
  }),
  { name: 'TripsCarousel' }
);

export const TripsCarousel: FC<TripsCarouselProps> = ({
  title,
  trips,
  addToFavorites,
  removeFromFavorites,
  onTripClick,
}) => {
  const classes = useStyles();
  const stops = useSelector(stopsSelector);
  const { initialValue: lsResentTrips, setData } = useLocalStorage<
    Array<RecentTrip>
  >({
    key: RECENT_TRIPS_LS_KEY,
    getDefaultValue: (value) => value || [],
  });
  const [recentTrips, setRecentTrips] = useState(lsResentTrips);
  const preparedTrips = useMemo(() => {
    const stopCodes = stops.map(({ id }) => id);
    return (trips ?? recentTrips).filter(({ origin, destination }) =>
      [origin, destination].every((stop) => stopCodes.includes(stop.code))
    );
  }, [recentTrips, stops, trips]);
  const handleAddToFavorite = useCallback(
    (trip: RecentTrip) => (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      addToFavorites?.({
        origin: trip.origin.code,
        destination: trip.destination.code,
      });
    },
    [addToFavorites]
  );
  const handleRemoveTrip = useCallback(
    (trip: RecentTrip, tripIdx: number) => (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (removeFromFavorites) {
        removeFromFavorites((trip as BookmarkedTrip).id);
      } else {
        const updatedRecentTrips = recentTrips.filter(
          (_, idx) => idx !== tripIdx
        );
        setData(updatedRecentTrips);
        setRecentTrips(updatedRecentTrips);
      }
    },
    [recentTrips, removeFromFavorites, setData]
  );

  return (
    <Stack spacing={1}>
      <Typography variant="h2">{title}</Typography>
      {!!preparedTrips.length && (
        <Tabs
          value={0}
          variant="scrollable"
          scrollButtons="auto"
          className={classes.tripsTabs}
          ScrollButtonComponent={(props) => (
            <div className={classes.scrollBtnWrapper}>
              <IconButton {...props}>
                <Icon color="primary" name={`direction-${props.direction}`} />
              </IconButton>
            </div>
          )}
        >
          {preparedTrips.map((trip, idx) => {
            const { origin, destination } = trip;
            return (
              <Tab
                key={idx}
                value={idx}
                onClick={() => onTripClick(trip)}
                label={
                  <Card className={classes.tripCard} elevation={2}>
                    {addToFavorites && (
                      <Tooltip
                        content={<TransSubtitle i18nKey="addToFavorites" />}
                      >
                        <Icon
                          className={classes.favBtn}
                          name="star-filled"
                          onClick={handleAddToFavorite(trip)}
                        />
                      </Tooltip>
                    )}
                    <Tooltip
                      content={
                        <TransSubtitle
                          i18nKey={
                            removeFromFavorites
                              ? 'removeFromFavorites'
                              : 'removeTrip'
                          }
                        />
                      }
                    >
                      <Icon
                        className={classes.deleteBtn}
                        name="trash"
                        onClick={handleRemoveTrip(trip, idx)}
                      />
                    </Tooltip>
                    <OverflowEllipsis
                      variant="body1"
                      maxWidth="18rem"
                      content={[origin.name, destination.name].join(' - ')}
                    />
                  </Card>
                }
              />
            );
          })}
        </Tabs>
      )}
    </Stack>
  );
};
