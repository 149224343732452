import { createReducer } from '@reduxjs/toolkit';
import {
  Journey,
  JourneyLink,
  PassengerOfferSelection,
  PaymentStatus,
  TravelPass,
  TripLeg,
  TripOffer,
} from 'dto/trip';
import {
  clearOfferSelection,
  clearPaymentStatus,
  getPaymentStatus,
  payByLink,
  resetSearch,
  resetTripOffers,
  searchTravelPasses,
  searchTrips,
  selectTravelPassOffer,
  selectTripOffer,
  setPdfDownload,
  showTripsResultPage,
  showTripStops,
  unselectOffer,
  updateOfferSelection,
} from 'features/trip/tripActions';
import omit from 'lodash/omit';

interface TripState {
  list: Array<Journey>;
  links: Array<JourneyLink>;
  travelPasses: Array<TravelPass>;
  showStopsFor?: TripLeg;
  downloadPdfTickets: boolean;
  paymentStatus?: PaymentStatus;
  selectedOffers: {
    reference?: string;
    trips: Array<TripOffer>;
    tripsSelectionMap: Record<string, Array<PassengerOfferSelection>>;
    travelPass?: TravelPass;
  };
  nextAvailableDepartureDate?: string;
}

const initialState: TripState = {
  list: [],
  links: [],
  travelPasses: [],
  downloadPdfTickets: true,
  selectedOffers: {
    trips: [],
    tripsSelectionMap: {},
  },
};

export const tripReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(searchTravelPasses.pending, (state) => {
      state.travelPasses = [];
    })
    .addCase(resetSearch, (state) => {
      state.list = [];
      state.travelPasses = [];
      state.selectedOffers = initialState.selectedOffers;
    })
    .addCase(searchTravelPasses.fulfilled, (state, action) => {
      state.travelPasses = action.payload.nonTripOffers;
    })
    .addCase(showTripStops, (state, action) => {
      state.showStopsFor = action.payload;
    })
    .addCase(setPdfDownload, (state, action) => {
      state.downloadPdfTickets = action.payload;
    })
    .addCase(resetTripOffers, (state, action) => {
      state.selectedOffers.reference = action.payload;
      state.selectedOffers = initialState.selectedOffers;
    })
    .addCase(selectTripOffer, (state, action) => {
      const { offers, reference } = action.payload;
      state.selectedOffers.reference = reference;
      state.selectedOffers.trips = offers;
    })
    .addCase(selectTravelPassOffer, (state, action) => {
      state.selectedOffers.travelPass = action.payload;
    })
    .addCase(updateOfferSelection, (state, action) => {
      const { offerId, selections } = action.payload;
      const { tripsSelectionMap } = state.selectedOffers;
      const selectedOfferIds = Object.keys(tripsSelectionMap);

      if (offerId) {
        state.selectedOffers.tripsSelectionMap[offerId] = selections;
      } else {
        state.selectedOffers.tripsSelectionMap = selectedOfferIds.reduce(
          (selectionMap, offerId) => ({
            ...selectionMap,
            [offerId]: selections,
          }),
          {}
        );
      }
    })
    .addCase(unselectOffer, (state, { payload: offerId }) => {
      const { trips, tripsSelectionMap } = state.selectedOffers;
      state.selectedOffers.trips = trips.filter(({ id }) => id !== offerId);
      state.selectedOffers.tripsSelectionMap = omit(tripsSelectionMap, offerId);
    })
    .addCase(payByLink.fulfilled, (state) => {
      state.paymentStatus = PaymentStatus.pending;
    })
    .addCase(getPaymentStatus.fulfilled, (state, { payload }) => {
      state.paymentStatus = payload;
    })
    .addCase(clearPaymentStatus, (state) => {
      state.paymentStatus = undefined;
    })
    .addMatcher(
      (action) =>
        [clearOfferSelection.type, unselectOffer.type].includes(action.type),
      (state, { payload: offerId }) => {
        state.selectedOffers.tripsSelectionMap = omit(
          state.selectedOffers.tripsSelectionMap,
          offerId
        );
      }
    )
    .addMatcher(
      (action) =>
        [searchTrips.pending.type, showTripsResultPage.pending.type].includes(
          action.type
        ),
      (state) => {
        state.list = [];
        state.selectedOffers = initialState.selectedOffers;
      }
    )
    .addMatcher(
      (action) =>
        [
          searchTrips.fulfilled.type,
          showTripsResultPage.fulfilled.type,
        ].includes(action.type),
      (state, action) => {
        state.list = action.payload.journeys;
        state.links = action.payload.links;
        state.nextAvailableDepartureDate =
          action.payload.nextAvailableDepartureDate;
      }
    );
});
