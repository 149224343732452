import { Header as FleetHeader, Icon } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { setShowRetalerModal } from 'features/user/userActions';
import {
  selectApplications,
  selectUser,
  selectUserUnits,
} from 'features/user/userSelector';
import { TransSubtitle } from 'i18n/trans/subtitle';
import React, { useCallback, useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { IS_IMS_AT } from 'utils/common';

const useStyles = makeStyles(
  (theme) => ({
    organizationSelect: {
      cursor: 'pointer',
      '&:hover > *': {
        color: theme.palette.action.hover,
      },
    },
  }),
  { name: 'Header' }
);
export const Header = () => {
  const classes = useStyles();
  const auth = useAuth();
  const user = useSelector(selectUser);
  const { organizationId } = useSelector(selectUserUnits);
  const applications = useSelector(selectApplications);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const steps = useMemo(
    () => [
      {
        label: <TransSubtitle i18nKey="sellTicketsAndPasses" />,
        path: '/search',
        basePath: '/search',
      },
      {
        label: <TransSubtitle i18nKey="bookingSearch" />,
        path: '/bookings',
        basePath: '/bookings',
      },
      ...(IS_IMS_AT
        ? [
            {
              label: <TransSubtitle i18nKey="salesReports" />,
              path: '/sales-reports',
              basePath: '/sales-reports',
            },
          ]
        : []),
    ],
    []
  );
  const currentOrganization = useMemo(
    () => user?.organizations?.find(({ id }) => id === organizationId),
    [organizationId, user?.organizations]
  );
  const handleMenuClick = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const showRetailerSelectModal = useCallback(() => {
    dispatch(setShowRetalerModal(true));
  }, [dispatch]);

  return (
    <FleetHeader
      steps={steps}
      backLinks={[]}
      pathname={pathname}
      onLogout={auth.signoutRedirect}
      helpPath="https://turnit.atlassian.net/wiki/spaces/HM/pages/169377793/Agent+Tool"
      onMenuClick={handleMenuClick}
      additionalControls={
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          className={classes.organizationSelect}
          onClick={showRetailerSelectModal}
        >
          <Icon name="profile" width={16} height={20} />
          <Typography>{currentOrganization?.name}</Typography>
        </Stack>
      }
      moduleItems={applications}
    />
  );
};
