import { FormProvider, useForm } from '@fleet/shared';
import {
  DateField,
  RadioGroupField,
  SelectField,
  TextField,
} from '@fleet/shared/form';
import { Icon, Tooltip } from '@fleet/shared/mui';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BookingSearchFilterForm, PaymentStatusFilter } from 'dto/booking';
import { ClassificationGroup } from 'dto/classification';
import {
  searchBookings,
  setBookingFilter,
} from 'features/booking/bookingActions';
import { bookingsFilterSelector } from 'features/booking/bookingSelectors';
import { selectUserUnits } from 'features/user/userSelector';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransParagraph } from 'i18n/trans/paragraph';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { IS_DS_AT, IS_IMS_AT } from 'utils/common';
import { parse } from 'qs';

const useStyles = makeStyles(
  (theme) => ({
    general: {
      width: 'calc(100% - 130px)',
      zIndex: 1,
    },
    accordion: {
      position: 'relative',
      zIndex: 0,
      background: 'transparent',
      '&:before': {
        display: 'none',
      },
      '& .MuiAccordionDetails-root': {
        padding: '16px',
        background: theme.palette.background.default,
      },
      '&$expanded': {
        marginBottom: 24,
      },
    },
    expanded: {},
    accordionSummary: {
      marginTop: '-38px',
    },
    accordionSummaryContent: {
      '&&': {
        margin: '0 0 24px 0',
      },
    },
  }),
  {
    name: 'BookingsSearchForm',
  }
);

export const BookingsSearchForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userUnits = useSelector(selectUserUnits);
  const { search } = useLocation();
  const stopOptions = useClassificationOptions(ClassificationGroup.STOP);
  const posTypeOptions = useClassificationOptions(
    IS_DS_AT
      ? ClassificationGroup.POINT_OF_SALE_TYPE
      : ClassificationGroup.SALES_CHANNEL
  );
  const retailerOptions = useClassificationOptions(
    ClassificationGroup.RETAILER
  );
  const filter = useSelector(bookingsFilterSelector);
  const [isAdvancedSearchCollapsed, setAdvancedSearchCollapsed] =
    useState(true);
  const onSubmit = useCallback(
    async (filter: Partial<BookingSearchFilterForm>) => {
      const { travelDateRange, purchaseDateRange, ...values } = filter;
      (document.activeElement as HTMLInputElement)?.blur?.();
      const preparedFilter = {
        ...pickBy(values, Boolean),
        ...mapValues(
          { travelDateRange, purchaseDateRange },
          (date: { from: string; to: string } | undefined) =>
            date && { startTime: date.from, endDate: date.to }
        ),
        pagination: {
          limit: filter?.pagination?.limit ?? 10,
          offset: 0,
        },
      };
      dispatch(setBookingFilter(preparedFilter));
      await dispatch(searchBookings(preparedFilter));
    },
    [dispatch]
  );
  const initialValues = useMemo<Partial<BookingSearchFilterForm>>(
    () => ({
      paymentStatus: PaymentStatusFilter.ALL,
      ...parse(search, { ignoreQueryPrefix: true }),
    }),
    [search]
  );

  const preparedFilter = useMemo(() => {
    if (!filter) return {};
    const { travelDateRange, purchaseDateRange, ...rest } = filter;
    return {
      ...rest,
      ...mapValues(
        { travelDateRange, purchaseDateRange },
        (date: { startTime: string; endDate: string } | undefined) =>
          date && { from: date.startTime, to: date.endDate }
      ),
    };
  }, [filter]);

  const { form, handleSubmit } = useForm<Partial<BookingSearchFilterForm>>({
    initialValues: {
      ...initialValues,
      ...preparedFilter,
    },
    onSubmit,
    validate: (values) => {
      const advancedSearchFields = [
        'cardNumber',
        'corporateAgreementReference',
        'origin',
        'destination',
        'service',
        'retailerId',
        'userName',
        'pointOfSalesType',
      ];
      const advancedFilter = pick(values, advancedSearchFields);
      const atLeastOneRequiredFields = [
        'reference',
        'purchaserPassengerName',
        'purchaserPassengerEmail',
        'purchaserPassengerPhone',
        ...(Object.values(advancedFilter).some(identity)
          ? ['travelDateRange', 'purchaseDateRange']
          : []),
      ];
      const requiredFilter = pick(values, atLeastOneRequiredFields);
      if (Object.values(requiredFilter).every(isEmpty)) {
        return atLeastOneRequiredFields.reduce(
          (acc, field) => ({
            ...acc,
            [field]: <TransAlert i18nKey="fillOneRequired" />,
          }),
          {}
        );
      }
    },
  });

  useEffect(() => {
    form.restart(initialValues);
  }, [form, initialValues, userUnits.organizationId]);

  return (
    <FormProvider {...form}>
      <Stack component="form" onSubmit={handleSubmit} p={3}>
        <Grid
          className={classes.general}
          container
          columns={3}
          spacing={4}
          rowSpacing={1}
          mb={1}
        >
          <Grid item xs={1}>
            <TextField
              name="reference"
              label={
                <Stack direction="row" sx={{ display: 'inline-flex' }}>
                  <TransField i18nKey="referenceNumber" />
                  &nbsp;
                  <Tooltip
                    content={<TransParagraph i18nKey="referenceNumberHint" />}
                  >
                    <Icon name="info-circle" />
                  </Tooltip>
                </Stack>
              }
            />
          </Grid>
          <Grid item xs={1}>
            <DateField
              name="travelDateRange"
              label={<TransField i18nKey="travelDateBetween" />}
              isClearable
              showTimeInput
              selectsRange
            />
          </Grid>
          <Grid item xs={1}>
            <DateField
              name="purchaseDateRange"
              label={<TransField i18nKey="purchaseDateBetween" />}
              isClearable
              showTimeInput
              selectsRange
            />
          </Grid>
          {(
            [
              'purchaserPassengerName',
              'purchaserPassengerEmail',
              'purchaserPassengerPhone',
            ] as const
          ).map((field) => (
            <Grid item xs={1} key={field}>
              <TextField name={field} label={<TransField i18nKey={field} />} />
            </Grid>
          ))}
        </Grid>
        <Accordion
          classes={{
            root: classes.accordion,
            expanded: classes.expanded,
          }}
          disableGutters
          elevation={0}
          expanded={!isAdvancedSearchCollapsed}
          onChange={() =>
            setAdvancedSearchCollapsed(!isAdvancedSearchCollapsed)
          }
        >
          <AccordionSummary
            classes={{
              root: classes.accordionSummary,
              content: classes.accordionSummaryContent,
            }}
          >
            <Stack ml="auto" direction="row" alignItems="center" spacing={1}>
              <Typography variant="paragraph" color="primary">
                {isAdvancedSearchCollapsed ? '+' : '—'}
              </Typography>
              <Typography variant="body1" color="primary">
                <TransButton i18nKey="advancedSearch" />
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container columns={4} spacing={4} rowSpacing={1}>
              <Grid item xs={1}>
                <TextField
                  name="cardNumber"
                  label={<TransField i18nKey="cardOrTravelPass" />}
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="corporateAgreementReference"
                  label={
                    <Stack direction="row">
                      <TransField i18nKey="agreementRef" />
                      &nbsp;
                      <Tooltip
                        content={
                          <TransParagraph i18nKey="corporateAgreementHint" />
                        }
                      >
                        <Icon name="info-circle" />
                      </Tooltip>
                    </Stack>
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="origin"
                  disableClearable={false}
                  label={<TransField i18nKey="legOrigin" />}
                  options={stopOptions}
                  showEmptyOption
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="destination"
                  disableClearable={false}
                  label={<TransField i18nKey="legDestination" />}
                  options={stopOptions}
                  showEmptyOption
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="service"
                  label={
                    <Stack direction="row">
                      <TransField i18nKey="service" />
                      &nbsp;
                      <Tooltip
                        content={<TransParagraph i18nKey="serviceHint" />}
                      >
                        <Icon name="info-circle" />
                      </Tooltip>
                    </Stack>
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="retailerId"
                  disableClearable={false}
                  label={
                    <Stack direction="row">
                      <TransField i18nKey="retailerName" />
                      &nbsp;
                      <Tooltip
                        content={<TransParagraph i18nKey="retailerHint" />}
                      >
                        <Icon name="info-circle" />
                      </Tooltip>
                    </Stack>
                  }
                  options={retailerOptions}
                  showEmptyOption
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="userName"
                  label={
                    <Stack direction="row">
                      <TransField i18nKey="agentToolUsername" />
                      &nbsp;
                      <Tooltip
                        content={
                          <TransParagraph i18nKey="agentToolUserNameHint" />
                        }
                      >
                        <Icon name="info-circle" />
                      </Tooltip>
                    </Stack>
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  options={posTypeOptions}
                  disableClearable={false}
                  name="pointOfSalesType"
                  label={<TransField i18nKey="pointOfSaleType" />}
                  showEmptyOption
                />
              </Grid>
              <Grid item xs={2}>
                <RadioGroupField
                  name="paymentStatus"
                  label={<TransField i18nKey="paymentStatus" />}
                  defaultValue=""
                  options={[
                    {
                      value: PaymentStatusFilter.ALL,
                      label: <TransField i18nKey="all" />,
                    },
                    {
                      value: PaymentStatusFilter.PAID,
                      label: <TransField i18nKey="statusPaid" />,
                    },
                    {
                      value: PaymentStatusFilter.UNPAID,
                      label: <TransField i18nKey="statusUnpaid" />,
                    },
                    ...(IS_IMS_AT
                      ? [
                          {
                            value: PaymentStatusFilter.ON_HOLD,
                            label: <TransField i18nKey="statusOnHold" />,
                          },
                        ]
                      : []),
                  ]}
                  inline
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Stack direction="row" spacing={2} ml="auto">
          <Button onClick={() => form.restart(initialValues)}>
            <TransButton i18nKey="resetFields" />
          </Button>
          <Button variant="contained" type="submit">
            <TransButton i18nKey="search" />
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
