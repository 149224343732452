export enum ClassificationGroup {
  CARD = 'CARD',
  STOP = 'STOP',
  CARRIER = 'CARRIER',
  RETAILER = 'RETAILER',
  PT_MODE = 'PT_MODE',
  COMMENT_TYPE = 'COMMENT_TYPE',
  POINT_OF_SALE_TYPE = 'POINT_OF_SALE_TYPE',
  SALES_CHANNEL = 'SALES_CHANNEL',
}

export interface Stop {
  name: string;
  reference: string;
  cityName: string;
  countryName: string;
}

export interface Classification {
  id: string;
  name: string;
  localizations?: Array<{
    languageId: string;
    name: string;
  }>;
}

export interface Country extends Classification {
  areaCode?: string;
  areaCodeDisplayName: string;
  code: string;
}

export interface Card {
  id: string;
  code: string;
  type: {
    id: string;
    name: string;
  };
  issuer: { code: string; id: string; name: string };
  cardIdRequired: boolean;
  displayOrder: number;
  name: {
    id: string;
    text: string;
    shortText: string;
    translations: Array<{
      language: string;
      text: string;
      shortText: string;
    }>;
  };
}
