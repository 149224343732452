import { ReadOnlyField } from '@fleet/shared';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { Grid, Link, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { OnHoldBookingModal } from 'components/OnHoldBookingModal';
import { PriceWithFee } from 'components/PriceWithFee';
import { TransportBadge } from 'components/TransportBadge';
import { TransportType } from 'dto/trip';
import {
  currentBookingSelector,
  isTravelPassBookingSelector,
} from 'features/booking/bookingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { JourneySummaries } from 'routes/bookingDetails/JourneySummaries';
import { useSelector } from 'store/utils';
import { prepareTrips } from 'utils/overview';
import { getTransportationLabel } from 'utils/trip';

const useStyles = makeStyles(
  () => ({
    root: {
      '& .MuiTypography-body2': {
        minHeight: 0,
      },
    },
  }),
  { name: 'BookingsDetailsCard' }
);
interface BookingDetailsCardProps {
  onShowFullRoute: () => void;
}
export const BookingsDetailsCard: FC<BookingDetailsCardProps> = ({
  onShowFullRoute,
}) => {
  const classes = useStyles();
  const booking = useSelector(currentBookingSelector);
  const trips = useMemo(() => prepareTrips(booking?.bookedTrips), [booking]);
  const isTravelPassBooking = useSelector(isTravelPassBookingSelector);
  const handleViewFullRoute = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      onShowFullRoute();
    },
    [onShowFullRoute]
  );
  const currentPrice = useMemo(() => {
    if (!booking) return;
    return {
      amount: booking!.confirmedPrice.amount,
      fee: booking!.confirmedPrice.vats!.reduce(
        (totalFee, fee) => totalFee + fee.amount,
        0
      ),
      currency: booking!.confirmedPrice.currency,
    };
  }, [booking]);

  const bookingCarriers = useMemo<
    Array<
      Array<{
        ptMode: {
          code: TransportType;
          name: string;
        };
        label: string;
      }>
    >
  >(
    () =>
      trips.map(({ legs }) =>
        legs.map((leg) => ({
          ptMode: leg.ptMode,
          label: getTransportationLabel(
            leg,
            renderToString(<TransSubtitle i18nKey="unknownCarrier" />)
          ),
        }))
      ),
    [trips]
  );

  return (
    <Grid
      container
      columns={6}
      spacing={2}
      rowSpacing={1}
      classes={{
        root: classes.root,
      }}
    >
      <Grid item xs={1}>
        <ReadOnlyField
          value={
            booking?.createdOn &&
            formatDate(booking.createdOn, currentDateTimeFormat)
          }
          label={<TransField i18nKey="createdOn" />}
        />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          value={booking?.soldByOrganization}
          label={<TransField i18nKey="soldBy" />}
        />
      </Grid>
      {booking?.status === 'ON_HOLD' && (
        <Grid item xs={1}>
          <Stack direction="row">
            <ReadOnlyField
              value={
                <>
                  <span>
                    {formatDate(
                      booking.confirmableUntil,
                      currentDateTimeFormat
                    )}
                  </span>
                  <OnHoldBookingModal edit />
                </>
              }
              label={<TransField i18nKey="onHoldUntil" />}
            />
          </Stack>
        </Grid>
      )}
      <Grid item xs={1}>
        <ReadOnlyField label={<TransField i18nKey="paymentMethod" />} />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          value={booking?.passengers.length}
          label={<TransField i18nKey="numberOfPassengers" />}
        />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          contentComponent="div"
          value={currentPrice && <PriceWithFee {...currentPrice} />}
          label={<TransField i18nKey="remainingPrice" />}
        />
      </Grid>
      {!isTravelPassBooking && (
        <>
          <Grid item xs={3} mt={3}>
            <ReadOnlyField
              contentComponent="div"
              value={<JourneySummaries />}
              label={
                <>
                  <TransField i18nKey="journeys" />
                  &nbsp;
                  <Link onClick={handleViewFullRoute}>
                    <TransButton i18nKey="viewFullRoute" />
                  </Link>
                </>
              }
            />
          </Grid>
          <Grid item xs={3} mt={3}>
            <ReadOnlyField
              contentComponent="div"
              value={
                <Stack spacing={1}>
                  {bookingCarriers.map((carriers, idx) => (
                    <Stack key={idx} direction="row" spacing={1}>
                      {carriers.map((carrier, idx) => (
                        <TransportBadge key={idx} {...carrier} small />
                      ))}
                    </Stack>
                  ))}
                </Stack>
              }
              label={<TransField i18nKey="carrier" />}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
