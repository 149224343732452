import { CardHeader, Loadable, useFormContext } from '@fleet/shared';
import { Button, FormControl, Icon, Switch } from '@fleet/shared/mui';
import {
  Card,
  CardContent,
  Divider,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AlertCard } from 'components/AlertCard';
import { JourneyInfo } from 'components/JourneyInfo';
import { OverflowEllipsis } from 'components/OverflowEllipsis';
import {
  SearchTab,
  SearchTabsContext,
  SearchType,
} from 'components/SearchTabsContext';
import { Stepper } from 'components/Stepper';
import { deleteBooking } from 'features/booking/bookingActions';
import {
  bookingAdditionalOffersSelector,
  bookingExpiredSelector,
  currentBookingSelector,
  isTravelPassBookingSelector,
} from 'features/booking/bookingSelectors';
import { bookingCheckoutLoadingSelector } from 'features/loading/loadingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransLabel } from 'i18n/trans/label';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTitle } from 'i18n/trans/title';
import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { renderToString } from 'react-dom/server';
import { RouteComponentProps } from 'react-router';
import { Ancillaries } from 'routes/tickets/checkout/Ancillaries';
import { Overview } from 'routes/tickets/checkout/Overview';
import { PassengerDetails } from 'routes/tickets/checkout/PassengerDetails';
import { SeatSelection } from 'routes/tickets/checkout/SeatSelection';
import { useDispatch, useSelector } from 'store/utils';
import { IS_IMS_AT } from 'utils/common';
import { getBookingJourneys } from 'utils/trip';
import { v4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '4px 32px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'visible',
  },
  detailsToggler: {
    width: 'auto',
    marginLeft: 'auto',
    '& > label': {
      maxWidth: 'initial',
    },
    '& .MuiBox-root, & .MuiSwitch-root': {
      marginBottom: 0,
    },
  },
  confirmBtnWrap: {
    marginTop: 32,
    padding: '24px 32px',
    background: theme.palette.background.default,
  },
  card: {
    width: '100%',
    '& .MuiCardHeader-root': {
      padding: '1rem 1.5rem',
    },
    '& .MuiCardContent-root': {
      '&:last-child': {
        paddingBottom: '5rem',
      },
    },
  },
}));

interface CheckoutProps extends RouteComponentProps {
  initBooking: (tab: SearchTab, onReset: SearchTabsContext['resetTab']) => void;
}

export const Checkout: FC<CheckoutProps> = ({ history, initBooking }) => {
  const classes = useStyles();
  const form = useFormContext();
  const dispatch = useDispatch();
  const booking = useSelector(currentBookingSelector);
  const additionalOffers = useSelector(bookingAdditionalOffersSelector);
  const isTravelPassBooking = useSelector(isTravelPassBookingSelector);
  const isBookingExpired = useSelector(bookingExpiredSelector);
  const bookingCheckoutLoading = useSelector(bookingCheckoutLoadingSelector);
  const { currentTab, resetTab, updateTab, activeTabIdx } =
    useContext(SearchTabsContext);
  const canShowSteps = isTravelPassBooking || additionalOffers;
  const journeys = getBookingJourneys(booking);
  const isTicketCheckout = currentTab!.type === SearchType.tickets;
  const title = useMemo(() => {
    const { name, summary } = currentTab!;
    return isTicketCheckout ? name : summary?.title;
  }, [currentTab, isTicketCheckout]);
  const bookingWarnings = useMemo(
    () => booking?.warnings.warnings.filter(({ type }) => !type),
    [booking?.warnings.warnings]
  );
  const steps = useMemo(
    () =>
      [
        'passengerDetails',
        ...(IS_IMS_AT && isTicketCheckout ? (['seatSelection'] as const) : []),
        ...(isTicketCheckout && additionalOffers?.length
          ? (['addons'] as const)
          : []),
        'overview',
      ] as const,
    [isTicketCheckout, additionalOffers]
  );
  const initialStep = currentTab?.redirectToOverview
    ? 2
    : currentTab?.activeStep ?? 0;
  const [showDetails, setDetailsShown] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(initialStep);
  const detailsToggleHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDetailsShown(e.target.checked);
    },
    []
  );

  const cancelBookingHandler = useCallback(async () => {
    history.replace('/search');
    resetTab();
    form.reset({
      passengerSpecifications: [
        {
          type: 'PERSON',
          externalReference: v4(),
        },
      ],
      ...(isTicketCheckout && { departureTime: new Date().toISOString() }),
      promotionCodes: [],
      corporateCodes: [],
    });
    await dispatch(deleteBooking());
  }, [dispatch, history, resetTab, form, isTicketCheckout]);

  const goToNextStep = useCallback(() => {
    const nextStepIdx = activeStep + 1;
    if (nextStepIdx === steps.length) {
      history.replace('/search/success');
    } else {
      setActiveStep(nextStepIdx);
      if (currentTab) {
        updateTab({ ...currentTab, activeStep: nextStepIdx }, activeTabIdx);
      }
    }
  }, [activeStep, history, steps, updateTab, currentTab, activeTabIdx]);

  const componentsMap = useMemo(
    () => ({
      passengerDetails: PassengerDetails,
      seatSelection: SeatSelection,
      addons: Ancillaries,
      overview: Overview,
    }),
    []
  );

  const CurrentStepComponent = useCallback(() => {
    const activeStepName = steps[activeStep];
    const Component = componentsMap[activeStepName];
    const submitLabel =
      activeStepName === 'overview' ? (
        <TransButton i18nKey="confirmTransaction" />
      ) : (
        <TransButton i18nKey="continue" />
      );
    return (
      <CardContent>
        {booking && (
          <Component goToNextStep={goToNextStep} submitLabel={submitLabel} />
        )}
      </CardContent>
    );
  }, [activeStep, booking, componentsMap, goToNextStep, steps]);

  const handleEditClick = (stepIndex: number) => {
    setActiveStep(stepIndex);
    if (currentTab) {
      updateTab({ ...currentTab, activeStep: stepIndex }, activeTabIdx);
    }
  };

  useEffect(() => {
    if (currentTab && currentTab.activeStep !== activeStep) {
      setActiveStep(currentTab.activeStep);
    }
  }, [currentTab, activeStep]);

  useEffect(() => {
    initBooking(currentTab!, resetTab);
  }, [currentTab, initBooking, resetTab]);

  return (
    <Loadable loading={bookingCheckoutLoading}>
      {({ loading }) => (
        <>
          <Card className={classes.header}>
            <Button
              variant="outlined"
              startIcon={<Icon name="direction-left" />}
              label={<TransButton i18nKey="cancelBooking" />}
              onClick={cancelBookingHandler}
              disabled={loading || isBookingExpired}
            />
            <OverflowEllipsis variant="h2" content={title} sx={{ p: 2 }} />
            {!isTravelPassBooking && (
              <FormControl
                label={<TransSubtitle i18nKey="detailedTripInfo" />}
                labelPosition="top"
                className={classes.detailsToggler}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body2">
                    <TransLabel i18nKey="off" />
                  </Typography>
                  <Switch
                    value={showDetails}
                    onChange={detailsToggleHandler}
                    disabled={loading}
                  />
                  <Typography variant="body2">
                    <TransLabel i18nKey="on" />
                  </Typography>
                </Stack>
              </FormControl>
            )}
          </Card>
          {canShowSteps && (
            <Stack direction="row" spacing={3} mt={3}>
              <Card className={classes.card}>
                <CardHeader
                  titleTypographyProps={{
                    variant: 'h2',
                  }}
                  title={<TransTitle i18nKey="checkout" />}
                />
                <Stepper
                  activeStepIdx={activeStep}
                  goToStep={handleEditClick}
                  steps={steps.map((key) =>
                    renderToString(<TransSubtitle i18nKey={key} />)
                  )}
                />
                <Divider />
                {!!bookingWarnings?.length && (
                  <Stack spacing={1} px={3} pt={3}>
                    {bookingWarnings.map(({ detail }, idx) => (
                      <AlertCard key={idx} message={detail} inline />
                    ))}
                  </Stack>
                )}
                <CurrentStepComponent />
              </Card>
              {!isTravelPassBooking && (
                <Slide
                  direction="left"
                  in={showDetails}
                  mountOnEnter
                  unmountOnExit
                >
                  <Card sx={{ p: 3, width: 330, pb: 10, flexShrink: 0 }}>
                    <Stack spacing={2}>
                      <Typography variant="h2">
                        <TransSubtitle i18nKey="detailedTripInfo" />
                      </Typography>
                      {journeys.map(({ trips }, idx) => (
                        <Stack spacing={1} key={idx}>
                          <Typography variant="subtitle">
                            <TransSubtitle
                              i18nKey="journey"
                              values={{ order: idx + 1 }}
                            />
                          </Typography>
                          <Stack mt={2} spacing={2}>
                            {!!trips?.length && (
                              <JourneyInfo trips={trips} showFullDate />
                            )}
                          </Stack>
                        </Stack>
                      ))}
                    </Stack>
                  </Card>
                </Slide>
              )}
            </Stack>
          )}
        </>
      )}
    </Loadable>
  );
};
