import {
  currentDateTimeFormat,
  formatDate,
  Icon,
  Table,
  TableColumns,
  TableProps,
  Tooltip,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { DaysAfterCount } from 'components/DaysAfterCount';
import { PriceWithFee } from 'components/PriceWithFee';
import { Tag, TagGroup } from 'components/Tag';
import {
  BookingAdmission,
  BookingDetailsPassenger,
  FulfillmentStatus,
  TravelPassBookingPart,
} from 'dto/booking';
import {
  bookingPartsSelector,
  currentBookingSelector,
  isTravelPassBookingSelector,
} from 'features/booking/bookingSelectors';
import { TransField } from 'i18n/trans/field';
import { TransLabel } from 'i18n/trans/label';
import { TransParagraph } from 'i18n/trans/paragraph';
import { TransTableHead } from 'i18n/trans/table';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import uniq from 'lodash/uniq';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useExpanded, useRowSelect, useTable } from 'react-table';
import { useSelector } from 'store/utils';
import {
  canShowAdmissionPrice,
  getAdmissionsTotalPrice,
  getTripsByLegIds,
} from 'utils/trip';

export interface PassengerData extends BookingDetailsPassenger {
  admissions: Array<BookingAdmission>;
}

interface PassengersTableProps
  extends Omit<TableProps<PassengerData>, 'table'> {
  data: Array<PassengerData>;
  hiddenColumns: Array<string>;
  onRowSelectionUpdate: (selectedPassengerIds: Array<string>) => void;
}

export const PassengersTable: FC<PassengersTableProps> = ({
  data,
  hiddenColumns,
  onRowSelectionUpdate,
  ...props
}) => {
  const booking = useSelector(currentBookingSelector)!;
  const isTravelPassBooking = useSelector(isTravelPassBookingSelector);
  const selection = useSelector(bookingPartsSelector, isEqual);
  const { bookedTrips, bookingParts, passengers } = booking;
  const getRowId = useCallback((row: PassengerData) => row.id, []);
  const selectedRowIds = useMemo(
    () =>
      mapValues(
        pickBy(selection.admission, (selection) => selection?.length),
        () => true
      ),
    [selection.admission]
  );
  const passengerStatusAccessor = useCallback(
    ({ admissions, id }: PassengerData) => {
      let statuses;
      if (isTravelPassBooking) {
        const passengerFulfillments = (bookingParts as TravelPassBookingPart[])
          .filter(({ nonTripOffer }) => nonTripOffer?.passengerIds.includes(id))
          .map(({ nonTripOffer }) => nonTripOffer.fulfillments)
          .flat();
        statuses = passengerFulfillments.map(({ status }) => status);
      } else {
        statuses = admissions.reduce<Array<FulfillmentStatus>>(
          (statuses, { status }) => uniq([...statuses, status]),
          []
        );
      }
      if (statuses.length === 1) {
        return <TransField i18nKey={statuses[0]} />;
      } else {
        return (
          <Stack direction="row">
            <TransField i18nKey="FULFILLED" />
            {!isTravelPassBooking && (
              <Tooltip
                content={<TransParagraph i18nKey="admissionStatusHint" />}
              >
                <Icon name="info-circle" color="warning" sx={{ ml: 0.25 }} />
              </Tooltip>
            )}
          </Stack>
        );
      }
    },
    [bookingParts, isTravelPassBooking]
  );
  const journeySummaryAccessor = useCallback(
    ({ admissions }: PassengerData) => {
      if (!bookedTrips.length) return null;
      const passengerLegIds = admissions.reduce<string[]>(
        (ids, { coveredLegIds }) => uniq([...ids, ...coveredLegIds]),
        []
      );
      const passengerTrips = getTripsByLegIds(booking, passengerLegIds);
      const originTrip = passengerTrips[0];
      const destinationTrip =
        passengerTrips.length === 1
          ? passengerTrips[0]
          : passengerTrips[passengerTrips.length - 1];
      const showResplus = passengerTrips.some(({ alliances }) =>
        alliances.includes('RESPLUS')
      );
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body2" noWrap>
            {`${formatDate(
              originTrip.departureTime,
              currentDateTimeFormat
            )} - ${formatDate(
              destinationTrip.arrivalTime,
              currentDateTimeFormat
            )}`}
          </Typography>
          <DaysAfterCount
            startDate={originTrip.departureTime}
            endDate={destinationTrip.arrivalTime}
          />
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body2" fontWeight="bold" noWrap>
              {originTrip.originStop.name}
            </Typography>
            <Icon name="journey-changeover" width={32} />
            <Typography variant="body2" fontWeight="bold" noWrap>
              {destinationTrip.destinationStop.name}
            </Typography>
            {showResplus && (
              <Icon name="resplus-horizontal" height={15} width={53} />
            )}
          </Stack>
        </Stack>
      );
    },
    [bookedTrips.length, booking]
  );

  const columns = useMemo<TableColumns<PassengerData>>(
    () => [
      {
        id: 'passengerName',
        accessor: ({ firstName, lastName }) =>
          [firstName.value, lastName.value].filter(Boolean).join(' '),
        Header: <TransTableHead i18nKey="passenger" />,
        width: 250,
      },
      {
        id: 'status',
        accessor: passengerStatusAccessor,
        Header: <TransTableHead i18nKey="status" />,
        width: 80,
      },
      {
        id: 'journeySummary',
        width: 'auto',
        accessor: journeySummaryAccessor,
        Header: <TransTableHead i18nKey="journey" />,
      },
      {
        id: 'tags',
        accessor: ({ age, prmNeeds }) => (
          <Stack direction="row" spacing={0.5}>
            {isNumber(age) && (
              <Tag
                variant="body2"
                title={<TransLabel i18nKey="age" />}
                text={`${age}`}
              />
            )}
            {!!prmNeeds?.value?.length && (
              <TagGroup
                title={<TransLabel i18nKey="prmNeed" />}
                texts={prmNeeds.value.map((code) => (
                  <TransLabel i18nKey={code} />
                ))}
                color="action"
                subColor="hoverText"
                variant="body2"
              />
            )}
          </Stack>
        ),
      },
      {
        id: 'totalPrice',
        accessor: ({ id, admissions }) => {
          const price = isTravelPassBooking
            ? booking.confirmedPrice
            : getAdmissionsTotalPrice(
                admissions.filter((admission) =>
                  canShowAdmissionPrice(admission, id, passengers)
                )
              );
          return <PriceWithFee hideNull {...price} />;
        },
        Header: <TransTableHead i18nKey="totalPrice" />,
      },
    ],
    [
      booking.confirmedPrice,
      isTravelPassBooking,
      journeySummaryAccessor,
      passengerStatusAccessor,
      passengers,
    ]
  );

  const table = useTable(
    {
      data,
      columns,
      getRowId,
      initialState: {
        selectedRowIds,
        hiddenColumns,
      },
    },
    useIndeterminateRowSelectCheckbox,
    useExpanded,
    useRowSelect
  );

  useEffect(() => {
    onRowSelectionUpdate(Object.keys(table.state.selectedRowIds));
  }, [onRowSelectionUpdate, table.state.selectedRowIds]);

  return <Table<PassengerData> {...props} table={table} />;
};
