import {
  addAdditionalOfferToBooking,
  addComment,
  confirmRefund,
  confirmRelease,
  deleteBooking,
  deleteComment,
  deleteRefundOffers,
  getAdditionalOffers,
  getBooking,
  getComments,
  getHistory,
  initiateRefund,
  initiateRelease,
  makeOnHoldBookingOffer,
  patchNewOnHoldBookingTime,
  putBookingOnHold,
  removeAdditionalOffer,
  removeFeeFromBooking,
  removeOfferFromBooking,
  searchBookings,
  sendConfirmation,
  triggerBookingFulfillment,
  updateBooking,
  updateComment,
} from 'features/booking/bookingActions';
import {
  getCards,
  getCarriers,
  getClassifications,
  getCommentTypes,
  getCountries,
  getElements,
  getPlaces,
  getPosTypes,
  getPtModes,
  getRetailers,
} from 'features/classification/classificationActions';
import {
  getAvailabilitiesPlaceMap,
  getAvailabilitiesPreferences,
  searchTravelPasses,
  searchTrips,
  showTripsResultPage,
  updatePassengersDetails,
  updatePurchaserDetails,
} from 'features/trip/tripActions';
import {
  addTripToFavorites,
  getApplications,
  getFavoriteTrips,
  getUser,
  removeTripFromFavorites,
} from 'features/user/userActions';
import { createLoadingSelector } from 'store/utils';

export const initialLoadingSelector = createLoadingSelector(
  getUser,
  getClassifications,
  getCards,
  getCommentTypes,
  getPlaces,
  getPtModes,
  getCarriers,
  getPosTypes,
  getRetailers,
  getCountries,
  getElements,
  getFavoriteTrips,
  getApplications
);

export const searchLoadingSelector = createLoadingSelector(
  searchTrips,
  searchTravelPasses,
  showTripsResultPage
);

export const bookingSearchLoadingSelector =
  createLoadingSelector(searchBookings);

export const currentBookingLoadingSelector = createLoadingSelector(
  getBooking,
  initiateRefund,
  confirmRefund,
  initiateRelease,
  confirmRelease,
  sendConfirmation,
  getComments,
  addComment,
  updateComment,
  deleteComment,
  getHistory,
  deleteRefundOffers
);

export const bookingCheckoutLoadingSelector = createLoadingSelector(
  deleteBooking,
  getBooking,
  updatePassengersDetails,
  updatePurchaserDetails,
  triggerBookingFulfillment,
  sendConfirmation,
  getAdditionalOffers,
  removeFeeFromBooking
);

export const bookingAddonsLoadingSelector = createLoadingSelector(
  addAdditionalOfferToBooking,
  removeAdditionalOffer
);

export const passengerUpdateLoading = createLoadingSelector(
  updatePassengersDetails
);

export const purchaserUpdateLoading = createLoadingSelector(
  updatePurchaserDetails
);

export const favoriteTripsLoading = createLoadingSelector(
  getFavoriteTrips,
  addTripToFavorites,
  removeTripFromFavorites
);
export const ticketsLoading = createLoadingSelector(getPlaces);

export const removeJourneyLoadingSelector = createLoadingSelector(
  removeOfferFromBooking
);

export const putOnHoldBookingLoadingSelector = createLoadingSelector(
  putBookingOnHold,
  makeOnHoldBookingOffer,
  patchNewOnHoldBookingTime
);

export const seatSelectionLoading = createLoadingSelector(
  updateBooking,
  getAvailabilitiesPreferences,
  getAvailabilitiesPlaceMap
);
