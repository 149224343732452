import {
  FormProvider,
  Modal,
  RadioGroupField,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Options } from '@fleet/shared/dto/option';
import { useModal } from '@fleet/shared/hooks';
import { Button } from '@fleet/shared/mui';
import { Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { AlertCard } from 'components/AlertCard';
import { SearchTabsContext } from 'components/SearchTabsContext';
import { SeatProperty } from 'dto/booking';
import {
  OfferAccommodationType,
  OfferBedProperties,
  OfferGenderProperties,
  OfferPetsProperties,
  PassengerOfferSelection,
  TripOffer,
} from 'dto/trip';
import { unselectOffer, updateOfferSelection } from 'features/trip/tripActions';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import { FC, useCallback, useContext, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { useDispatch } from 'store/utils';

interface CompartmentPreferencesModalProps {
  offer: TripOffer;
}

interface SelectionForm {
  passengers: Array<{
    reference: string;
    gender?: OfferGenderProperties;
    bed?: OfferBedProperties;
    pets?: OfferPetsProperties;
  }>;
}

const useStyles = makeStyles(
  () => ({
    paper: {
      margin: 0,
      maxWidth: 'none',
      width: 776,
      '&$small': {
        width: 400,
      },
    },
    small: {},
    hidden: {
      display: 'none',
    },
  }),
  { name: 'CompartmentPreferencesModal' }
);

const FORM_ID = 'compartment-preferences';
const NO_PROPERTY = 'NO_PROPERTY';

export const CompartmentPreferencesModal: FC<CompartmentPreferencesModalProps> =
  ({ offer }) => {
    const { open, onClose } = useModal({ open: true });
    const classes = useStyles();
    const dispatch = useDispatch();
    const isCompartmentOffer = useMemo(
      () => offer.offerAccommodationType === OfferAccommodationType.COMPARTMENT,
      [offer.offerAccommodationType]
    );
    const { currentTab } = useContext(SearchTabsContext);
    const availablePlaceProperties = useMemo(
      () =>
        uniq(
          offer.reservationLegCoverage
            .filter(({ placeProperties }) => placeProperties.length)
            .map(({ placeProperties }) => placeProperties)
            .flat()
        ),
      [offer.reservationLegCoverage]
    );
    const isPropertyAvailable = useCallback(
      (property: SeatProperty) =>
        availablePlaceProperties.length &&
        availablePlaceProperties.includes(property),
      [availablePlaceProperties]
    );
    const isBedType =
      offer.offerAccommodationType === OfferAccommodationType.BED;
    const passengerSpecifications = useMemo(
      () => currentTab?.params?.passengerSpecifications ?? [],
      [currentTab?.params?.passengerSpecifications]
    );

    const [genderOptions, allBedOptions, allPetsOptions] = [
      OfferGenderProperties,
      OfferBedProperties,
      OfferPetsProperties,
    ].map((props) =>
      Object.values(props).map((key) => ({
        label: renderToString(<TransLabel i18nKey={key} />),
        value: key,
      }))
    );
    const makeOptions = useCallback(
      (options: Options<string>) => [
        {
          label: renderToString(<TransLabel i18nKey="noPreference" />),
          value: NO_PROPERTY,
        },
        ...options,
      ],
      []
    );
    const [bedOptions, petsOptions] = [allBedOptions, allPetsOptions].map(
      (opts) =>
        makeOptions(
          opts.map((opt) =>
            isPropertyAvailable(opt.value) ? opt : { ...opt, disabled: true }
          ) as Options<string>
        )
    );
    const availableBedOptions = bedOptions.filter(({ disabled }) => !disabled);
    const availablePetsOptions = petsOptions.filter(
      ({ disabled }) => !disabled
    );
    const propertiesUnavailable = useMemo(
      () =>
        [
          availablePetsOptions,
          ...(isBedType ? [availableBedOptions] : []),
        ].every((opts) => opts.length === 1),
      [availableBedOptions, availablePetsOptions, isBedType]
    );
    const handleOnClose = useCallback(() => {
      onClose();
      dispatch(
        propertiesUnavailable
          ? updateOfferSelection({ offerId: offer.id, selections: [] })
          : unselectOffer(offer.id)
      );
    }, [dispatch, offer.id, onClose, propertiesUnavailable]);
    const onSubmit = useCallback(
      ({ passengers }: SelectionForm) => {
        const {
          id: offerId,
          reservationLegCoverage,
          reservationOfferParts,
        } = offer;
        const coverageByReservation = keyBy(
          reservationLegCoverage,
          'reservationId'
        );
        const selections = propertiesUnavailable
          ? []
          : reservationOfferParts.map(({ reservationId, passengerRefs }) => {
              const [passengerRef] = passengerRefs;
              const passenger = passengers.find(
                ({ reference }) => reference === passengerRef
              );
              const {
                legId,
                tripId,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                reservationId: _,
                accommodationType,
                ...rest
              } = coverageByReservation[reservationId];
              return {
                passengerIds: isCompartmentOffer
                  ? passengerSpecifications.map(
                      ({ externalReference }) => externalReference
                    )
                  : [passengerRef],
                tripLegCoverage: {
                  tripId,
                  legId,
                },
                accommodationType,
                ...rest,
                reservationId,
                placeProperties: Object.values(
                  omit(passenger, 'reference')
                ).filter(
                  (property) =>
                    property &&
                    ![
                      NO_PROPERTY,
                      ...(accommodationType !== OfferAccommodationType.BERTH
                        ? Object.values(OfferGenderProperties)
                        : []),
                    ].includes(property)
                ),
              };
            });
        dispatch(
          updateOfferSelection({
            offerId,
            selections: selections as PassengerOfferSelection[],
          })
        );
      },
      [
        dispatch,
        isCompartmentOffer,
        offer,
        passengerSpecifications,
        propertiesUnavailable,
      ]
    );

    const initialValues = useMemo(
      () => ({
        passengers: passengerSpecifications.map(({ externalReference }) => ({
          reference: externalReference,
          gender: NO_PROPERTY as OfferGenderProperties,
          bed: NO_PROPERTY as OfferBedProperties,
          pets: !!availablePetsOptions.find(
            ({ value }) => value === OfferPetsProperties.WITHOUT_ANIMALS
          )
            ? OfferPetsProperties.WITHOUT_ANIMALS
            : (NO_PROPERTY as OfferPetsProperties),
        })),
      }),
      [availablePetsOptions, passengerSpecifications]
    );

    const { form, handleSubmit } = useForm<SelectionForm>({
      onSubmit,
      initialValues,
    });
    const gridColumnsSize =
      isCompartmentOffer || passengerSpecifications.length === 1 ? 1 : 2;

    return (
      <Modal
        classes={{
          paper: classNames(classes.paper, {
            [classes.small]: gridColumnsSize === 1,
          }),
        }}
        title={<TransTitle i18nKey="compartmentPreferences" />}
        open={open}
        onClose={handleOnClose}
        showCloseControl={false}
        actionButton={
          <Button variant="contained" form={FORM_ID} type="submit">
            <TransButton i18nKey="confirmSelection" />
          </Button>
        }
      >
        <FormProvider {...form}>
          <Grid
            component="form"
            id={FORM_ID}
            container
            columns={gridColumnsSize}
            spacing={1.5}
            onSubmit={handleSubmit}
          >
            {propertiesUnavailable && (
              <Grid item xs={gridColumnsSize}>
                <AlertCard
                  message={<TransAlert i18nKey="noPropsAvailable" />}
                />
              </Grid>
            )}
            {(isCompartmentOffer
              ? [passengerSpecifications[0]]
              : passengerSpecifications
            ).map(({ externalReference }, idx, passengers) => (
              <Grid item key={externalReference} xs={1}>
                <Stack spacing={2}>
                  <Typography variant="subtitle">
                    {`${idx + 1} / `}
                    <Typography variant="subtitle" color="text.secondary">
                      {passengers.length}
                    </Typography>
                  </Typography>
                  <div className={classes.hidden}>
                    <TextField name={`passengers[${idx}].reference`} disabled />
                  </div>
                  {isBedType && (
                    <>
                      <SelectField
                        label={<TransField i18nKey="compartmentGender" />}
                        name={`passengers[${idx}].gender`}
                        options={makeOptions(genderOptions)}
                        required={!!genderOptions.length}
                      />
                      <SelectField
                        label={<TransField i18nKey="bedPreference" />}
                        name={`passengers[${idx}].bed`}
                        {...(availableBedOptions.length === 1 && {
                          defaultValue: availableBedOptions[0].value,
                        })}
                        options={availableBedOptions}
                        disabled={availableBedOptions.length === 1}
                        required={availableBedOptions.length > 1}
                      />
                    </>
                  )}
                  <RadioGroupField
                    name={`passengers[${idx}].pets`}
                    options={petsOptions}
                    {...(availablePetsOptions.length === 1 && {
                      defaultValue: availablePetsOptions[0].value,
                    })}
                    required={availablePetsOptions.length > 1}
                    inline
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>
        </FormProvider>
      </Modal>
    );
  };
