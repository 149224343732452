import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import pick from 'lodash/pick';

const useStyles = makeStyles((theme) => ({
  label: {
    left: '24px',
    top: '6px',
    fontSize: 20,
    fontWeight: 600,
  },
  labelShrink: {
    top: '20px',
    color: `${theme.palette.text.primary}!important`,
    fontSize: 14,
    fontWeight: 'normal',
  },
  inputRoot: {
    padding: 0,
    marginTop: '0!important',
  },
  input: {
    height: '24px!important',
    padding: '36px 24px 20px!important',
    fontSize: 16,
    fontWeight: 600,
  },
}));

export const SearchTextField = forwardRef<typeof TextField, TextFieldProps>(
  (props, ref) => {
    const inputValue = props.value || props.inputProps?.value;
    const [isFocused, setIsFocused] = useState(false);
    const isLabelShrunk = useMemo(
      () => !!inputValue || isFocused,
      [inputValue, isFocused]
    );
    const classes = useStyles();
    return (
      <TextField
        {...props}
        variant="standard"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        InputLabelProps={{
          ...props.InputLabelProps,
          shrink: isLabelShrunk,
          classes: {
            root: classes.label,
            shrink: classes.labelShrink,
          },
        }}
        // @ts-ignore: fix for tooltip warning, forward prop to input
        InputProps={{
          ...pick(props, 'data-mui-internal-clone-element'),
          ref: props.InputProps?.ref ?? ref,
          autoComplete: 'off',
          disableUnderline: true,
          classes: {
            root: classes.inputRoot,
            input: classes.input,
          },
        }}
      />
    );
  }
);
