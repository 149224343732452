import type { FC } from 'react';
import { useCallback } from 'react';
import { useForm } from '@fleet/shared/form';
import { FormProvider, Icon, SelectField, TextField } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { TransTitle } from 'i18n/trans/title';
import { TransField } from 'i18n/trans/field';
import { Button, Tooltip } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { makeStyles } from '@mui/styles';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useDispatch, useSelector } from 'store/utils';
import {
  addComment,
  deleteComment,
  getComments,
  updateComment,
} from 'features/booking/bookingActions';
import classNames from 'classnames';
import { bookingCommentsSelector } from 'features/booking/bookingSelectors';
import { TransParagraph } from 'i18n/trans/paragraph';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(
  () => ({
    root: {
      maxWidth: 640,
    },
    comment: {
      '&:hover $controls:not($editComment):not($deletedComment)': {
        display: 'flex',
      },
    },
    commentHeader: {},
    controls: {
      display: 'none',
      '& .MuiButton-root': {
        textDecoration: 'underline',
        padding: '0 0.5rem',
        minWidth: 0,
      },
    },
    editComment: {},
    deletedComment: {},
  }),
  {
    name: 'Comments',
  }
);
interface CommentsProps {}

export const Comments: FC<CommentsProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const comments = useSelector(bookingCommentsSelector);
  const commentTypeOptions = useClassificationOptions(
    ClassificationGroup.COMMENT_TYPE
  );
  const { id: bookingId } = useParams<{ id: string }>();

  const onCommentAdd = useCallback(
    async (payload) => {
      await dispatch(addComment(payload)).unwrap();
      await dispatch(getComments(bookingId)).unwrap();
    },
    [dispatch, bookingId]
  );

  const onCommentEdit = useCallback(
    async ({ content, id }) => {
      await dispatch(updateComment({ content, id })).unwrap();
      await dispatch(getComments(bookingId)).unwrap();
    },
    [dispatch, bookingId]
  );

  const onCommentDelete = useCallback(
    async (id) => {
      await dispatch(deleteComment(id)).unwrap();
      await dispatch(getComments(bookingId)).unwrap();
    },
    [dispatch, bookingId]
  );

  const {
    form: addForm,
    handleSubmit: handleAddSubmit,
    valid,
  } = useForm({
    onSubmit: onCommentAdd,
    subscription: {
      valid: true,
    },
  });

  const {
    form: editForm,
    handleSubmit: handleEditSubmit,
    values: { id: editCommentId },
  } = useForm({
    subscription: {
      values: true,
    },
    onSubmit: onCommentEdit,
  });

  const getEditHandler = useCallback(
    (comment) => () => {
      editForm.reset(comment);
    },
    [editForm]
  );

  const dateFormat = useCallback(
    (date) => formatDate(date, currentDateTimeFormat),
    []
  );

  return (
    <Stack spacing={4} className={classes.root}>
      {!!comments.length && (
        <Stack spacing={3}>
          {comments.map((comment) => {
            const { id, content, createInfo, type, isDeleted } = comment;
            const { createdOn, userName, lastChangedOn } = createInfo;
            return (
              <FormProvider {...editForm}>
                <form
                  onSubmit={async (event) => {
                    await handleEditSubmit(event);
                    editForm.restart({});
                  }}
                >
                  <Stack key={id} spacing={1} className={classes.comment}>
                    <Stack
                      direction="row"
                      spacing={1}
                      flexWrap="wrap"
                      className={classes.commentHeader}
                    >
                      <Typography fontWeight="bold">{userName}</Typography>
                      <Typography>{type.name}</Typography>
                      <span>·</span>
                      <Tooltip
                        content={
                          <TransParagraph
                            i18nKey="lastModified"
                            values={{ date: dateFormat(lastChangedOn) }}
                          />
                        }
                      >
                        <Typography>{dateFormat(createdOn)}</Typography>
                      </Tooltip>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        flex={1}
                        spacing={1}
                        className={classNames(classes.controls, {
                          [classes.editComment]: editCommentId === id,
                          [classes.deletedComment]: isDeleted,
                        })}
                      >
                        <Button
                          variant="text"
                          label={<TransButton i18nKey="edit" />}
                          onClick={getEditHandler(comment)}
                        />
                        <Button
                          variant="text"
                          label={<TransButton i18nKey="delete" />}
                          onClick={() => onCommentDelete(id)}
                        />
                      </Stack>
                    </Stack>
                    {editCommentId === id ? (
                      <>
                        <TextField name="content" multiline rows={3} required />
                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          flex={1}
                          spacing={1}
                        >
                          <Button
                            variant="text"
                            label={<TransButton i18nKey="cancel" />}
                            onClick={() => editForm.initialize({})}
                          />
                          <Button
                            type="submit"
                            label={<TransButton i18nKey="save" />}
                          />
                        </Stack>
                      </>
                    ) : (
                      <Typography
                        {...(isDeleted && {
                          fontStyle: 'italic',
                          color: 'text.secondary',
                        })}
                      >
                        {isDeleted ? (
                          <TransParagraph
                            i18nKey="deletedComment"
                            values={{
                              date: dateFormat(lastChangedOn!),
                            }}
                          />
                        ) : (
                          content
                        )}
                      </Typography>
                    )}
                  </Stack>
                </form>
              </FormProvider>
            );
          })}
        </Stack>
      )}
      <FormProvider {...addForm}>
        <form
          onSubmit={async (event) => {
            await handleAddSubmit(event);
            valid && addForm.restart({});
          }}
        >
          <Stack spacing={2}>
            <Typography variant="h2">
              <TransTitle i18nKey="addComment" />
            </Typography>
            <SelectField
              label={<TransField i18nKey="commentType" />}
              name="type"
              required
              options={commentTypeOptions}
            />
            <TextField
              label={<TransField i18nKey="comment" />}
              required
              name="content"
              multiline
              rows={3}
            />
            <Stack direction="row" justifyContent="flex-end" spacing={1}>
              <Button
                variant="contained"
                type="submit"
                startIcon={<Icon name="check" />}
                label={<TransButton i18nKey="postComment" />}
              />
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
};
