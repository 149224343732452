import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransField = createTrans({
  AVAILABLE: <Trans i18nKey="field.AVAILABLE" defaults="Available" />,
  CANCELLED: <Trans i18nKey="field.CANCELLED" defaults="Cancelled" />,
  CLIENT_WISH: <Trans i18nKey="field.CLIENT_WISH" defaults="Client's wish" />,
  DELAY_COMPENSATION: (
    <Trans i18nKey="field.DELAY_COMPENSATION" defaults="Delay compensation" />
  ),
  DISRUPTION: <Trans i18nKey="field.DISRUPTION" defaults="Disruption" />,
  EXTERNAL_COMPENSATION: (
    <Trans
      i18nKey="field.EXTERNAL_COMPENSATION"
      defaults="External compensation"
    />
  ),
  FULFILLED: <Trans i18nKey="field.FULFILLED" defaults="Fulfilled" />,
  JOURNEY_OBSOLETE: (
    <Trans
      i18nKey="field.JOURNEY_OBSOLETE"
      defaults="Journey lost its purpose"
    />
  ),
  MEDICAL_CERTIFICATE: (
    <Trans
      i18nKey="field.MEDICAL_CERTIFICATE"
      defaults="Doctor’s certificate"
    />
  ),
  ON_HOLD: <Trans i18nKey="field.ON_HOLD" defaults="On hold" />,
  PREBOOKED: <Trans i18nKey="field.PREBOOKED" defaults="Prebooked" />,
  REFUNDED: <Trans i18nKey="field.REFUNDED" defaults="Refunded" />,
  RELEASED: <Trans i18nKey="field.RELEASED" defaults="Cancelled" />,
  SALES_STAFF_ERROR: (
    <Trans i18nKey="field.SALES_STAFF_ERROR" defaults="Incorrect sale" />
  ),
  TECHNICAL_FAILURE: (
    <Trans i18nKey="field.TECHNICAL_FAILURE" defaults="Technical failure" />
  ),
  additionalEmailRecipient: (
    <Trans
      i18nKey="field.additionalEmailRecipient"
      defaults="Additional recipient e-mail address"
    />
  ),
  addonType: <Trans i18nKey="field.addonType" defaults="Add-on type" />,
  address: <Trans i18nKey="field.address" defaults="Address" />,
  age: <Trans i18nKey="field.age" defaults="Age: {{age}}" />,
  agentToolUsername: (
    <Trans i18nKey="field.agentToolUsername" defaults="Agent tool user name" />
  ),
  agreementRef: (
    <Trans
      i18nKey="field.agreementRef"
      defaults="Corporate agreement reference"
    />
  ),
  all: <Trans i18nKey="field.all" defaults="All" />,
  arrivalTime: <Trans i18nKey="field.arrivalTime" defaults="Return date" />,
  bedPreference: (
    <Trans i18nKey="field.bedPreference" defaults="Bed preference" />
  ),
  birthday: <Trans i18nKey="field.birthday" defaults="Birthday" />,
  bookingLevel: <Trans i18nKey="field.bookingLevel" defaults="Booking level" />,
  businessGroup: (
    <Trans i18nKey="field.businessGroup" defaults="Business group" />
  ),
  cancelled: <Trans i18nKey="field.cancelled" defaults="Cancelled" />,
  card: <Trans i18nKey="field.card" defaults="Card: {{number}}" />,
  cardCode: <Trans i18nKey="field.cardCode" defaults="Card code" />,
  cardName: <Trans i18nKey="field.cardName" defaults="Card name" />,
  cardNumber: <Trans i18nKey="field.cardNumber" defaults="Card number" />,
  cardOrTravelPass: (
    <Trans
      i18nKey="field.cardOrTravelPass"
      defaults="Card/travel pass number"
    />
  ),
  cardType: <Trans i18nKey="field.cardType" defaults="Card type" />,
  carrier: <Trans i18nKey="field.carrier" defaults="Carrier" />,
  carrierInclude: (
    <Trans i18nKey="field.carrierInclude" defaults="Include/exclude carrier" />
  ),
  comment: <Trans i18nKey="field.comment" defaults="Comment" />,
  commentType: <Trans i18nKey="field.commentType" defaults="Comment type" />,
  companionDog: <Trans i18nKey="field.companionDog" defaults="Companion dog" />,
  companyName: <Trans i18nKey="field.companyName" defaults="Company name" />,
  companyRegNr: (
    <Trans i18nKey="field.companyRegNr" defaults="Company registration nr" />
  ),
  compartmentGender: (
    <Trans i18nKey="field.compartmentGender" defaults="Compartment gender" />
  ),
  concessionId: <Trans i18nKey="field.concessionId" defaults="Concession ID" />,
  concessionType: (
    <Trans i18nKey="field.concessionType" defaults="Concession type" />
  ),
  conditions: <Trans i18nKey="field.conditions" defaults="Conditions" />,
  corporateCode: (
    <Trans i18nKey="field.corporateCode" defaults="Corporate agreement code" />
  ),
  createdOn: <Trans i18nKey="field.createdOn" defaults="Created on" />,
  dateFrom: <Trans i18nKey="field.dateFrom" defaults="Date from" />,
  dateOfBirth: <Trans i18nKey="field.dateOfBirth" defaults="Date of birth" />,
  dateTo: <Trans i18nKey="field.dateTo" defaults="Date to" />,
  departureTime: (
    <Trans i18nKey="field.departureTime" defaults="Departure date" />
  ),
  documentNumber: (
    <Trans i18nKey="field.documentNumber" defaults="Document number" />
  ),
  documentType: <Trans i18nKey="field.documentType" defaults="Document type" />,
  email: <Trans i18nKey="field.email" defaults="Email" />,
  exclude: <Trans i18nKey="field.exclude" defaults="Exclude" />,
  fees: <Trans i18nKey="field.fees" defaults="Fees" />,
  fileFormat: <Trans i18nKey="field.fileFormat" defaults="File format" />,
  firstName: <Trans i18nKey="field.firstName" defaults="First name" />,
  from: <Trans i18nKey="field.from" defaults="From" />,
  gender: <Trans i18nKey="field.gender" defaults="Gender" />,
  include: <Trans i18nKey="field.include" defaults="Include" />,
  includeAllTickets: (
    <Trans i18nKey="field.includeAllTickets" defaults="Include all tickets" />
  ),
  instituteId: <Trans i18nKey="field.instituteId" defaults="Institute ID" />,
  invalidPhoneNumber: (
    <Trans
      i18nKey="field.invalidPhoneNumber"
      defaults="Invalid phone number format"
    />
  ),
  issuer: <Trans i18nKey="field.issuer" defaults="Issuer" />,
  journeys: <Trans i18nKey="field.journeys" defaults="Journeys" />,
  lastName: <Trans i18nKey="field.lastName" defaults="Last name" />,
  legDestination: (
    <Trans
      i18nKey="field.legDestination"
      defaults="Journey/leg destination (stop or city)"
    />
  ),
  legLevel: <Trans i18nKey="field.legLevel" defaults="Leg level" />,
  legOrigin: (
    <Trans
      i18nKey="field.legOrigin"
      defaults="Journey/leg origin (stop or city)"
    />
  ),
  maxTransferTime: (
    <Trans i18nKey="field.maxTransferTime" defaults="Max transfer time" />
  ),
  minTransferTime: (
    <Trans i18nKey="field.minTransferTime" defaults="Min transfer time" />
  ),
  mobileAreaCode: (
    <Trans i18nKey="field.mobileAreaCode" defaults="Mobile area code" />
  ),
  mobileNumber: <Trans i18nKey="field.mobileNumber" defaults="Mobile number" />,
  multiRide: <Trans i18nKey="field.multiRide" defaults="Multi ride" />,
  multipleCarriers: (
    <Trans i18nKey="field.multipleCarriers" defaults="Multiple carriers" />
  ),
  multipleJourneys: (
    <Trans i18nKey="field.multipleJourneys" defaults="Multiple journeys" />
  ),
  nationality: <Trans i18nKey="field.nationality" defaults="Nationality" />,
  noPets: <Trans i18nKey="field.noPets" defaults="No pets" />,
  numberOfPassengers: (
    <Trans i18nKey="field.numberOfPassengers" defaults="Number of passengers" />
  ),
  numberOfRemainingUsages: (
    <Trans
      i18nKey="field.numberOfRemainingUsages"
      defaults="Number of remaining usages"
    />
  ),
  onHoldUntil: <Trans i18nKey="field.onHoldUntil" defaults="On hold until" />,
  passengerAge: <Trans i18nKey="field.passengerAge" defaults="Passenger age" />,
  passengerEmail: (
    <Trans i18nKey="field.passengerEmail" defaults="Passenger email address" />
  ),
  passengerNameFirst: (
    <Trans i18nKey="field.passengerNameFirst" defaults="Passenger first name" />
  ),
  passengerNameLast: (
    <Trans i18nKey="field.passengerNameLast" defaults="Passenger last name" />
  ),
  passengerPhone: (
    <Trans i18nKey="field.passengerPhone" defaults="Passenger phone number" />
  ),
  passengerType: (
    <Trans i18nKey="field.passengerType" defaults="Passenger type: {{type}}" />
  ),
  passengers: <Trans i18nKey="field.passengers" defaults="Select passengers" />,
  payerType: <Trans i18nKey="field.payerType" defaults="Payer type" />,
  paymentMethod: (
    <Trans i18nKey="field.paymentMethod" defaults="Payment method" />
  ),
  paymentStatus: (
    <Trans i18nKey="field.paymentStatus" defaults="Payment status" />
  ),
  person: <Trans i18nKey="field.person" defaults="Person" />,
  petsAllowed: <Trans i18nKey="field.petsAllowed" defaults="Pets allowed" />,
  petsNear: <Trans i18nKey="field.petsNear" defaults="Near pets" />,
  petsNotAllowed: <Trans i18nKey="field.petsNotAllowed" defaults="No pets" />,
  phone: <Trans i18nKey="field.phone" defaults="Phone number" />,
  pointOfSaleType: (
    <Trans i18nKey="field.pointOfSaleType" defaults="Point of sales type" />
  ),
  postalCity: <Trans i18nKey="field.postalCity" defaults="Postal city" />,
  postalCountry: (
    <Trans i18nKey="field.postalCountry" defaults="Postal country" />
  ),
  preference: <Trans i18nKey="field.preference" defaults="Preference" />,
  prefillWithPassenger: (
    <Trans
      i18nKey="field.prefillWithPassenger"
      defaults="Prefill with passenger detail"
    />
  ),
  price: <Trans i18nKey="field.price" defaults="Price" />,
  promoCode: <Trans i18nKey="field.promoCode" defaults="Promo code" />,
  purchaseDateBetween: (
    <Trans
      i18nKey="field.purchaseDateBetween"
      defaults="Purchase date between"
    />
  ),
  purchaserEmail: (
    <Trans i18nKey="field.purchaserEmail" defaults="Payer email address" />
  ),
  purchaserNameFirst: (
    <Trans i18nKey="field.purchaserNameFirst" defaults="Payer first name" />
  ),
  purchaserNameLast: (
    <Trans i18nKey="field.purchaserNameLast" defaults="Payer last name" />
  ),
  purchaserPassengerEmail: (
    <Trans
      i18nKey="field.purchaserPassengerEmail"
      defaults="Passenger/payer e-mail address"
    />
  ),
  purchaserPassengerName: (
    <Trans
      i18nKey="field.purchaserPassengerName"
      defaults="Passenger/payer name"
    />
  ),
  purchaserPassengerPhone: (
    <Trans
      i18nKey="field.purchaserPassengerPhone"
      defaults="Passenger/payer phone number"
    />
  ),
  purchaserPhone: (
    <Trans i18nKey="field.purchaserPhone" defaults="Payer phone number" />
  ),
  quantity: <Trans i18nKey="field.quantity" defaults="Quantity" />,
  quantityPerPassenger: (
    <Trans
      i18nKey="field.quantityPerPassenger"
      defaults="Quantity per passenger"
    />
  ),
  referenceNumber: (
    <Trans i18nKey="field.referenceNumber" defaults="Reference number" />
  ),
  refundReason: <Trans i18nKey="field.refundReason" defaults="Refund reason" />,
  remainingPrice: (
    <Trans i18nKey="field.remainingPrice" defaults="Current price" />
  ),
  retailerName: <Trans i18nKey="field.retailerName" defaults="Retailer name" />,
  search: <Trans i18nKey="field.search" defaults="Search" />,
  selectAddon: <Trans i18nKey="field.selectAddon" defaults="Select add-on" />,
  selectJourney: (
    <Trans i18nKey="field.selectJourney" defaults="Select journey" />
  ),
  selectLeg: <Trans i18nKey="field.selectLeg" defaults="Select leg" />,
  selectPassenger: (
    <Trans i18nKey="field.selectPassenger" defaults="Select passenger(s)" />
  ),
  selectPointOfSale: (
    <Trans i18nKey="field.selectPointOfSale" defaults="Select salespoint" />
  ),
  selectRetailer: (
    <Trans i18nKey="field.selectRetailer" defaults="Select retailer" />
  ),
  selectUnit: <Trans i18nKey="field.selectUnit" defaults="Select unit" />,
  service: <Trans i18nKey="field.service" defaults="Service" />,
  soldBy: <Trans i18nKey="field.soldBy" defaults="Sold by" />,
  state: <Trans i18nKey="field.state" defaults="State" />,
  statusOnHold: <Trans i18nKey="field.statusOnHold" defaults="On hold" />,
  statusPaid: <Trans i18nKey="field.statusPaid" defaults="Paid" />,
  statusUnpaid: <Trans i18nKey="field.statusUnpaid" defaults="Unpaid" />,
  stopsSearch: (
    <Trans
      i18nKey="field.stopsSearch"
      defaults="Search for Stop, Stop code or city"
    />
  ),
  tickets: <Trans i18nKey="field.tickets" defaults="Tickets" />,
  to: <Trans i18nKey="field.to" defaults="To" />,
  total: <Trans i18nKey="field.total" defaults="Total" />,
  totalExclVat: (
    <Trans i18nKey="field.totalExclVat" defaults="Total price excl VAT:" />
  ),
  totalToPay: <Trans i18nKey="field.totalToPay" defaults="Total to pay" />,
  totalVAT: <Trans i18nKey="field.totalVAT" defaults="Total VAT:" />,
  transferLimit: (
    <Trans i18nKey="field.transferLimit" defaults="Max numbers of transfers" />
  ),
  transferStation: (
    <Trans i18nKey="field.transferStation" defaults="Transfer station" />
  ),
  travelDateBetween: (
    <Trans i18nKey="field.travelDateBetween" defaults="Travel date between" />
  ),
  travelPass: <Trans i18nKey="field.travelPass" defaults="Travel pass" />,
  travelPassCode: (
    <Trans i18nKey="field.travelPassCode" defaults="Travel pass code" />
  ),
  travelPassNr: (
    <Trans i18nKey="field.travelPassNr" defaults="Travel pass number" />
  ),
  type: <Trans i18nKey="field.type" defaults="Type" />,
  valid: <Trans i18nKey="field.valid" defaults="Valid" />,
  vatNumber: <Trans i18nKey="field.vatNumber" defaults="VAT number" />,
  visaChecked: <Trans i18nKey="field.visaChecked" defaults="Visa checked" />,
  zipCode: <Trans i18nKey="field.zipCode" defaults="ZIP code" />,
});
