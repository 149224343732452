import { api } from '@fleet/shared';
import { currentLocaleConfiguration } from '@fleet/shared/i18n';
import { Duration, format, getTime, intervalToDuration } from 'date-fns';
import download from 'downloadjs';
import {
  BookingAdmission,
  BookingAdmissionAncillary,
  BookingDetails,
  BookingDetailsPassenger,
  BookingFulfillment,
  BookingTripWithAdmissions,
  FulfillmentStatus,
  PlaceAllocation,
  TravelPassBookingPart,
} from 'dto/booking';
import {
  Journey as TripJourney,
  Price,
  ReservationLegCoverage,
  Trip,
  TripLeg,
  TripOffer,
} from 'dto/trip';
import { parse } from 'duration-fns';
import i18n from 'i18n';
import capitalize from 'lodash/capitalize';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import lowerCase from 'lodash/lowerCase';
import omitBy from 'lodash/omitBy';
import partition from 'lodash/partition';
import startsWith from 'lodash/startsWith';
import { IS_DS_AT } from 'utils/common';

export const getTimeString = (dateTimeStr: string): string => {
  return format(
    getTime(new Date(dateTimeStr)),
    currentLocaleConfiguration.timeFormat
  );
};

export const getLegDuration = (trip: TripLeg) => {
  return parseDuration(parse(trip.duration));
};

export const getLegTransferTime = (tripA: TripLeg, tripB: TripLeg) => {
  return getDuration(tripA.arrivalTime, tripB.departureTime);
};

export const getDuration = (a: string, b: string) => {
  const duration = omitBy(
    intervalToDuration({
      start: new Date(a),
      end: new Date(b),
    }),
    (v) => !v
  );

  return parseDuration(duration);
};

export const parseDuration = (duration: Duration) =>
  Object.keys(duration).reduce((acc, cur) => {
    const unitStr = cur === 'minutes' ? 'min' : cur.substring(0, 1);
    const unitValue = duration[cur as keyof Duration];
    return [
      acc,
      ...(unitValue ? [`${duration[cur as keyof Duration]}${unitStr}`] : []),
    ].join(' ');
  }, '');

export const getTripsLegsWithOffers = (
  trips: Array<Trip>
): Array<TripLeg & { offers: Array<TripOffer> }> =>
  trips.reduce<Array<TripLeg & { offers: Array<TripOffer> }>>((acc, trip) => {
    const { legs, offers } = trip;
    return [
      ...acc,
      ...legs.map((leg) => {
        return {
          ...leg,
          offers: offers?.filter(({ coveredLegIds }) =>
            coveredLegIds.includes(leg.id)
          ),
        };
      }),
    ];
  }, []);

export const getBookingJourneys = (booking?: BookingDetails) => {
  if (!booking) return [];
  const journeysMap = groupBy(booking.bookedTrips, 'journeyRef');
  return Object.keys(journeysMap).map((reference) => ({
    reference,
    trips: journeysMap[reference],
  }));
};

export const getBookingDestinations = (
  booking?: BookingDetails
): Array<string> => {
  if (!booking) return [];
  const journeys = getBookingJourneys(booking);
  const lastJourney = journeys[journeys.length - 1];
  const lastTrip = lastJourney.trips[lastJourney.trips.length - 1];
  return [journeys[0].trips[0].originStop.name, lastTrip.destinationStop.name];
};

export const getOnDemandServiceTexts = (
  trips: Array<Trip | BookingTripWithAdmissions>
): Array<string> =>
  trips.reduce<Array<string>>(
    (texts, trip) => [
      ...texts,
      ...trip.legs.reduce<Array<string>>(
        (texts, leg) => [...texts, ...(leg.onDemandTexts ?? [])],
        []
      ),
    ],
    []
  );

export const getHasJourneyNotifications = (
  trips: Array<Trip | BookingTripWithAdmissions>
): boolean =>
  trips.some(({ legs }) =>
    legs.some(({ serviceTexts }) => !!serviceTexts?.length)
  );

export const getTripAdmissions = (
  trip: BookingTripWithAdmissions
): Array<BookingAdmission> =>
  trip.bookedOffers?.reduce<Array<BookingAdmission>>(
    (acc, { admissions }) => [...acc, ...admissions],
    []
  ) ?? [];

export const getBookingAdmissions = (
  booking?: BookingDetails
): Array<BookingAdmission> =>
  booking?.bookedTrips.reduce<Array<BookingAdmission>>(
    (admissions, trip) =>
      [...admissions, ...getTripAdmissions(trip)].filter(
        ({ passengerIds }) => passengerIds
      ),
    []
  ) ?? [];

export const getTotalPrice = (
  items: Array<{ price: Price; status?: string }>
) =>
  items
    .filter(
      ({ status }) =>
        status === undefined || status !== FulfillmentStatus.REFUNDED
    )
    .reduce<{
      amount: number;
      fee: number;
      currency: string;
    }>(
      (acc, { price }) => ({
        amount: acc.amount + price.amount,
        fee: price.vats!.reduce(
          (totalFee, vat) => totalFee + vat.amount,
          acc.fee
        ),
        currency: price.currency,
      }),
      {
        amount: 0,
        fee: 0,
        currency: '',
      }
    );

export const getAdmissionsTotalPrice = (
  admissions: Array<BookingAdmission>,
  includeAncillaries = true,
  withFee = true
) => {
  const admissionsReservations = admissions.reduce<
    Array<{ price: Price; status?: string }>
  >(
    (reservations, admission) => [
      ...reservations,
      ...(admission.reservations ?? []),
      ...(includeAncillaries ? admission.ancillaries ?? [] : []),
      ...(withFee
        ? (admission.fees ?? []).map((fee) => ({ price: fee.price }))
        : []),
    ],
    []
  );

  return getTotalPrice([...admissions, ...admissionsReservations]);
};

export const downloadBookingTickets = (booking?: BookingDetails) => {
  if (!booking) return;
  let documents: Array<BookingFulfillment>;
  const { bookingParts, bookedTrips } = booking;
  const filterDocument = ({ downloadLink, documentType }: BookingFulfillment) =>
    downloadLink && documentType === 'TICKET';
  if (!bookedTrips.length) {
    documents = (bookingParts as TravelPassBookingPart[])
      .reduce<Array<BookingFulfillment>>(
        (list, { nonTripOffer }) => [...list, ...nonTripOffer?.fulfillments],
        []
      )
      .filter(filterDocument);
  } else {
    documents = bookedTrips
      .reduce<Array<BookingFulfillment>>(
        (acc, trip) => [
          ...acc,
          ...getTripAdmissions(trip).reduce<Array<BookingFulfillment>>(
            (acc, admission) => [...acc, ...admission?.fulfillments],
            []
          ),
        ],
        []
      )
      .filter(filterDocument);
  }

  if (IS_DS_AT) {
    documents = documents.slice(0, 1);
  }

  return documents.forEach(async ({ downloadLink, documentFormat }) =>
    download(
      (
        await api.get(downloadLink, {
          responseType: 'blob',
          headers: {
            'Accept-Language': i18n.language,
          },
        })
      ).data,
      'ticket.pdf',
      documentFormat
    )
  );
};

export const getReadablePropertyName = (code: string): string => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, propertyCode] = code.split('.');
  return capitalize(lowerCase(propertyCode ?? code));
};

export const getOffersReservationInfo = async (
  offers: Array<TripOffer>
): Promise<Array<TripOffer>> => {
  if (!offers.length) return [];
  const payload = offers.map(({ id, reservationOfferParts }) => ({
    offerId: id,
    reservationIds: reservationOfferParts.map(
      ({ reservationId }) => reservationId
    ),
  }));
  try {
    const { offerAvailabilities } = (
      await api.post<{
        offerAvailabilities: Array<{
          offerId: string;
          reservationLegCoverage: Array<ReservationLegCoverage>;
        }>;
      }>(`/availabilities/preferences/bulk`, {
        offers: payload,
      })
    ).data;
    const availabilitiesMap = keyBy(
      offerAvailabilities,
      ({ offerId }) => offerId
    );
    return offers.map((offer) => ({
      ...offer,
      reservationLegCoverage:
        availabilitiesMap[offer.id]!.reservationLegCoverage,
    }));
  } catch (e) {
    return offers;
  }
};
export const checkIfOfferNeedsAdditionalDataCall = ({
  reservationOfferParts,
  reservationLegCoverage,
}: TripOffer) =>
  !reservationLegCoverage?.length &&
  reservationOfferParts.map(
    ({ reservationId, availablePlaces }) =>
      reservationId && !availablePlaces?.length
  ).length;

export const getJourneyReservationData = async (
  journey: TripJourney
): Promise<TripJourney> => {
  const preparedTrips = await Promise.all(
    journey.trips.map(async (trip) => {
      try {
        const [offersWithMissingInfo, rest] = partition(
          trip.offers,
          checkIfOfferNeedsAdditionalDataCall
        );
        const preparedOffers = await getOffersReservationInfo(
          offersWithMissingInfo
        );
        return {
          ...trip,
          offers: [...preparedOffers, ...rest],
        };
      } catch (e) {
        return trip;
      }
    })
  );

  return {
    ...journey,
    trips: preparedTrips,
  };
};

export const getTripsByLegIds = (booking: BookingDetails, ids: string[]) => {
  return booking?.bookedTrips.filter((bookedTrip) =>
    bookedTrip.legs
      .reduce<string[]>((legIds, { id }) => [...legIds, id], [])
      .some((id) => ids.includes(id))
  );
};

export const getTripsAncillaries = (trips: Array<BookingTripWithAdmissions>) =>
  trips.reduce<Array<BookingAdmissionAncillary>>(
    (acc, trip) => [
      ...acc,
      ...getTripAdmissions(trip).reduce<Array<BookingAdmissionAncillary>>(
        (ancillaries, admission) => [
          ...ancillaries,
          ...(admission.ancillaries ?? []),
        ],
        []
      ),
    ],
    []
  );

export const isCompartment = (admission: BookingAdmission) =>
  admission.reservations.find(({ placeAllocations }) =>
    placeAllocations?.accommodationSubType.includes('COMPARTMENT')
  );

export const canShowAdmissionPrice = (
  admission: BookingAdmission,
  passengerId: string,
  passengersOrder: Array<BookingDetailsPassenger>
) => {
  if (isCompartment(admission)) {
    const sortOrder = passengersOrder.map(({ id }) => id);
    const sortedPassengerIds = [...admission.passengerIds].sort(
      (a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b)
    );
    return sortedPassengerIds[0] === passengerId;
  }
  return true;
};

export const isSupplement = ({ type }: BookingAdmissionAncillary) =>
  type.toUpperCase() === 'SUPPLEMENT';

export const getAdmissionsPlaceAllocations = (
  admissions: Array<BookingAdmission>
): Array<PlaceAllocation & { passengerIds: Array<string> }> => {
  return admissions.reduce<
    Array<PlaceAllocation & { passengerIds: Array<string> }>
  >(
    (places, { reservations, passengerIds }) => [
      ...places,
      ...reservations.map(({ placeAllocations }) => ({
        ...placeAllocations,
        passengerIds,
      })),
    ],
    []
  );
};

export const getPassengersNames = (
  booking: BookingDetails,
  ...ids: Array<string>
) => {
  const passengerMap = keyBy(booking.passengers, 'id');
  return ids
    .map((id) =>
      [passengerMap[id]?.firstName.value, passengerMap[id]?.lastName.value]
        .filter(Boolean)
        .join(' ')
    )
    .join(', ');
};

export const getTransportationLabel = (
  {
    carrier,
    serviceCode,
    lineNumber,
    serviceAttributes,
    publishedServiceName,
  }: TripLeg,
  fallbackCarrier: string
) => {
  const serviceBrand = serviceAttributes.find(({ code }) =>
    startsWith(code, 'VRM')
  );
  return [
    carrier?.name ?? carrier?.code ?? fallbackCarrier,
    publishedServiceName ?? serviceCode ?? lineNumber,
    serviceBrand?.description,
  ]
    .filter(Boolean)
    .join(' ');
};
