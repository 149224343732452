import { BookingAdmission } from 'dto/booking';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const moveArrElement = (array: Array<any>, from: number, to: number) => {
  const item = array[from];
  const length = array.length;
  const diff = from - to;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, to),
      item,
      ...array.slice(to, from),
      ...array.slice(from + 1, length),
    ];
  } else if (diff < 0) {
    // move right
    const targetIndex = to + 1;
    return [
      ...array.slice(0, from),
      ...array.slice(from + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ];
  }
  return array;
};

export const runInSequence = async (functions: Array<Function>) => {
  return await functions.reduce(async (promise, func) => {
    await promise;
    return await func();
  }, Promise.resolve([]));
};

export const IS_DS_AT =
  process.env.REACT_APP_IS_DS_INVOLVED?.toString() === 'true';

export const IS_IMS_AT = !IS_DS_AT;

export const replaceUnicodeChars = (value: string) =>
  value.replace(/&#x([0-9a-f]+);/gi, (_, code) =>
    String.fromCharCode(parseInt(code, 16))
  );

export const formatCurrency = ({
  amount,
  currency,
}: {
  amount: number;
  currency: string;
}) => {
  return [Number(amount.toFixed(2)), currency].join(' ');
};

export const isSelectedAdmissionStatusOnHold = (
  selectedAdmissions: BookingAdmission[]
) =>
  selectedAdmissions.some(
    ({ fulfillments }: BookingAdmission) =>
      fulfillments.some(({ status }) => (status as string) === 'ONHOLD') // Backend returns ONHOLD and ON_HOLD in different places.
  );
