import { Icon } from '@fleet/shared';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TypographyProps,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import { DialogProps } from '@mui/material/Dialog';

interface ModalWrapProps extends Omit<DialogProps, 'title'> {
  title: ReactNode;
  titleVariant?: TypographyProps['variant'];
  onClose: () => void;
}

export const ModalWrap: FC<ModalWrapProps> = ({
  onClose,
  title,
  children,
  ...props
}) => {
  return (
    <Dialog onClose={onClose} {...props}>
      <DialogTitle component={Stack} flexDirection="row" alignItems="center">
        <Box sx={{ flex: 1 }}>{title}</Box>
        <IconButton onClick={onClose}>
          <Icon name="close" size={24} />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
