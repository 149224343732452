import { selector } from 'store/utils';
import { RootState } from 'store';
import keyBy from 'lodash/keyBy';
import { createSelector } from '@reduxjs/toolkit';
import { OfferAccommodationType } from 'dto/trip';

export const tripsSelector = selector((state: RootState) => state.trip.list);
export const tripLinksMapSelector = selector((state: RootState) =>
  keyBy(state.trip.links, 'rel')
);
export const travelPassesSelector = selector(
  (state: RootState) => state.trip.travelPasses
);

export const downloadPdfFlagSelector = selector(
  (state: RootState) => state.trip.downloadPdfTickets
);
export const showingStopsForSelector = selector(
  (state: RootState) => state.trip.showStopsFor
);

export const selectSelectedOffers = selector(
  (state: RootState) => state.trip.selectedOffers
);

export const selectUnconfiguredTripOffer = createSelector(
  selectSelectedOffers,
  ({ trips, tripsSelectionMap }) =>
    trips.find(
      ({ offerAccommodationType, id }) =>
        offerAccommodationType !== OfferAccommodationType.SEAT &&
        !tripsSelectionMap[id]
    )
);

export const paymentStatusSelector = selector(
  (state: RootState) => state.trip.paymentStatus
);

export const nextAvailableDateSelector = selector(
  (state: RootState) => state.trip.nextAvailableDepartureDate
);
