import { useCallback } from 'react';

export const TABS_LS_KEY = 'tickets.tabs';
export const ACTIVE_TAB_LS_KEY = 'tickets.activeTabIdx';
export const RECENT_STOPS_LS_KEY = 'tickets.recentStops';
export const RECENT_TRIPS_LS_KEY = 'tickets.recentTrips';
export const RETAILER_SELECTION_LS_KEY = 'user.retailerSelection';

export const useLocalStorage = <T>(props: {
  key: string;
  getDefaultValue: (lsData: T | null) => T;
  isSessionStorage?: boolean;
}) => {
  const { key, getDefaultValue, isSessionStorage } = props;
  const storage = isSessionStorage ? sessionStorage : localStorage;
  const lsData = storage.getItem(key);
  const parsedData: T | null = lsData && JSON.parse(lsData);
  const initialValue = getDefaultValue(parsedData);
  const setData = useCallback(
    (data: T) => {
      storage.setItem(key, JSON.stringify(data));
    },
    [key, storage]
  );

  return {
    initialValue,
    setData,
  };
};
