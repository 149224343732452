import {
  Button,
  FormProvider,
  Icon,
  Layout,
  Loadable,
  Loader,
  Modal,
  TabPanel,
  Tabs,
} from '@fleet/shared';
import { useForm } from '@fleet/shared/form';
import { useModal } from '@fleet/shared/hooks';
import { CardHeader } from '@fleet/shared/mui';
import { noop } from '@fleet/shared/utils/noop';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EmailFulfillment } from 'components/ticketFulfillment/EmailFulfillment';
import {
  getBooking,
  getComments,
  getHistory,
  resetCurrentBooking,
} from 'features/booking/bookingActions';
import {
  bookingCommentsSelector,
  bookingNotificationsPresentSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { currentBookingLoadingSelector } from 'features/loading/loadingSelectors';
import { selectEmailSendAvailable } from 'features/user/userSelector';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTitle } from 'i18n/trans/title';
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BookingsDetailsCard } from 'routes/bookingDetails/BookingsDetailsCard';
import { BookingAddOns } from 'routes/bookingDetails/tabs/BookingAddOns';
import { Comments } from 'routes/bookingDetails/tabs/Comments';
import { Fees } from 'routes/bookingDetails/tabs/Fees';
import { History } from 'routes/bookingDetails/tabs/History';
import { PassengersAndTickets } from 'routes/bookingDetails/tabs/PassengersAndTickets';
import { PayerData } from 'routes/bookingDetails/tabs/PayerData';
import { Route } from 'routes/bookingDetails/tabs/Route';
import { useDispatch, useSelector } from 'store/utils';
import { downloadBookingTickets } from 'utils/trip';
import isEmpty from 'lodash/isEmpty';
import { useConfirmationSend } from 'utils/overview';
import { TicketSelectionPayload } from 'routes/tickets/checkout/Overview';
import { SmsFulfillment } from 'components/ticketFulfillment/SmsFulfillment';

interface BookingsDetailsProps {}

const tabKeys = [
  'passengersAndTickets',
  // 'bookingAddOns',
  'fees',
  'route',
  'payerData',
  'history',
  'comments',
] as const;

const SEND_TICKETS_FORM_ID = 'sendTicketsForm';

const useStyles = makeStyles(
  (theme) => ({
    sendTicketsModal: {
      '& .MuiPaper-root': {
        width: '50rem',
      },
      '& .MuiDialogTitle-root > .MuiTypography-root': {
        fontSize: theme.typography.h2.fontSize,
      },
      '& .MuiDialogContent-root': {
        padding: 0,
        paddingBottom: '1rem',
      },
    },
    tabIcon: {
      transform: 'translateY(-0.375rem)',
      marginLeft: '0.25rem',
    },
  }),
  { name: 'BookingDetails' }
);

export const BookingDetails: FC<BookingsDetailsProps> = () => {
  const classes = useStyles();
  const { open, onOpen, onClose } = useModal();
  const currentBooking = useSelector(currentBookingSelector);
  const [activeTab, setActiveTab] = useState<typeof tabKeys[number]>(
    tabKeys[0]
  );
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(currentBookingLoadingSelector);
  const emailSendAvailable = useSelector(selectEmailSendAvailable);
  const hasNotifications = useSelector(bookingNotificationsPresentSelector);
  const comments = useSelector(bookingCommentsSelector);
  const handleConfirmationSend = useConfirmationSend(id);
  const onSubmit = useCallback(
    async (payload: TicketSelectionPayload) => {
      await handleConfirmationSend(payload);
      onClose();
    },
    [handleConfirmationSend, onClose]
  );

  const {
    form: ticketFulfillmentForm,
    handleSubmit,
    values,
  } = useForm<TicketSelectionPayload>({
    onSubmit,
    subscription: { values: true },
  });

  const onTicketDownload = useCallback(async () => {
    downloadBookingTickets(currentBooking);
  }, [currentBooking]);

  const sendTicketsDisabled = useMemo(() => {
    const {
      emailConfirmationRecipient = [],
      additionalEmailConfirmationRecipients = [],
      smsConfirmationRecipient = [],
      additionalSmsConfirmationRecipients = [],
      passengerSelection = [],
      includeTickets,
    }: TicketSelectionPayload = values;
    const emailConfirmationRecipients = [
      ...emailConfirmationRecipient,
      ...additionalEmailConfirmationRecipients,
    ];
    const smsConfirmationRecipients = [
      ...smsConfirmationRecipient,
      ...additionalSmsConfirmationRecipients,
    ];
    return (
      [
        emailConfirmationRecipients,
        passengerSelection,
        smsConfirmationRecipients,
      ].every(isEmpty) && !includeTickets
    );
  }, [values]);

  const tabsContent = useMemo<Record<typeof tabKeys[number], JSX.Element>>(
    () => ({
      passengersAndTickets: <PassengersAndTickets />,
      bookingAddOns: <BookingAddOns />,
      fees: <Fees />,
      route: <Route />,
      payerData: <PayerData />,
      history: <History />,
      comments: <Comments />,
    }),
    []
  );
  const actionButtons = useMemo(
    () =>
      [
        {
          icon: <Icon name="mail" />,
          label: <TransButton i18nKey="sendTickets" />,
          hidden: !emailSendAvailable,
          onClick: onOpen,
        },
        {
          icon: <Icon width={13} name="download" />,
          label: <TransButton i18nKey="downloadTickets" />,
          disabled: currentBooking?.status === 'ON_HOLD',
          onClick: onTicketDownload,
        },
        {
          icon: <Icon width={13} name="print" />,
          label: <TransButton i18nKey="printTickets" />,
          disabled: true,
          onClick: noop,
        },
      ].filter((btn) => !btn.hidden),
    [onOpen, onTicketDownload, emailSendAvailable, currentBooking?.status]
  );

  const getTabIcon = useCallback(
    (tab: typeof tabKeys[number]): ReactNode | undefined => {
      let iconName;
      if (tab === 'comments' && comments?.length) iconName = 'comment';
      if (tab === 'route' && hasNotifications) iconName = 'info-circle';

      return iconName && <Icon name={iconName} className={classes.tabIcon} />;
    },
    [classes.tabIcon, comments?.length, hasNotifications]
  );

  useEffect(() => {
    dispatch(getBooking(id));
    dispatch(getComments(id));
    dispatch(getHistory(id));
    return () => {
      dispatch(resetCurrentBooking());
    };
  }, [dispatch, id]);

  if (!currentBooking) return <Loader size="container" active />;

  return (
    <Loadable loading={loading}>
      <Layout
        sx={{ flex: '0 !important', minHeight: 'unset !important' }}
        header={
          <CardHeader
            sx={{ py: 2, px: 3 }}
            title={
              <Stack direction="row">
                <IconButton
                  onClick={() => history.push('/bookings')}
                  color="inherit"
                >
                  <Icon name="arrow-left" />
                </IconButton>
                <TransTitle
                  i18nKey="booking"
                  values={{ num: currentBooking?.code }}
                />
              </Stack>
            }
            action={
              <>
                {actionButtons.map(
                  ({ icon, label, onClick, disabled }, idx) => (
                    <Button
                      key={idx}
                      textColorString="text.warning"
                      startIcon={icon}
                      variant="text"
                      disabled={disabled}
                      onClick={onClick}
                    >
                      <Typography>{label}</Typography>
                    </Button>
                  )
                )}
              </>
            }
          />
        }
      >
        <Box sx={{ p: 3, pt: 2, pb: 2 }}>
          <Stack sx={{ mb: 2 }}>
            <Typography variant="subtitle">
              <TransSubtitle i18nKey="details" />
            </Typography>
          </Stack>
          <BookingsDetailsCard onShowFullRoute={() => setActiveTab('route')} />
        </Box>
      </Layout>
      <Box p={3}>
        <Tabs onChange={(_, tab) => setActiveTab(tab)} value={activeTab}>
          {tabKeys.map((tab) => (
            <TabPanel
              key={tab}
              label={
                <Stack direction="row" alignItems="center">
                  <TransSubtitle i18nKey={tab} />
                  {getTabIcon(tab)}
                </Stack>
              }
              value={tab}
            >
              {tabsContent[tab]}
            </TabPanel>
          ))}
        </Tabs>
      </Box>
      <Modal
        className={classes.sendTicketsModal}
        title={<TransButton i18nKey="sendTickets" />}
        open={open}
        onClose={onClose}
        actionButton={
          <Button
            loading={loading}
            startIcon={<Icon name="mail" />}
            variant="contained"
            disabled={sendTicketsDisabled}
            form={SEND_TICKETS_FORM_ID}
            type="submit"
          >
            <TransButton i18nKey="send" />
          </Button>
        }
      >
        <FormProvider {...ticketFulfillmentForm}>
          <Divider />
          <EmailFulfillment
            formId={SEND_TICKETS_FORM_ID}
            onSubmit={handleSubmit}
          />
          <Divider />
          <SmsFulfillment
            onSubmit={handleSubmit}
            formId={SEND_TICKETS_FORM_ID}
          />
        </FormProvider>
      </Modal>
    </Loadable>
  );
};
