import type { FC } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'BookingAddOns',
  }
);

interface BookingAddOnsProps {}

export const BookingAddOns: FC<BookingAddOnsProps> = () => {
  const classes = useStyles();

  return <div className={classes.root}>BookingAddOns</div>;
};
