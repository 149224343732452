import { Icon } from '@fleet/shared/mui';
import { Box, Stack, Typography } from '@mui/material';
import { Collapsible } from 'components/Collapsible';
import { DaysAfterCount } from 'components/DaysAfterCount';
import { BookingTripWithAdmissions } from 'dto/booking';
import {
  getBooking,
  removeOfferFromBooking,
} from 'features/booking/bookingActions';
import { removeJourneyLoadingSelector } from 'features/loading/loadingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransParagraph } from 'i18n/trans/paragraph';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { LegWrapper } from 'components/LegWrapper';
import { OverflowEllipsis } from 'components/OverflowEllipsis';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { makeStyles } from '@mui/styles';
import { TransTitle } from 'i18n/trans/title';
import { PassengersAndTickets } from 'routes/bookingDetails/tabs/PassengersAndTickets';
import { useDispatch, useSelector } from 'store/utils';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { formatCurrency } from 'utils/common';
import { getAdmissionsTotalPrice, getTripAdmissions } from 'utils/trip';
import { Modal, Button } from '@fleet/shared';
import uniq from 'lodash/uniq';
import classNames from 'classnames';

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    inTooltip: {
      '& .MuiButtonBase-root, & .MuiAccordionSummary-content > .MuiBox-root': {
        padding: 0,
      },
    },
    wrapper: {
      gap: '2rem',
      '& > *': {
        flex: 'auto',
      },
      '& $resplusIcon, & $removeBtn': {
        flex: 'none',
      },
    },
    resplusIcon: {},
    removeBtn: {
      minWidth: 0,
      padding: 0,
      '& .MuiButton-startIcon': {
        color: theme.palette.error.main,
      },
    },
    hidden: {
      visibility: 'hidden',
    },
  }),
  { name: 'JourneyOverview' }
);

interface JourneyOverviewProps {
  isOverview?: boolean;
  trip: BookingTripWithAdmissions;
  canDelete?: boolean;
}

export const JourneyOverview: FC<JourneyOverviewProps> = ({
  isOverview,
  trip,
  canDelete,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const booking = useSelector(currentBookingSelector);
  const removeJourneyLoading = useSelector(removeJourneyLoadingSelector);
  const handleDeleteConfirm = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    setShowDeleteModal(true);
  }, []);
  const tripAdmissions = getTripAdmissions(trip).filter(
    ({ passengerIds }) => passengerIds
  );
  const handleDeleteBookedOffer = useCallback(async () => {
    if (!booking || !trip) return;
    await dispatch(
      removeOfferFromBooking({
        bookingId: booking.id,
        bookedOfferId: trip.bookedOffers[0].id,
      })
    ).unwrap();
    setShowDeleteModal(false);
    dispatch(getBooking());
  }, [booking, dispatch, trip]);
  const totalPrice = useMemo(
    () => formatCurrency(getAdmissionsTotalPrice(tripAdmissions)),
    [tripAdmissions]
  );
  if (!booking) return null;

  const { bookedTrips } = booking;
  const passengerCount = tripAdmissions.reduce<Array<string>>(
    (passengerIds, admission) =>
      uniq([...passengerIds, ...admission.passengerIds]),
    []
  ).length;
  const {
    journeyOrigin,
    journeyOriginTime,
    journeyDestination,
    journeyDestinationTime,
    showResplusIcon,
  } = trip
    ? {
        journeyOrigin: trip.originStop.name,
        journeyOriginTime: trip.departureTime,
        journeyDestination: trip.destinationStop.name,
        journeyDestinationTime: trip.arrivalTime,
        showResplusIcon: trip.alliances.includes('RESPLUS'),
      }
    : {
        journeyOrigin: bookedTrips[0].originStop.name,
        journeyOriginTime: bookedTrips[0].departureTime,
        journeyDestination:
          bookedTrips[bookedTrips.length - 1].destinationStop.name,
        journeyDestinationTime: bookedTrips[bookedTrips.length - 1].arrivalTime,
        showResplusIcon: bookedTrips[0].alliances.includes('RESPLUS'),
      };

  return (
    <>
      <Collapsible
        collapsible={isOverview}
        className={classNames(classes.root, {
          [classes.inTooltip]: !isOverview,
        })}
        title={
          <Stack
            direction="row"
            className={classes.wrapper}
            alignItems="center"
            justifyContent="space-between"
            borderRadius={0.25}
          >
            <Icon
              name="resplus"
              className={classNames(classes.resplusIcon, {
                [classes.hidden]: !showResplusIcon,
              })}
              height={44}
              width={12}
            />
            <Typography variant="body1" fontWeight="bold">
              <TransSubtitle
                i18nKey="passengersQty"
                values={{ count: passengerCount }}
                tOptions={{ postProcess: 'interval' }}
              />
            </Typography>
            <LegWrapper small>
              {[journeyOrigin, journeyDestination].map((title, idx) => (
                <OverflowEllipsis
                  key={idx}
                  content={title}
                  variant="body1"
                  fontWeight="bold"
                  sx={{ flex: 1, marginBottom: idx ? 0 : 0.5 }}
                />
              ))}
            </LegWrapper>
            <Box>
              <Typography variant="body1">
                {formatDate(journeyOriginTime, `EEE, ${currentDateTimeFormat}`)}
              </Typography>
              <Stack direction="row" mt={0.5} spacing={0.5}>
                <Typography variant="body1">
                  {formatDate(
                    journeyDestinationTime,
                    `EEE, ${currentDateTimeFormat}`
                  )}
                </Typography>
                <DaysAfterCount
                  startDate={journeyOriginTime}
                  endDate={journeyDestinationTime}
                />
              </Stack>
            </Box>
            <Typography variant="h2" sx={{ textAlign: 'right' }}>
              {totalPrice}
            </Typography>
            {isOverview && (
              <Button
                className={classNames(classes.removeBtn, {
                  [classes.hidden]: !canDelete,
                })}
                variant="text"
                startIcon={<Icon name="trash" />}
                onClick={handleDeleteConfirm}
              />
            )}
          </Stack>
        }
      >
        <PassengersAndTickets isOverview trip={trip} />
      </Collapsible>
      <Modal
        title={<TransTitle i18nKey="deleteConfirmation" />}
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        actionButton={
          <Button
            variant="contained"
            onClick={handleDeleteBookedOffer}
            loading={removeJourneyLoading}
          >
            <TransButton i18nKey="confirm" />
          </Button>
        }
      >
        <TransParagraph i18nKey="deleteBookedOffer" />
      </Modal>
    </>
  );
};
