import { Icon, Table, TableColumns, Tooltip } from '@fleet/shared';
import { useRowSelectCheckbox } from '@fleet/shared/hooks';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { HintWrapper } from 'components/HintWrapper';
import { PriceWithFee } from 'components/PriceWithFee';
import { BookingAdmission, FulfillmentStatus } from 'dto/booking';
import { TripLeg } from 'dto/trip';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { TransField } from 'i18n/trans/field';
import { TransLabel } from 'i18n/trans/label';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { useRowSelect, useSortBy, useTable } from 'react-table';
import { useSelector } from 'store/utils';
import { canShowAdmissionPrice, getAdmissionsTotalPrice } from 'utils/trip';

export enum PassengerAdmissionsColumns {
  selection = 'selection',
  status = 'status',
  ticketNumber = 'ticketNumber',
  origin = 'origin',
  destination = 'destination',
  productName = 'productName',
  appliedPassengerType = 'appliedPassengerType',
  passengerCards = 'passengerCards',
  appliedCodes = 'appliedCodes',
  flexibility = 'flexibility',
  price = 'price',
  refundableAmount = 'refundableAmount',
}

interface PassengerAdmissionsTableProps {
  selected?: Array<string>;
  currentPassengerId: string;
  data: Array<BookingAdmission>;
  onRowSelectionUpdate?: (selection: Array<string>) => void;
  hiddenColumns?: Array<
    PassengerAdmissionsColumns[keyof PassengerAdmissionsColumns]
  >;
}

const useStyles = makeStyles(
  (theme) => ({
    selectionDisabled: {
      '& > td:first-of-type': {
        cursor: 'not-allowed',
        '& label': {
          pointerEvents: 'none',
        },
      },
    },
    strikeThrough: {
      textDecoration: 'line-through',
      opacity: 0.75,
    },
    table: {
      '& thead > tr': {
        background: theme.palette.common.white,
      },
      '& tbody > tr': {
        background: theme.palette.background.default,
      },
    },
    cancelled: {},
  }),
  {
    name: 'PassengerAdmissionsTable',
  }
);
export const PassengerAdmissionsTable: FC<PassengerAdmissionsTableProps> = ({
  selected,
  data,
  onRowSelectionUpdate,
  hiddenColumns,
  currentPassengerId,
}) => {
  const classes = useStyles();
  const { bookedTrips, passengers } = useSelector(currentBookingSelector)!;
  const preparedSelectedRows = useMemo(
    () =>
      selected?.reduce((selection, id) => ({ ...selection, [id]: true }), {}) ??
      {},
    [selected]
  );
  const bookingLegs = useMemo(
    () =>
      bookedTrips.reduce<Array<TripLeg>>(
        (acc, trip) => [...acc, ...trip.legs],
        []
      ),
    [bookedTrips]
  );
  const getAdmissionLeg = useCallback(
    (coveredLegIds: Array<string>, type: 'origin' | 'destination') => {
      const admissionLegs = coveredLegIds
        .map((legId) => bookingLegs.find(({ id }) => id === legId)!)
        .sort(
          (a, b) =>
            new Date(a.departureTime).getTime() -
            new Date(b.departureTime).getTime()
        );
      if (type === 'origin') return admissionLegs[0];
      else return admissionLegs[admissionLegs.length - 1];
    },
    [bookingLegs]
  );

  const getPassengerTypeByAdmission = useCallback(
    (admissionId, passengerId) => {
      const admission = data.find((admission) => admission.id === admissionId);

      if (admission) {
        const passengerTypeEntry = admission.appliedPassengerTypes.find(
          (passengerType) => passengerType.passengerRef === passengerId
        );
        return passengerTypeEntry ? passengerTypeEntry.description : undefined;
      }

      return undefined;
    },
    [data]
  );

  const columns = useMemo<TableColumns<BookingAdmission>>(
    () => [
      {
        id: 'departureTime',
        accessor: ({ coveredLegIds }: BookingAdmission) =>
          getAdmissionLeg(coveredLegIds, 'origin').departureTime,
      },
      {
        id: PassengerAdmissionsColumns.status,
        Header: <TransTableHead i18nKey="status" />,
        accessor: ({ status }) => <TransField i18nKey={status} />,
        width: 90,
      },
      {
        id: PassengerAdmissionsColumns.ticketNumber,
        Header: <TransTableHead i18nKey="ticketNr" />,
        accessor: ({ fulfillments }) => fulfillments[0]?.controlNumber,
      },
      ...(['origin', 'destination'] as const).map((place) => ({
        id: PassengerAdmissionsColumns[place],
        Header: <TransTableHead i18nKey={place} />,
        accessor: ({ coveredLegIds, status }: BookingAdmission) => {
          const leg = getAdmissionLeg(coveredLegIds, place);
          return (
            leg && (
              <Stack
                className={classNames({
                  [classes.strikeThrough]: [
                    FulfillmentStatus.RELEASED,
                    FulfillmentStatus.REFUNDED,
                  ].includes(status),
                })}
              >
                <Typography variant="body2">
                  {formatDate(
                    leg[place === 'origin' ? 'departureTime' : 'arrivalTime'],
                    currentDateTimeFormat
                  )}
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                  {
                    leg[place === 'origin' ? 'originStop' : 'destinationStop']
                      .name
                  }
                </Typography>
              </Stack>
            )
          );
        },
      })),
      {
        id: PassengerAdmissionsColumns.productName,
        Header: <TransTableHead i18nKey="productName" />,
        accessor: ({
          summary,
          productSummary,
          productDescriptiveTexts,
        }: BookingAdmission) => (
          <HintWrapper
            iconPlacement="start"
            iconColor="warning"
            content={productSummary || summary}
            hint={
              !!productDescriptiveTexts.length && (
                <Stack>
                  {productDescriptiveTexts.map(({ description }, idx) => (
                    <Typography key={idx}>{description}</Typography>
                  ))}
                </Stack>
              )
            }
          />
        ),
      },
      {
        id: PassengerAdmissionsColumns.appliedPassengerType,
        Header: <TransTableHead i18nKey="passengerType" />,
        accessor: (admission: BookingAdmission) => {
          return getPassengerTypeByAdmission(admission.id, currentPassengerId);
        },
      },
      {
        id: PassengerAdmissionsColumns.passengerCards,
        Header: <TransTableHead i18nKey="passengerCards" />,
        accessor: ({ appliedReductions }) => (
          <Typography variant="body2">
            {appliedReductions
              .filter(({ type }) => type === 'CARD')
              .map(({ name }) => name)}
          </Typography>
        ),
      },
      {
        id: PassengerAdmissionsColumns.appliedCodes,
        Header: <TransTableHead i18nKey="appliedCodes" />,
        accessor: ({ appliedReductions }) => (
          <Stack>
            {appliedReductions
              .filter(({ type }) => type !== 'CARD')
              .map(({ type, code }) => (
                <Tooltip
                  key={code}
                  content={
                    <Typography>
                      <TransLabel i18nKey={type} />
                    </Typography>
                  }
                >
                  <Stack direction="row">
                    <Icon name="voucher" margin />
                    <Typography variant="body2">{code}</Typography>
                  </Stack>
                </Tooltip>
              ))}
          </Stack>
        ),
      },
      {
        id: PassengerAdmissionsColumns.flexibility,
        Header: <TransTableHead i18nKey="flexibility" />,
        accessor: ({ flexibility, ...admission }) => (
          <HintWrapper
            content={capitalize(startCase(flexibility))}
            hint={
              <Stack direction="row" spacing={0.5}>
                {(['exchangeable', 'refundable'] as const).map((field) => (
                  <Fragment key={field}>
                    <Typography variant="body2" fontWeight="bold">
                      <TransSubtitle i18nKey={field} />:
                    </Typography>
                    <Typography variant="body2">
                      <TransLabel i18nKey={admission[field]} />
                    </Typography>
                  </Fragment>
                ))}
              </Stack>
            }
          />
        ),
        width: 80,
      },
      {
        id: PassengerAdmissionsColumns.price,
        Header: <TransTableHead i18nKey="price" />,
        accessor: (admission) =>
          canShowAdmissionPrice(admission, currentPassengerId, passengers) && (
            <PriceWithFee
              {...getAdmissionsTotalPrice([admission], false, false)}
            />
          ),
        width: '7rem',
      },
    ],
    [
      getAdmissionLeg,
      classes.strikeThrough,
      getPassengerTypeByAdmission,
      currentPassengerId,
      passengers,
    ]
  );

  const table = useTable(
    {
      initialState: {
        sortBy: [{ id: 'departureTime', desc: false }],
        selectedRowIds: preparedSelectedRows,
        hiddenColumns: ['departureTime', ...(hiddenColumns as Array<string>)],
      },
      data: useMemo(() => data, [data]),
      columns,
      getRowId: ({ id }) => id,
    },
    useSortBy,
    useRowSelect,
    useRowSelectCheckbox
  );

  const selectedIds = useMemo(
    () => Object.keys(table.state.selectedRowIds),
    [table.state.selectedRowIds]
  );
  useEffect(() => {
    onRowSelectionUpdate?.(selectedIds);
  }, [onRowSelectionUpdate, selectedIds]);

  return <Table table={table} classes={{ table: classes.table }} />;
};
