import {
  api,
  Button,
  DateField,
  FormProvider,
  Icon,
  SelectField,
  TextField,
  useForm,
  useFormContext,
} from '@fleet/shared';
import { Card, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ConcessionModal } from 'components/searchBar/concession/ConcessionModal';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransLabel } from 'i18n/trans/label';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useCallback, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useField } from 'react-final-form-hooks';

interface ConcessionFormProps {
  name: string;
}

const useStyles = makeStyles(
  (theme) => ({
    card: {
      width: '100%',
      padding: '0.5rem',
      '& > *:not(:last-child)': {
        marginBottom: 8,
      },
    },
    deleteBtn: {
      padding: 0,
      fontSize: '0.75rem',
      color: theme.palette.error.main,
    },
    deleteIcon: {
      border: `1px solid ${theme.palette.error.main}`,
      width: '0.75rem',
      height: '0.75rem',
      borderRadius: '50%',
    },
    addBtn: {
      margin: 0,
      padding: 0,
    },
  }),
  {
    name: 'ConcessionForm',
  }
);

enum ConcessionType {
  PENSIONER = 'CONCESSION_TYPE.PENSIONER',
  SENIOR = 'CONCESSION_TYPE.SENIOR',
  EXDEFENCE = 'CONCESSION_TYPE.EXDEFENCE',
  VISION_IMPAIRED = 'CONCESSION_TYPE.VISION_IMPAIRED',
  BLINDED_SOLDIER = 'CONCESSION_TYPE.BLINDED_SOLDIER',
  CURRENT_EMPLOYEE = 'CONCESSION_TYPE.CURRENT_EMPLOYEE',
  CURRENT_EMPLOYEE_INTERSTATE = 'CONCESSION_TYPE.CURRENT_EMPLOYEE_INTERSTATE',
  FORMER_EMPLOYEE = 'CONCESSION_TYPE.FORMER_EMPLOYEE',
  TCEC_STUDENT = 'CONCESSION_TYPE.TCEC_STUDENT',
  OTHER_STUDENT = 'CONCESSION_TYPE.OTHER_STUDENT',
  DVA = 'CONCESSION_TYPE.DVA',
  JOB_SEEKER = 'CONCESSION_TYPE.JOB_SEEKER',
}

export interface ValidatedConcession {
  id: string;
  result: 'VALID' | 'INVALID' | 'PENDING' | 'ERROR';
  code: string;
  vouchers: Array<{
    id: string;
    status: string;
    validFrom: string;
    validTo: string;
  }>;
}

export const ConcessionForm: FC<ConcessionFormProps> = ({ name }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [validatedConcession, setValidatedConfession] =
    useState<ValidatedConcession>();
  const searchForm = useFormContext();
  const {
    input: { value: initialValue },
  } = useField(name, searchForm);
  const onSubmit = useCallback(
    async (concession) => {
      if (loading) return;
      setLoading(true);
      searchForm.change(name, concession);
      try {
        const [responseConcession] = (
          await api.post<{ concessions: Array<ValidatedConcession> }>(
            '/integrations/nsw/concessions/validate',
            {
              concessions: [concession],
            }
          )
        ).data.concessions;
        setValidatedConfession(responseConcession);
      } catch (e) {}
      setLoading(false);
    },
    [loading, name, searchForm]
  );

  const {
    form,
    handleSubmit,
    values: concession,
  } = useForm({
    destroyOnUnregister: true,
    initialValues: initialValue,
    subscription: { values: true },
    onSubmit,
  });
  const handleConcessionDelete = useCallback(() => {
    searchForm.change(name, undefined);
    setValidatedConfession(undefined);
  }, [searchForm, name]);

  const getStateOptions = useCallback((type: ConcessionType) => {
    if (
      [
        ConcessionType.EXDEFENCE,
        ConcessionType.VISION_IMPAIRED,
        ConcessionType.BLINDED_SOLDIER,
        ConcessionType.DVA,
      ].includes(type)
    )
      return ['NSW', 'ACT'].map((state) => ({ label: state, value: state }));
    else {
      return ['NSW', 'VIC', 'NT', 'ACT', 'TAS', 'WA', 'SA', 'QLD'].map(
        (state) => ({ label: state, value: state })
      );
    }
  }, []);
  return (
    <>
      <FormProvider {...form}>
        <Stack spacing={1} component="form" onSubmit={handleSubmit}>
          {initialValue ? (
            <Card className={classes.card} elevation={0}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2" fontWeight="bold">
                  <TransSubtitle i18nKey="concessionDetails" />
                </Typography>
                <Button
                  className={classes.deleteBtn}
                  variant="text"
                  disabled={loading}
                  startIcon={
                    <Icon name="minus24" className={classes.deleteIcon} />
                  }
                  onClick={handleConcessionDelete}
                >
                  <TransButton i18nKey="remove" />
                </Button>
              </Stack>
              <TextField
                name="id"
                disabled={loading}
                label={<TransField i18nKey="concessionId" />}
                required
              />
              <SelectField
                required
                name="concessionTypeId"
                options={Object.values(ConcessionType).map((type) => ({
                  label: renderToString(<TransLabel i18nKey={type} />),
                  value: type,
                }))}
                label={<TransField i18nKey="concessionType" />}
                disabled={loading}
              />
              {concession?.concessionTypeId && (
                <>
                  {[
                    ConcessionType.TCEC_STUDENT,
                    ConcessionType.OTHER_STUDENT,
                    ConcessionType.DVA,
                  ].includes(concession.concessionTypeId) && (
                    <TextField
                      name="firstName"
                      label={<TransField i18nKey="firstName" />}
                      disabled={loading}
                      required
                    />
                  )}
                  <TextField
                    name="lastName"
                    label={<TransField i18nKey="lastName" />}
                    disabled={loading}
                    required
                  />
                  {[
                    ConcessionType.PENSIONER,
                    ConcessionType.SENIOR,
                    ConcessionType.JOB_SEEKER,
                    ConcessionType.EXDEFENCE,
                    ConcessionType.VISION_IMPAIRED,
                    ConcessionType.BLINDED_SOLDIER,
                    ConcessionType.DVA,
                  ].includes(concession.concessionTypeId) && (
                    <SelectField
                      required
                      name="state"
                      options={getStateOptions(concession.concessionTypeId)}
                      label={<TransField i18nKey="state" />}
                      disabled={loading}
                    />
                  )}
                  {[
                    ConcessionType.TCEC_STUDENT,
                    ConcessionType.OTHER_STUDENT,
                  ].includes(concession.concessionTypeId) && (
                    <>
                      <DateField
                        name="dateOfBirth"
                        label={<TransField i18nKey="dateOfBirth" />}
                        disabled={loading}
                      />
                      <TextField
                        required
                        name="instituteId"
                        label={<TransField i18nKey="instituteId" />}
                        disabled={loading}
                      />
                    </>
                  )}
                  {[
                    ConcessionType.CURRENT_EMPLOYEE,
                    ConcessionType.CURRENT_EMPLOYEE_INTERSTATE,
                    ConcessionType.FORMER_EMPLOYEE,
                  ].includes(concession.concessionTypeId) && (
                    <>
                      <TextField
                        required
                        name="businessGroup"
                        label={<TransField i18nKey="businessGroup" />}
                        disabled={loading}
                      />
                    </>
                  )}
                </>
              )}
              <Button
                sx={{
                  mt: 0.5,
                  width: '100%',
                  textAlign: 'center',
                }}
                loading={loading}
                type="submit"
                disabled={!concession?.concessionTypeId}
                variant="contained"
              >
                <TransButton i18nKey="validate" />
              </Button>
            </Card>
          ) : (
            <Button
              startIcon={<Icon name="add" />}
              sx={{ p: 0 }}
              variant="text"
              onClick={() => searchForm.change(name, {})}
            >
              <TransButton i18nKey="enterConcessionDetails" />
            </Button>
          )}
        </Stack>
      </FormProvider>
      <ConcessionModal concession={validatedConcession} />
    </>
  );
};
