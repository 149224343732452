import { Loadable } from '@fleet/shared';
import { Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SearchBar } from 'components/searchBar/SearchBar';
import {
  SearchTab,
  SearchTabsContext,
  SearchTabsProvider,
  SearchType,
} from 'components/SearchTabsContext';
import {
  getAdditionalOffers,
  getBooking,
} from 'features/booking/bookingActions';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { ticketsLoading } from 'features/loading/loadingSelectors';
import React, { FC, useCallback } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { Checkout } from 'routes/tickets/checkout/Checkout';
import { Dashboard } from 'routes/tickets/dashboard/Dashboard';
import { TravelPassSearchResults } from 'routes/tickets/searchResults/TravelPassSearchResults';
import { TripSearchResults } from 'routes/tickets/searchResults/TripSearchResults';
import { useDispatch, useSelector } from 'store/utils';
import { BookingSuccess } from './tickets/checkout/BookingSuccess';

const useStyles = makeStyles(() => ({
  content: {
    flex: 1,
    padding: '24px 24px 0',
    boxSizing: 'content-box',
    width: 'calc(100% - 48px)',
    minWidth: 976,
    maxWidth: 1416,
    margin: '0 auto',
    overflow: 'auto',
    '& .MuiTableCell-root > div': {
      minWidth: 'auto',
    },
  },
}));

interface TicketsProps extends RouteComponentProps {}
export const Tickets: FC<TicketsProps> = ({ match: { path }, history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const booking = useSelector(currentBookingSelector);
  const loading = useSelector(ticketsLoading);
  const handleTabState = useCallback(
    ({ bookingId, isCompleted, addingAdditionalJourney }: SearchTab) => {
      if (addingAdditionalJourney) {
        history.replace(path);
      } else {
        history.replace(
          bookingId ? `${path}/${isCompleted ? 'success' : 'checkout'}` : path
        );
      }
    },
    [history, path]
  );
  const initBooking = useCallback(
    async (currentTab: SearchTab, onReset: SearchTabsContext['resetTab']) => {
      const { bookingId } = currentTab;
      if (!bookingId) return history.replace('/search/trip');
      try {
        if (bookingId !== booking?.id) {
          await dispatch(getBooking(bookingId)).unwrap();
          currentTab?.type === SearchType.tickets &&
            (await dispatch(getAdditionalOffers()));
        }
      } catch (e) {
        onReset();
        history.replace('/search');
      }
    },
    [booking?.id, dispatch, history]
  );

  return (
    <Loadable loading={loading}>
      <Stack className={classes.content}>
        <SearchTabsProvider onTabChange={handleTabState}>
          <SearchBar path={path}>
            <Switch>
              <Route path={path} exact component={Dashboard} />
              <Route
                path={`${path}/trip`}
                exact
                component={TripSearchResults}
              />
              <Route
                path={`${path}/travel-pass`}
                exact
                component={TravelPassSearchResults}
              />
              <Route
                path={`${path}/checkout`}
                exact
                render={(props) => (
                  <Checkout {...props} initBooking={initBooking} />
                )}
              />
              <Route
                path={`${path}/success`}
                exact
                render={(props) => (
                  <BookingSuccess {...props} initBooking={initBooking} />
                )}
              />
            </Switch>
          </SearchBar>
        </SearchTabsProvider>
      </Stack>
    </Loadable>
  );
};
