import { formatDate, Modal, useModal } from '@fleet/shared';
import { AlertColor, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AlertCard } from 'components/AlertCard';
import { ValidatedConcession } from 'components/searchBar/concession/ConcessionForm';
import { TransTitle } from 'i18n/trans/title';
import { FC, useEffect } from 'react';

const useStyles = makeStyles(
  () => ({
    root: {
      '& .MuiDialogActions-root': {
        display: 'none',
      },
    },
    paper: {
      margin: 0,
      maxWidth: 'none',
      width: 800,
    },
  }),
  { name: 'ConcessionModal' }
);

interface ConcessionModalProps {
  concession?: ValidatedConcession;
}
export const ConcessionModal: FC<ConcessionModalProps> = ({ concession }) => {
  const classes = useStyles();
  const { open, onOpen, onClose } = useModal();
  const statusesMap: Record<ValidatedConcession['result'], AlertColor> = {
    VALID: 'success',
    INVALID: 'error',
    ERROR: 'error',
    PENDING: 'warning',
  };
  useEffect(() => {
    concession && onOpen();
  }, [concession, onOpen]);

  return (
    <Modal
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      title={<TransTitle i18nKey="concessions" />}
      open={open}
      onClose={onClose}
      actionButton={<></>}
    >
      {!!concession && (
        <AlertCard
          type={statusesMap[concession.result]}
          title={`Concession ID ${concession?.id} - ${concession?.result}`}
          message={
            <Stack spacing={0.5}>
              {concession?.vouchers.map(
                ({ id, status, validFrom, validTo }) => (
                  <Stack
                    key={id}
                    direction="row"
                    justifyContent="space-between"
                  >
                    {[
                      `Voucher ${id}`,
                      `Status: ${status}`,
                      `Validity: ${[validFrom, validTo]
                        .map((date) => formatDate(date))
                        .join('-')}`,
                    ].map((val) => (
                      <Typography key={val}>{val}</Typography>
                    ))}
                  </Stack>
                )
              )}
            </Stack>
          }
        />
      )}
    </Modal>
  );
};
