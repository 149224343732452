import { FC, ReactNode, useCallback, useContext, useMemo } from 'react';
import {
  alpha,
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import { TransParagraph } from 'i18n/trans/paragraph';
import { makeStyles } from '@mui/styles';
import { Button, formatDate, Icon } from '@fleet/shared';
import { nextAvailableDateSelector } from 'features/trip/tripSelector';
import { useSelector } from 'store/utils';
import { TransButton } from 'i18n/trans/button';
import { SearchTabsContext, SearchType } from 'components/SearchTabsContext';
import { TripSearchParams } from 'dto/trip';
import { FormApi } from 'final-form';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: 300,
    },
    content: {
      width: 250,
      margin: '0 auto',
      textAlign: 'center',
    },
    iconWrap: {
      width: 80,
      height: 80,
      borderRadius: '50%',
      background: alpha(theme.palette.warning.main, 0.1),
      color: alpha(theme.palette.warning.main, 0.8),
      display: 'inline-flex',
      margin: '32px 0',
    },
  }),
  {
    name: 'EmptyResults',
  }
);

interface EmptyResultsProps {
  message?: ReactNode;
  form?: FormApi<TripSearchParams, Partial<TripSearchParams>>;
}

export const EmptyResults: FC<EmptyResultsProps> = ({ message, form }) => {
  const nextAvailableDate = useSelector(nextAvailableDateSelector);
  const { currentTab } = useContext(SearchTabsContext);
  const isTicketSearch = useMemo(
    () => currentTab?.type === SearchType.tickets,
    [currentTab?.type]
  );
  const classes = useStyles();

  const onNextDepartureClick = useCallback(async () => {
    if (!nextAvailableDate || !isTicketSearch || !form) {
      return;
    }
    form.change('departureTime', nextAvailableDate);
    form.submit();
  }, [form, isTicketSearch, nextAvailableDate]);

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Box className={classes.iconWrap}>
          <Icon
            name="search"
            sx={{ m: 'auto', strokeWidth: '2px' }}
            size={20}
          />
        </Box>
        <Stack spacing={3}>
          <Typography color="text.secondary">
            {message ?? <TransParagraph i18nKey="emptyResults" />}
          </Typography>
          {nextAvailableDate && (
            <Button
              variant="outlined"
              sx={{ mt: 3 }}
              startIcon={<Icon name="search" />}
              onClick={onNextDepartureClick}
            >
              <TransButton
                i18nKey="nextDepartureOn"
                values={{ date: formatDate(new Date(nextAvailableDate)) }}
              />
            </Button>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
