import { SelectOption } from '@fleet/shared';
import { stopsSelector } from 'features/classification/classificationSelectors';
import { searchStops } from 'features/trip/tripActions';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store/utils';

let timeout: ReturnType<typeof setTimeout>;

interface Props {
  value?: string | number;
  wait?: number;
}
export const useStopSearchSelect = ({ value, wait = 300 }: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const stops = useSelector(stopsSelector);
  const [options, setOptions] = useState<Array<SelectOption>>([]);
  const selectedOption: Array<SelectOption> = useMemo(
    () =>
      stops
        .filter(({ id }) => id === value)
        .map(({ id, name }) => ({
          value: id,
          label: name,
        })),
    [stops, value]
  );

  const onSearch = useCallback(
    (e?: SyntheticEvent) => {
      const value = (e?.target as HTMLInputElement)?.value;
      clearTimeout(timeout);

      if (value) {
        setInputValue(value);
        setIsLoading(true);
        try {
          timeout = setTimeout(async () => {
            try {
              const { stops } = await dispatch(searchStops(value)).unwrap();
              stops &&
                setOptions(
                  stops.map(({ reference, name }) => ({
                    value: reference,
                    label: name,
                  }))
                );
            } finally {
              setIsLoading(false);
            }
          }, wait);
        } catch (e) {
          setIsLoading(false);
        }
      } else {
        setOptions([]);
        setInputValue('');
      }
    },
    [dispatch, wait]
  );

  return {
    isLoading,
    inputValue,
    options: [...options, ...selectedOption],
    onSearch,
  };
};
