import { FC, useCallback, useMemo, useState } from "react";
import { TransTableHead } from "i18n/trans/table";
import { Row, useTable } from "react-table";
import {
  Icon,
  SearchResult,
  Table,
  TableColumns,
  useModal,
} from "@fleet/shared";
import { PassengerFee } from "dto/booking";
import { makeStyles } from "@mui/styles";
import { PriceWithFee } from "components/PriceWithFee";
import { useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { ConfirmModal } from "components/ConfirmModal";
import { TransParagraph } from "i18n/trans/paragraph";
import {
  getBooking,
  removeFeeFromBooking,
} from "features/booking/bookingActions";
import { useDispatch, useSelector } from "store/utils";
import { currentBookingSelector } from "features/booking/bookingSelectors";

interface PassengerFeesProps {
  data: Array<PassengerFee>;
}

const useStyles = makeStyles(
  (theme) => ({
    table: {
      "& thead > tr": {
        background: theme.palette.common.white,
      },
      "& tbody > tr": {
        background: theme.palette.background.default,
      },
    },
  }),
  {
    name: "PassengerFees",
  }
);
export const PassengerFees: FC<PassengerFeesProps> = ({ data }) => {
  const [selectedFeeId, setSelectedFeeId] = useState("");
  const dispatch = useDispatch();
  const { open, onClose, onOpen } = useModal();
  const booking = useSelector(currentBookingSelector);
  const isSalesFlow = Boolean(
    !useParams<{
      id: string;
    }>().id
  );

  const classes = useStyles();
  const preparedData = useMemo(() => data, [data]);
  const onRemoveBookingFee = useCallback(
    async (feeId: string) => {
      onClose();
      await dispatch(removeFeeFromBooking([feeId])).unwrap();
      await dispatch(getBooking(booking!.id));
    },
    [dispatch, onClose, booking]
  );

  const columns = useMemo<TableColumns<PassengerFee>>(
    () => [
      {
        id: "removeFeeButton",
        width: 70,
        Cell: ({ row }: { row: Row<PassengerFee> }) => {
          return (
            <IconButton
              onClick={() => {
                setSelectedFeeId(row.original.id);
                onOpen();
              }}
            >
              <Icon size={16} name="trash" color="error" />
            </IconButton>
          );
        },
      },
      {
        accessor: "description",
        width: 150,
        Header: <TransTableHead i18nKey="fee" />,
      },
      {
        id: "price",
        width: "auto",
        Header: <TransTableHead i18nKey="price" />,
        accessor: ({ price }) => <PriceWithFee {...price} />,
      },
    ],
    [onOpen]
  );

  const table = useTable({
    data: preparedData,
    columns,
    initialState: {
      hiddenColumns: isSalesFlow ? [] : ["removeFeeButton"],
    },
  });

  return (
    <SearchResult
      results={data.length}
      loading={false}
      message={<TransParagraph i18nKey="emptyResults" />}
    >
      <Table table={table} classes={{ table: classes.table }} />
      <ConfirmModal
        messageBody={<TransParagraph i18nKey={"removeBookingFee"} />}
        onConfirm={() => onRemoveBookingFee(selectedFeeId)}
        onClose={onClose}
        isOpen={open}
      />
    </SearchResult>
  );
};
