import { api } from '@fleet/shared';
import { ElementResponse } from '@fleet/widget/dto/element';
import {
  Card,
  Classification,
  ClassificationGroup,
  Country,
  Stop,
} from 'dto/classification';
import { createAsyncThunk } from 'store/utils';
import { IS_DS_AT } from 'utils/common';

export const getPlaces = createAsyncThunk<Array<Classification>>(
  'stops',
  async () => {
    const { stops } = (await api.get<{ stops: Array<Stop> }>('/places')).data;

    return stops.map(({ reference, name, ...rest }) => ({
      name,
      id: reference,
      ...rest,
    }));
  }
);

export const getCarriers = createAsyncThunk<Array<Classification>>(
  'carriers',
  async () => (await api.get('/carriers')).data.carriers
);

export const getCountries = createAsyncThunk<Array<Country>>(
  'countries',
  async () => (await api.get('/countries')).data.items
);

export const getPtModes = createAsyncThunk<Array<Classification>>(
  'ptModes',
  async () => (await api.get('/ptModes')).data.ptModes
);

export const getCards = createAsyncThunk<Array<Card>>(
  'cards',
  async () => (await api.get('/cards')).data.items
);

export const getCommentTypes = createAsyncThunk<Array<Classification>>(
  'commentType',
  async () =>
    (
      await api.get(
        `/classifications/groups/${ClassificationGroup.COMMENT_TYPE}`
      )
    ).data.items
);

export const getPosTypes = createAsyncThunk<Array<Classification>>(
  'posTypes',
  async () =>
    (
      await api.get(
        `/classifications/groups/${
          IS_DS_AT
            ? ClassificationGroup.POINT_OF_SALE_TYPE
            : ClassificationGroup.SALES_CHANNEL
        }`
      )
    ).data.items
);

export const getElements = createAsyncThunk<Array<ElementResponse>>(
  'elements',
  async () => (await api.get('/floor-plan-icons')).data.floorPlanIcons
);

export const getRetailers = createAsyncThunk<Array<Classification>>(
  'retailers',
  async () => (await api.get('/retailers')).data.retailers
);

export const getClassifications = createAsyncThunk(
  'getClassifications',
  (_, store) => {
    store.dispatch(getCards());
    store.dispatch(getCommentTypes());
    store.dispatch(getPlaces());
    store.dispatch(getPtModes());
    store.dispatch(getCarriers());
    store.dispatch(getPosTypes());
    store.dispatch(getRetailers());
    store.dispatch(getCountries());
    store.dispatch(getElements());
  }
);
