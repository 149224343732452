import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'store/utils';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { FC, useContext } from 'react';
import { SearchTabsContext } from 'components/SearchTabsContext';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: 4,
      padding: '0.875rem 1.5rem',
      border: `thin solid ${theme.palette.divider}`,
    },
  }),
  { name: 'TravelPassOverview' }
);

interface TravelPassOverviewProps {}
export const TravelPassOverview: FC<TravelPassOverviewProps> = () => {
  const classes = useStyles();
  const booking = useSelector(currentBookingSelector);
  const { currentTab } = useContext(SearchTabsContext);

  if (!currentTab || !booking) return null;

  const { provisionalPrice } = booking;
  const { title, description } = currentTab.summary!;

  return (
    <Stack
      className={classes.root}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography fontWeight={600}>{title}</Typography>
        <Typography variant="body2">{description}</Typography>
      </Stack>
      <Typography variant="h2">
        {`${provisionalPrice.amount} ${provisionalPrice.currency}`}
      </Typography>
    </Stack>
  );
};
