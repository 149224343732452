import { bookingTotalFeesSelector } from 'features/booking/bookingSelectors';
import { FC } from 'react';
import { PassengerFees } from 'routes/bookingDetails/passengerSubRow/PassengerFees';
import { useSelector } from 'store/utils';

interface FeesProps {}

export const Fees: FC<FeesProps> = () => {
  const bookingFees = useSelector(bookingTotalFeesSelector);

  return <PassengerFees data={bookingFees} />;
};
