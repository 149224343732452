import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransParagraph = createTrans({
  addonsBookingLevelEmpty: (
    <Trans
      i18nKey="paragraph.addonsBookingLevelEmpty"
      defaults="No booking level add-ons added"
    />
  ),
  addonsLegLevelEmpty: (
    <Trans
      i18nKey="paragraph.addonsLegLevelEmpty"
      defaults="No leg level add-ons added"
    />
  ),
  admissionSelectionCanceled: (
    <Trans
      i18nKey="paragraph.admissionSelectionCanceled"
      defaults="Some or all of selected admissions are already cancelled/refunded"
    />
  ),
  admissionStatusHint: (
    <Trans
      i18nKey="paragraph.admissionStatusHint"
      defaults="Some of the admissions have different status. Please refer to admissions tab"
    />
  ),
  agentToolUserNameHint: (
    <Trans
      i18nKey="paragraph.agentToolUserNameHint"
      defaults="Search is made by agent tool user who sold the booking. Please type in the person's first and/or last name."
    />
  ),
  bookingExpired: (
    <Trans
      i18nKey="paragraph.bookingExpired"
      defaults="Due to inactivity your booking has expired."
    />
  ),
  bookingSuccess: (
    <Trans
      i18nKey="paragraph.bookingSuccess"
      defaults="Your tickets have been generated and are available to download. The download should start automatically within 5 seconds. If it doesn’t then click below to download."
    />
  ),
  cancelWarning: (
    <Trans
      i18nKey="paragraph.cancelWarning"
      defaults="Are you sure you want to cancel selected booking parts?"
    />
  ),
  copyPassengerDataHint: (
    <Trans
      i18nKey="paragraph.copyPassengerDataHint"
      defaults="The filled fields from this passenger will be copied to only the empty fields below. Prefilled data will not be overwritten."
    />
  ),
  corporateAgreementHint: (
    <Trans
      i18nKey="paragraph.corporateAgreementHint"
      defaults="Corporate agreement code represents the contract between a carrier and a corporate customer. Search is made over the bookings where the corporate agreement code has been applied."
    />
  ),
  deleteAncillariesWarning: (
    <Trans
      i18nKey="paragraph.deleteAncillariesWarning"
      defaults="Are you sure you want to delete selected add-ons?"
    />
  ),
  deleteBookedOffer: (
    <Trans
      i18nKey="paragraph.deleteBookedOffer"
      defaults="Are you sure you want to delete booked offer?"
    />
  ),
  deletedComment: (
    <Trans
      i18nKey="paragraph.deletedComment"
      defaults="Deleted comment {{date}}"
    />
  ),
  emptyResults: (
    <Trans i18nKey="paragraph.emptyResults" defaults="No results found" />
  ),
  emptyTicketResults: (
    <Trans
      i18nKey="paragraph.emptyTicketResults"
      defaults="Requested date has no available trips"
    />
  ),
  fees: <Trans i18nKey="paragraph.fees" defaults="Fees" />,
  lastModified: (
    <Trans i18nKey="paragraph.lastModified" defaults="Last modified {{date}}" />
  ),
  modifySelection: (
    <Trans
      i18nKey="paragraph.modifySelection"
      defaults="Confirm the selected passengers and select what modification you would like to create. Please note that you can only select passengers with matching journeys."
    />
  ),
  onHoldAlert: (
    <Trans
      i18nKey="paragraph.onHoldAlert"
      defaults="Your chosen date and time for the on-hold booking is unavailable. We've assigned the nearest available date and time: {{date}}. To proceed, either continue with the assigned time or choose a different possible time."
    />
  ),
  onHoldAlertSuccess: (
    <Trans
      i18nKey="paragraph.onHoldAlertSuccess"
      defaults="Selected on-hold booking date is available."
    />
  ),
  onHoldSuccess: (
    <Trans
      i18nKey="paragraph.onHoldSuccess"
      defaults="The booking has been reserved until {{ date }}"
    />
  ),
  referenceNumberHint: (
    <Trans
      i18nKey="paragraph.referenceNumberHint"
      defaults="Search is made by booking, ticket or external reference number"
    />
  ),
  refundDetails: (
    <Trans i18nKey="paragraph.refundDetails" defaults="Refund details" />
  ),
  refundFee: <Trans i18nKey="paragraph.refundFee" defaults="Refund fee" />,
  refundWarning: (
    <Trans
      i18nKey="paragraph.refundWarning"
      defaults="Are you sure you want to refund selected booking parts?"
    />
  ),
  refundableAmount: (
    <Trans i18nKey="paragraph.refundableAmount" defaults="Refundable amount" />
  ),
  removeBookingFee: (
    <Trans
      i18nKey="paragraph.removeBookingFee"
      defaults="Are you sure you want to remove the booking fee?"
    />
  ),
  reservedUntil: (
    <Trans
      i18nKey="paragraph.reservedUntil"
      defaults="Reserved until {{date}}"
    />
  ),
  retailerHint: (
    <Trans
      i18nKey="paragraph.retailerHint"
      defaults="Search is made by retailer organization (travel agency, agent) who sold the booking. User related retailers are available, please start typing the name and choose the retailer from the list"
    />
  ),
  serviceHint: (
    <Trans
      i18nKey="paragraph.serviceHint"
      defaults="Search is made by service code or line number"
    />
  ),
  specifyRefundDetails: (
    <Trans
      i18nKey="paragraph.specifyRefundDetails"
      defaults="Please specify refund details"
    />
  ),
});
