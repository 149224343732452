import { FC, useCallback, useEffect, useState } from 'react';
import { SelectField, SelectFieldProps } from '@fleet/shared';

interface AreaCodeOption {
  label: string;
  value: string;
}

interface AreaCodeSelectFieldProps extends SelectFieldProps<string, false> {
  options: AreaCodeOption[];
}

const AreaCodeSelectField: FC<AreaCodeSelectFieldProps> = ({
  options,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortedOptions, setSortedOptions] = useState<Array<AreaCodeOption>>([]);
  const isNumericSearch = (term: string) => /^\d+$/.test(term);

  // Function to score options based on the search term
  const scoreOption = useCallback((option: AreaCodeOption, term: string) => {
    let score = 0;
    const termLower = term.toLowerCase();
    const countryCode = option.value.substring(1); // Remove "+" sign
    const countryName = option.label.toLowerCase();

    if (isNumericSearch(termLower)) {
      // Numeric search
      if (countryCode.startsWith(term)) score += 100;
      else if (countryCode.includes(term)) score += 50;
    } else {
      // Alphabetic search
      const index = countryName.indexOf(termLower);
      if (index !== -1) score += Math.max(100 - index, 1);
    }

    return score;
  }, []);

  useEffect(() => {
    const termLower = searchTerm.toLowerCase();

    const prioritizedOptions = options
      .map((option) => ({
        ...option,
        score: scoreOption(option, termLower),
      }))
      .filter((option) => option.score > 0)
      .sort((a, b) => b.score - a.score)
      .map(({ label, value }) => ({ label, value })); // Cleanup score from options for final output

    setSortedOptions(prioritizedOptions);
  }, [searchTerm, options, scoreOption]);

  return (
    <SelectField
      {...props}
      options={sortedOptions}
      onInputChange={(e) => e && setSearchTerm(e.target.value)}
      getOptionKey={({ label }) => label as string}
    />
  );
};

export default AreaCodeSelectField;
