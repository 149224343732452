import { Icon } from '@fleet/shared';
import { useModal } from '@fleet/shared/hooks';
import { Button, Stack } from '@mui/material';
import { CheckoutOverviewModal } from 'components/CheckoutOverviewModal';
import { PassengerData, PassengersTable } from 'components/PassengersTable';
import {
  BookingAdmission,
  BookingTripWithAdmissions,
  FulfillmentStatus,
  TravelPassBookingPart,
} from 'dto/booking';
import {
  updateBookingPartsSelection,
  updateBookingPartsSelectionBulk,
} from 'features/booking/bookingActions';
import {
  bookingPartsSelector,
  currentBookingSelector,
  isTravelPassBookingSelector,
  selectFulfillmentIdsSelection,
} from 'features/booking/bookingSelectors';
import { TransButton } from 'i18n/trans/button';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import { FC, useCallback, useMemo, useState } from 'react';
import { Row } from 'react-table';
import { CancelModal } from 'routes/bookingDetails/modal/CancelModal';
import { RefundModal } from 'routes/bookingDetails/modal/RefundModal';
import { PassengerSubRow } from 'routes/bookingDetails/passengerSubRow/PassengerSubRow';
import { useDispatch, useSelector } from 'store/utils';
import { isSelectedAdmissionStatusOnHold } from 'utils/common';
import { getBookingAdmissions, getTripAdmissions } from 'utils/trip';

interface PassengersAndTicketsProps {
  isOverview?: boolean;
  trip?: BookingTripWithAdmissions;
}

let refreshKey = 0;
export const PassengersAndTickets: FC<PassengersAndTicketsProps> = ({
  isOverview,
  trip,
}) => {
  const dispatch = useDispatch();
  const [modalType, setModalType] = useState<
    'refund' | 'cancel' | 'checkout'
  >();
  const { onOpen, onClose, open } = useModal();
  const toggleModal = useCallback(
    (type?: 'refund' | 'cancel' | 'checkout') => {
      if (type) {
        setModalType(type);
        onOpen();
      } else {
        setModalType(undefined);
        onClose();
      }
    },
    [onOpen, onClose]
  );
  const fulfillmentIds = useSelector(selectFulfillmentIdsSelection, isEqual);
  const isTravelPassBooking = useSelector(isTravelPassBookingSelector);
  const booking = useSelector(currentBookingSelector)!;
  const selection = useSelector(bookingPartsSelector, isEqual);
  const { bookedTrips, passengers } = booking;
  const bookingAdmissions = useMemo(
    () => getBookingAdmissions(booking),
    [booking]
  );
  const selectedAdmissions = useMemo(() => {
    const selectedIds = Object.values(selection.admission).flat();
    return bookingAdmissions.filter(({ id }) => selectedIds.includes(id));
  }, [selection.admission, bookingAdmissions]);
  const isAdmissionStatusOnHold = useMemo(
    () => isSelectedAdmissionStatusOnHold(selectedAdmissions),
    [selectedAdmissions]
  );
  const isModalShown = useCallback(
    (type) => !isOverview && modalType === type,
    [isOverview, modalType]
  );
  const isSubmitDisabled = useCallback(
    (type: 'refund' | 'release') =>
      selectedAdmissions.some(({ fulfillments }: BookingAdmission) =>
        fulfillments.some(({ status }) =>
          [
            FulfillmentStatus.REFUNDED,
            ...(type === 'release' ? [FulfillmentStatus.RELEASED] : []),
          ].includes(status)
        )
      ),
    [selectedAdmissions]
  );

  const preparedBookedTrips = useMemo(
    () => (trip ? [trip] : bookedTrips),
    [bookedTrips, trip]
  );
  const preparedPassengers = useMemo(() => {
    if (trip) {
      const tripPassengerIds = getTripAdmissions(trip).reduce<Array<string>>(
        (passengerIds, admission) =>
          uniq([...passengerIds, ...(admission.passengerIds ?? [])]),
        []
      );
      return passengers.filter(({ id }) => tripPassengerIds.includes(id));
    } else {
      return passengers;
    }
  }, [passengers, trip]);
  const passengersData = useMemo<Array<PassengerData>>(
    () =>
      preparedPassengers.map((passenger) => ({
        ...passenger,
        admissions: preparedBookedTrips
          .map(getTripAdmissions)
          .flat()
          .filter(({ passengerIds }) => passengerIds?.includes(passenger.id)),
      })),
    [preparedBookedTrips, preparedPassengers]
  );

  const hiddenColumns = useMemo(
    () => [...(isOverview ? ['selection', 'status', 'journeySummary'] : [])],
    [isOverview]
  );

  const updatePassengerSelection = useCallback(
    (selectedPassengerIds: Array<string>) => {
      if (isOverview) return;
      const selection = preparedPassengers.map(({ id: passengerId }) => {
        const shouldRemoveSelection =
          !selectedPassengerIds.includes(passengerId);
        if (isTravelPassBooking) {
          const passengerFulfillmentIds = (
            booking.bookingParts as TravelPassBookingPart[]
          )
            .filter(({ nonTripOffer }) => nonTripOffer)
            .map(({ nonTripOffer }) =>
              nonTripOffer.fulfillments.map(({ id }) => id)
            )
            .flat();
          return {
            passengerId,
            type: 'fulfillment' as const,
            selection: shouldRemoveSelection ? [] : passengerFulfillmentIds,
          };
        } else {
          return {
            passengerId,
            type: 'admission' as const,
            selection: shouldRemoveSelection
              ? []
              : bookingAdmissions
                  .filter(({ passengerIds }) =>
                    passengerIds.includes(passengerId)
                  )
                  .map(({ id }) => id),
          };
        }
      });

      dispatch(updateBookingPartsSelectionBulk(selection));
      refreshKey++;
    },
    [
      isOverview,
      preparedPassengers,
      dispatch,
      isTravelPassBooking,
      booking.bookingParts,
      bookingAdmissions,
    ]
  );

  const handleOnCancelClicked = useCallback(() => {
    if (booking.status === 'ON_HOLD') {
      preparedPassengers.forEach((passenger, index) => {
        const admissionId = bookingAdmissions[index].id;
        dispatch(
          updateBookingPartsSelection({
            passengerId: passenger.id,
            type: 'admission',
            selection: [admissionId],
          })
        );
      });
    }
    toggleModal('cancel');
  }, [
    booking.status,
    bookingAdmissions,
    dispatch,
    preparedPassengers,
    toggleModal,
  ]);

  const renderSubRow = useCallback(
    (row: Row<PassengerData>) => {
      return (
        <PassengerSubRow
          key={refreshKey}
          row={row.original}
          isOverview={isOverview}
          trip={trip}
        />
      );
    },
    [isOverview, trip]
  );

  return (
    <>
      {!isOverview && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          {booking.status === 'ON_HOLD' && (
            <Button
              onClick={() => toggleModal('checkout')}
              variant="text"
              sx={{ fontSize: '12px' }}
              disabled={!fulfillmentIds.length}
              startIcon={<Icon name="cart" />}
            >
              <TransButton i18nKey="paySelected" />
            </Button>
          )}
          <Button
            variant="text"
            sx={{ fontSize: '12px' }}
            disabled
            startIcon={<Icon name="ticket-manage" />}
          >
            <TransButton i18nKey="modifySelected" />
          </Button>
          <Button
            variant="text"
            onClick={() => toggleModal('refund')}
            disabled={!fulfillmentIds.length || isAdmissionStatusOnHold}
            sx={{ fontSize: '12px' }}
            startIcon={<Icon name="ticket-refund" />}
          >
            <TransButton i18nKey="refundSelected" />
          </Button>
          <Button
            variant="text"
            onClick={handleOnCancelClicked}
            disabled={!fulfillmentIds.length || isTravelPassBooking}
            sx={{ fontSize: '12px' }}
            startIcon={<Icon name="ticket-cancel" />}
          >
            <TransButton i18nKey="cancelSelected" />
          </Button>
        </Stack>
      )}
      <PassengersTable
        data={passengersData}
        hiddenColumns={hiddenColumns}
        onRowSelectionUpdate={updatePassengerSelection}
        {...(isOverview && {
          getHeaderGroupProps: { sx: { display: 'none' } },
          getCellProps: {
            sx: {
              '& > div': {
                minWidth: 'auto',
              },
            },
          },
          getRowProps: {
            sx: {
              background: 'white',
              '& td:last-of-type .MuiTypography-root': { marginLeft: 'auto' },
            },
          },
        })}
        getSubRow={renderSubRow}
      />
      {isModalShown('refund') && (
        <RefundModal
          onClose={toggleModal}
          submitDisabled={isSubmitDisabled('refund')}
        />
      )}
      {isModalShown('cancel') && (
        <CancelModal
          onClose={toggleModal}
          submitDisabled={isSubmitDisabled('release')}
          isAdmissionOnHold={isAdmissionStatusOnHold}
        />
      )}
      {isModalShown('checkout') && (
        <CheckoutOverviewModal onClose={() => toggleModal()} isOpen={open} />
      )}
    </>
  );
};
