import { FC, useCallback, useContext } from 'react';
import { RouteChildrenProps } from 'react-router';
import { useDispatch, useSelector } from 'store/utils';
import { selectSelectedOffers } from 'features/trip/tripSelector';
import { PassengerSpecification } from 'dto/trip';
import { Button, useFormContext } from '@fleet/shared';
import { useField } from 'react-final-form-hooks';
import { TravelPassesTable } from 'routes/tickets/searchResults/TravelPassesTable';
import { postBooking } from 'features/booking/bookingActions';
import { CartTotal } from 'components/CartTotal';
import { TransButton } from 'i18n/trans/button';
import { SearchTabsContext } from 'components/SearchTabsContext';
import { bookingLoadingSelector } from 'features/booking/bookingSelectors';
import { alpha, Card } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { renderToString } from 'react-dom/server';

interface TravelPassSearchResultsProps extends RouteChildrenProps {}

export const TravelPassSearchResults: FC<TravelPassSearchResultsProps> = ({
  history,
}) => {
  const { updateTab } = useContext(SearchTabsContext);
  const dispatch = useDispatch();
  const form = useFormContext();
  const {
    input: { value: passengerSpecifications },
  } = useField<Array<PassengerSpecification>>('passengerSpecifications', form);
  const postBookingLoading = useSelector(bookingLoadingSelector);
  const { travelPass } = useSelector(selectSelectedOffers);
  const submitHandler = useCallback(async () => {
    const booking = await dispatch(
      postBooking({
        offers: [
          {
            id: travelPass!.id,
            passengerExternalReferences: passengerSpecifications.map(
              ({ externalReference }) => externalReference
            ),
          },
        ],
        passengerSpecifications,
      })
    ).unwrap();
    updateTab({
      name: renderToString(<TransField i18nKey="travelPass" />),
      summary: {
        title: travelPass!.admissionOfferParts[0]!.productDescription,
        description: travelPass!.summary,
      },
      bookingId: booking.id,
    });
    history.push('/search/checkout');
  }, [dispatch, history, passengerSpecifications, travelPass, updateTab]);
  return (
    <Card sx={{ overflow: 'visible', marginBottom: '72px' }}>
      <TravelPassesTable />
      {!!travelPass && (
        <CartTotal offersTotal={travelPass.offerSummary.minimalPrice} isDark>
          <Button
            loading={postBookingLoading}
            sx={{
              background: 'white!important',
              '&:hover': {
                boxShadow: (theme) =>
                  [
                    alpha(theme.palette.action.hover, 0.2),
                    theme.palette.common.white,
                  ]
                    .map((color) => `inset 0 0 0 2rem ${color}`)
                    .join(','),
              },
            }}
            variant="outlined"
            label={<TransButton i18nKey="continueCheckout" />}
            onClick={submitHandler}
          />
        </CartTotal>
      )}
    </Card>
  );
};
