import { AxiosError } from 'axios';
import {
  BookedOffer,
  BookingDetailsPassenger,
  BookingTripWithAdmissions,
} from 'dto/booking';
import { ErrorResponse } from 'dto/error';
import { TripLeg } from 'dto/trip';
import {
  sendConfirmation,
  setCurrentBooking,
  triggerBookingFulfillment,
} from 'features/booking/bookingActions';
import {
  PassengerDetailsPayload,
  payByLink,
  updatePurchaserDetails,
} from 'features/trip/tripActions';
import { FormApi } from 'final-form';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import partition from 'lodash/partition';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { TicketSelectionPayload } from 'routes/tickets/checkout/Overview';
import { useDispatch } from 'store/utils';

export const prefillPayerFormData = (
  passengerId: string,
  passengers: BookingDetailsPassenger[],
  setShowAreaCodeInput: Dispatch<SetStateAction<boolean>>,
  form: FormApi<PassengerDetailsPayload, Partial<PassengerDetailsPayload>>
) => {
  const passenger = passengers.find(({ id }) => id === passengerId);
  if (passenger) {
    const { firstName, lastName, contactInformation } = passenger;
    setShowAreaCodeInput(!contactInformation.phoneNumber.value);
    form.reset({
      firstName: firstName.value,
      lastName: lastName.value,
      email: contactInformation.emailAddress.value,
      phone: {
        number: contactInformation.phoneNumber.value,
      },
    });
  } else {
    setShowAreaCodeInput(true);
    form.reset({});
  }
};

export const usePurchaserInfo = (
  payerDetailsForm: FormApi<
    PassengerDetailsPayload,
    Partial<PassengerDetailsPayload>
  >,
  values: PassengerDetailsPayload
) => {
  return useMemo(() => {
    let purchaserPhoneNumber: string | undefined;
    let purchaserEmail: string | undefined;
    const phoneNumberState = payerDetailsForm.getFieldState(
      'phone.number' as keyof PassengerDetailsPayload
    );
    const areaCodeState = payerDetailsForm.getFieldState(
      'phone.areaCode' as keyof PassengerDetailsPayload
    );

    if (payerDetailsForm.getFieldState('email')?.valid) {
      purchaserEmail = values.email;
    }

    if (values.phone?.number?.includes('+') && !areaCodeState?.value) {
      purchaserPhoneNumber = values.phone?.number;
    }

    if (phoneNumberState?.value && areaCodeState?.value) {
      purchaserPhoneNumber = values.phone?.areaCode?.concat(
        values.phone?.number
      );
    }

    return { purchaserEmail, purchaserPhoneNumber };
  }, [payerDetailsForm, values]);
};

export const useShowValidations = (
  form: FormApi<PassengerDetailsPayload, Partial<PassengerDetailsPayload>>,
  invalid: boolean
) => {
  return useCallback(() => {
    if (invalid) form.submit();
  }, [form, invalid]);
};

export const prepareTrips = (
  trips?: Array<BookingTripWithAdmissions>
): Array<BookingTripWithAdmissions> => {
  if (!trips) return [];
  const tripsByJourneyRef = mapValues(groupBy(trips, 'journeyRef'), (trips) => [
    ...trips.sort(
      (a, b) =>
        new Date(a.departureTime).getTime() -
        new Date(b.departureTime).getTime()
    ),
  ]);
  const journeys = Object.keys(tripsByJourneyRef);
  return journeys
    .map((ref) => {
      const journeyTrips = tripsByJourneyRef[ref];
      const [outboundTrips, inboundTrips] = partition(
        journeyTrips,
        ({ isOutbound }) => isOutbound
      );

      return [outboundTrips, inboundTrips]
        .filter((arr) => arr.length)
        .map((trips) => {
          const [firstJourneyTrip, ...restJourneyTrips] = trips;
          const legs = trips.reduce<Array<TripLeg>>(
            (legs, trip) => [...legs, ...trip.legs],
            []
          );

          return {
            ...firstJourneyTrip,
            bookedOffers: [
              ...firstJourneyTrip.bookedOffers,
              ...restJourneyTrips.reduce<Array<BookedOffer>>(
                (acc, { bookedOffers }) => [...acc, ...bookedOffers],
                []
              ),
            ],
            legs,
            arrivalTime: trips[trips.length - 1].arrivalTime,
            destinationStop: trips[trips.length - 1].destinationStop,
          };
        });
    })
    .flat();
};

export const useConfirmationSend = (bookingId: string) => {
  const dispatch = useDispatch();
  return useCallback(
    async ({
      emailConfirmationRecipient = [],
      additionalEmailConfirmationRecipients = [],
      smsConfirmationRecipient = [],
      additionalSmsConfirmationRecipients = [],
      passengerSelection = [],
      includeTickets,
    }: TicketSelectionPayload) => {
      const emailConfirmationRecipients = [
        ...emailConfirmationRecipient,
        ...additionalEmailConfirmationRecipients,
      ];
      const smsConfirmationRecipients = [
        ...smsConfirmationRecipient,
        ...additionalSmsConfirmationRecipients,
      ];
      await Promise.all([
        ...(emailConfirmationRecipients.length ||
        smsConfirmationRecipients.length
          ? [
              dispatch(
                sendConfirmation({
                  url: 'purchase-confirmation',
                  bookingId,
                  includeTickets,
                  emailsOverride: emailConfirmationRecipients,
                  phoneNumbersOverride: smsConfirmationRecipients.map(
                    (phoneNo) => ({
                      number: phoneNo,
                    })
                  ),
                })
              ),
            ]
          : []),
        ...(passengerSelection.length
          ? [
              dispatch(
                sendConfirmation({
                  url: 'ticket-delivery',
                  bookingId,
                  passengers: passengerSelection.map((passengerId: string) => ({
                    passengerId,
                  })),
                })
              ),
            ]
          : []),
      ]);
    },
    [bookingId, dispatch]
  );
};

export const useSubmitBooking = (
  bookingId: string,
  ticketFulfillmentForm: FormApi<TicketSelectionPayload>,
  paymentMethod: string,
  showSuccessPage: () => void
) => {
  const dispatch = useDispatch();

  const hasRecipients = useCallback(() => {
    const formState = ticketFulfillmentForm.getState().values;
    return (
      formState.emailConfirmationRecipient?.length > 0 ||
      formState.additionalEmailConfirmationRecipients?.length > 0 ||
      formState.smsConfirmationRecipient?.length > 0 ||
      formState.additionalSmsConfirmationRecipients?.length > 0
    );
  }, [ticketFulfillmentForm]);

  return useCallback(
    async ({ phone, ...values }) => {
      const phoneNumber = [phone?.areaCode, phone?.number]
        .filter(Boolean)
        .join('');
      if (ticketFulfillmentForm.getState().invalid)
        return ticketFulfillmentForm.submit();

      await dispatch(
        updatePurchaserDetails({
          bookingId,
          ...(phoneNumber && { phone: { number: phoneNumber } }),
          ...values,
        })
      ).unwrap();
      let updatedBooking;

      if (paymentMethod === 'payByLink') {
        try {
          await dispatch(
            payByLink({
              bookingId,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phoneNumber,
            })
          ).unwrap();
        } catch (e) {
          if (
            (e as AxiosError<ErrorResponse>)?.response?.data?.errors?.find(
              ({ message }) => message === 'The booking is already confirmed'
            )
          ) {
            showSuccessPage();
          }
        }
      } else {
        updatedBooking = await dispatch(
          triggerBookingFulfillment(bookingId)
        ).unwrap();
        showSuccessPage();
      }

      if (hasRecipients()) {
        await ticketFulfillmentForm.submit();
      }

      updatedBooking && dispatch(setCurrentBooking(updatedBooking));
    },
    [
      ticketFulfillmentForm,
      dispatch,
      bookingId,
      paymentMethod,
      showSuccessPage,
      hasRecipients,
    ]
  );
};
