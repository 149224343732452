import { Stack, Typography } from '@mui/material';
import { Button, Icon } from '@fleet/shared';
import { bookingExpiredSelector } from 'features/booking/bookingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';
import { CartTotal } from 'components/CartTotal';
import { FC, ReactNode } from 'react';
import { AddonsTable } from 'routes/tickets/checkout/AddonsTable';
import { useModal } from '@fleet/shared/hooks';
import { AncillaryModal } from 'routes/tickets/checkout/AncillaryModal';
import { useSelector } from 'store/utils';

interface AncillariesProps {
  goToNextStep: () => void;
  submitLabel: ReactNode;
}
export const Ancillaries: FC<AncillariesProps> = ({
  goToNextStep,
  submitLabel,
}) => {
  const { open, onOpen, onClose } = useModal();
  const isBookingExpired = useSelector(bookingExpiredSelector);

  return (
    <>
      <Stack spacing={2} alignItems="flex-start">
        <Typography variant="h1">
          <TransTitle i18nKey="addons" />
        </Typography>
        <Button
          onClick={onOpen}
          label={<TransButton i18nKey="addAddon" />}
          startIcon={<Icon name="add" />}
        />
        <AddonsTable hiddenColumns={['status']} />
      </Stack>
      <AncillaryModal open={open} onClose={onClose} />
      <CartTotal>
        <>
          <Button
            variant="text"
            disabled={isBookingExpired}
            label={<TransButton i18nKey="resetFields" />}
          />
          <Button
            variant="contained"
            type="submit"
            form="passengerDetails"
            disabled={isBookingExpired}
            onClick={goToNextStep}
            label={
              <>
                <Icon name="arrow-right" sx={{ mr: 1 }} />
                {submitLabel}
              </>
            }
          />
        </>
      </CartTotal>
    </>
  );
};
