import { Stack, Typography, TypographyProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import get from 'lodash/get';

interface TagProps {
  bold?: boolean;
  color?: string;
  subColor?: string;
  variant?: TypographyProps['variant'];
  text?: ReactNode;
  title?: ReactNode;
}

interface TagGroupProps extends Omit<TagProps, 'text'> {
  title?: ReactNode;
  texts?: Array<ReactNode>;
}

export const Tag: FC<TagProps> = ({
  color = 'secondary',
  subColor = 'main',
  bold,
  variant = 'body1',
  text,
  title,
}) => {
  const preparedText = typeof text === 'number' ? `${text}` : text;
  return (
    <Typography
      variant={variant}
      color="white"
      fontWeight={bold ? 'bold' : 'normal'}
      noWrap
      component="span"
      sx={{
        padding: '0.25rem 0.6125rem',
        background: (theme) => get(theme.palette, [color, subColor]),
        borderRadius: '0.25rem',
      }}
    >
      {title ? (
        <Stack direction="row" divider={<>: </>}>
          <Typography fontWeight="600" variant={variant}>
            {title}
          </Typography>
          {preparedText}
        </Stack>
      ) : (
        preparedText
      )}
    </Typography>
  );
};

export const TagGroup: FC<TagGroupProps> = ({ texts, title, ...rest }) => {
  if (!texts?.length) return null;
  return (
    <>
      {texts.map((text, idx) => (
        <Tag key={idx} text={text} {...(idx === 0 && { title })} {...rest} />
      ))}
    </>
  );
};
