import {
  Button,
  currentDateTimeFormat,
  FormProvider,
  Icon,
  Modal,
  SelectField,
  useForm,
} from '@fleet/shared';
import { formatDate } from '@fleet/shared/utils/date';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PriceWithFee } from 'components/PriceWithFee';
import { AdditionalOffer, BookedOffer } from 'dto/booking';
import {
  addAdditionalOfferToBooking,
  getBooking,
} from 'features/booking/bookingActions';
import {
  bookingAdditionalOffersSelector,
  currentBookingSelector,
  currentTripsSelector,
} from 'features/booking/bookingSelectors';
import { bookingAddonsLoadingSelector } from 'features/loading/loadingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import keyBy from 'lodash/keyBy';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store/utils';

interface AncillaryModalProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(
  (theme) => ({
    content: {
      width: 352,
    },
    typeSelection: {
      padding: '1rem',
      background: theme.palette.background.default,
    },
  }),
  { name: 'AncillaryModal' }
);

const FORM_ID = 'ancillary-form';

export const AncillaryModal: FC<AncillaryModalProps> = ({ open, onClose }) => {
  const [selectionBookedOffer, setSelectionBookedOffer] =
    useState<BookedOffer>();
  const booking = useSelector(currentBookingSelector)!;
  const { id, passengers, externalReference } = booking;
  const classes = useStyles();
  const dispatch = useDispatch();
  const additionalOffers = useSelector(bookingAdditionalOffersSelector);
  const loading = useSelector(bookingAddonsLoadingSelector);
  const currentTrips = useSelector(currentTripsSelector);
  const journeysByRefMap = useMemo(
    () => keyBy(currentTrips, 'journeyRef'),
    [currentTrips]
  );
  const journeyOptions = useMemo(() => {
    return Object.keys(journeysByRefMap).map((ref) => {
      const { originStop, departureTime, arrivalTime, destinationStop } =
        journeysByRefMap[ref];
      return {
        label: `${originStop.name} - ${destinationStop.name} (${[
          departureTime,
          arrivalTime,
        ]
          .map((date) => formatDate(date, currentDateTimeFormat))
          .join(' - ')})`,
        value: ref,
      };
    });
  }, [journeysByRefMap]);
  const additionalOffersMap = useMemo(
    () => additionalOffers && keyBy(additionalOffers, 'additionalOfferId'),
    [additionalOffers]
  );
  const onSubmit = useCallback(
    async ({ offerId, legId, passengerRefs }) => {
      const selectedAddon = additionalOffersMap?.[offerId];
      if (!selectedAddon) return;
      await dispatch(
        addAdditionalOfferToBooking({
          bookedOfferId: selectedAddon.bookedOfferId,
          offerId,
          ancillaryId: selectedAddon.ancillaryOfferParts[0].id,
          passengerRefs,
          tripCoverage: {
            tripId: selectedAddon.ancillaryOfferParts[0].tripId,
            journeyReference: externalReference,
            coveredLegIds: [legId],
          },
        })
      ).unwrap();
      onClose();
      await dispatch(getBooking(id)).unwrap();
    },
    [additionalOffersMap, dispatch, externalReference, id, onClose]
  );
  const { form, handleSubmit, values } = useForm({
    onSubmit,
    initialValues: {
      journeyRef:
        journeyOptions.length === 1 ? journeyOptions[0].value : undefined,
    },
    subscription: { values: true },
  });

  useEffect(() => {
    !open && form.reset();
  }, [form, open]);

  useEffect(() => {
    form.batch(() => {
      form.change('offerId', undefined);
      form.change('legId', undefined);
      form.change('passengerRefs', undefined);
    });
  }, [form]);

  const onLegChange = useCallback(
    (legId: string) => {
      if (!legId) return;
      setSelectionBookedOffer(
        currentTrips
          .reduce<Array<BookedOffer>>(
            (acc, { bookedOffers }) => [...acc, ...bookedOffers],
            []
          )
          .find(({ admissions }) =>
            admissions.some(({ coveredLegIds }) =>
              coveredLegIds.includes(legId)
            )
          )!
      );
    },
    [currentTrips]
  );

  useEffect(() => {
    onLegChange(values.legId);
  }, [onLegChange, values.legId]);

  const legOptions = useMemo(() => {
    if (!values.journeyRef) return [];
    const { legs } = journeysByRefMap[values.journeyRef];
    return legs.map(
      ({ arrivalTime, departureTime, originStop, destinationStop, id }) => ({
        label: [
          `${formatDate(departureTime)} ${originStop.name}`,
          `${formatDate(arrivalTime)} ${destinationStop.name}`,
        ].join(' -\n'),
        value: id,
      })
    );
  }, [journeysByRefMap, values.journeyRef]);

  const ancillariesOptions = useMemo(() => {
    if (!values.legId || !additionalOffers?.length) return [];
    return additionalOffers
      .filter(({ ancillaryOfferParts }) =>
        ancillaryOfferParts.find(({ legIds }) => legIds.includes(values.legId))
      )
      .map(({ ancillaryOfferParts, additionalOfferId }) => ({
        label: ancillaryOfferParts[0]?.products?.[0].description,
        value: additionalOfferId,
      }));
  }, [additionalOffers, values.legId]);

  const selectedOffer = useMemo<AdditionalOffer>(
    () => values.offerId && additionalOffersMap?.[values.offerId],
    [additionalOffersMap, values.offerId]
  );
  const passengerOptions = useMemo(() => {
    if (!selectionBookedOffer) return [];
    return passengers
      .filter(({ id }) =>
        selectionBookedOffer.admissions.some(({ passengerIds }) =>
          passengerIds.includes(id)
        )
      )
      .map(({ firstName, lastName, externalReference }) => ({
        label: `${firstName.value} ${lastName.value}`,
        value: externalReference,
      }));
  }, [passengers, selectionBookedOffer]);

  return (
    <Modal
      title={<TransButton i18nKey="addAddon" />}
      open={open}
      onClose={onClose}
      actionButton={
        <Button
          startIcon={<Icon name="plus" />}
          variant="contained"
          loading={loading}
          type="submit"
          form={FORM_ID}
        >
          <TransButton i18nKey="addAddon" />
        </Button>
      }
    >
      <FormProvider {...form}>
        <Stack
          spacing={3}
          id={FORM_ID}
          component="form"
          onSubmit={handleSubmit}
          className={classes.content}
        >
          {journeyOptions.length > 1 && (
            <SelectField
              name="journeyRef"
              label={<TransField i18nKey="selectJourney" />}
              options={journeyOptions}
              disabled={loading}
            />
          )}
          <SelectField
            name="legId"
            label={<TransField i18nKey="selectLeg" />}
            disabled={loading || !values.journeyRef}
            options={legOptions}
          />
          <SelectField
            name="offerId"
            label={<TransField i18nKey="selectAddon" />}
            options={ancillariesOptions}
            disabled={loading || !values.legId}
          />
          <SelectField
            name="passengerRefs"
            label={<TransField i18nKey="selectPassenger" />}
            options={passengerOptions}
            disabled={loading}
            multiple
          />
          <Stack spacing={1}>
            <Typography variant="body2">
              <TransSubtitle i18nKey="addonPrice" />
            </Typography>
            <Typography variant="body2">
              {selectedOffer ? (
                <PriceWithFee
                  {...selectedOffer.ancillaryOfferParts?.[0].price}
                />
              ) : (
                ' - '
              )}
            </Typography>
          </Stack>
        </Stack>
      </FormProvider>
    </Modal>
  );
};
