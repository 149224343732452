import { Button, Icon, Switch } from '@fleet/shared';
import { Box, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { JourneyInfo } from 'components/JourneyInfo';
import {
  bookingNotificationsPresentSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useState } from 'react';
import { ServiceTextsModal } from 'routes/tickets/searchResults/ServiceTextsModal';
import { useSelector } from 'store/utils';
import { getBookingJourneys } from 'utils/trip';

const useStyles = makeStyles(
  () => ({
    section: {
      width: '35rem',
    },
  }),
  {
    name: 'Route',
  }
);

interface RouteProps {}

export const Route: FC<RouteProps> = () => {
  const classes = useStyles();
  const [showNotifications, setShowNotifications] = useState(false);
  const [showPlaceInfo, setShowPlaceInfo] = useState(false);
  const booking = useSelector(currentBookingSelector)!;
  const hasNotifications = useSelector(bookingNotificationsPresentSelector);
  const { passengers } = booking;
  const journeys = getBookingJourneys(booking);

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Switch
          value={showPlaceInfo}
          onChange={(e) => setShowPlaceInfo(e.target.checked)}
        />
        <Typography variant="body2">
          <TransSubtitle i18nKey="seatingInfo" />
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className={classes.section}
      >
        <Typography variant="subtitle">
          <TransSubtitle
            i18nKey="passengersQty"
            values={{ count: passengers.length }}
            tOptions={{ postProcess: 'interval' }}
          />
        </Typography>
        {hasNotifications && (
          <Button
            variant="text"
            label={<TransSubtitle i18nKey="notifications" />}
            startIcon={
              <Icon
                name="info-circle"
                color="primary"
                sx={{ cursor: 'pointer' }}
              />
            }
            onClick={() => setShowNotifications(true)}
          />
        )}
      </Stack>
      <Stack mt={1} spacing={2}>
        {journeys.map(({ trips, reference }, idx) => (
          <Stack spacing={1} key={reference} className={classes.section}>
            <Typography variant="subtitle">
              <TransSubtitle i18nKey="journey" values={{ order: idx + 1 }} />
            </Typography>
            <Box
              p={2}
              sx={{
                background: (theme) => theme.palette.background.default,
              }}
            >
              <JourneyInfo
                trips={trips}
                showServiceTexts
                showFullDate
                showPlaces={showPlaceInfo}
              />
            </Box>
          </Stack>
        ))}
      </Stack>
      {showNotifications && (
        <ServiceTextsModal onClose={() => setShowNotifications(false)} />
      )}
    </>
  );
};
