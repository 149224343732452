import { RETAILER_SELECTION_LS_KEY } from 'hooks/useLocalStorage';
import { FC, useCallback, useEffect } from 'react';
import { AuthContextProps, useAuth } from 'react-oidc-context';
import { Loader } from '@fleet/shared';
import { useLocation } from 'react-router-dom';

interface AuthWrapperProps {
  appLoading: boolean;
}

export const APP_REDIRECT_URI_KEY = 'REDIRECT_URI';

const AuthWrapper: FC<AuthWrapperProps> = ({ children, appLoading }) => {
  const auth = useAuth();
  const location = useLocation();

  const isSigninOrSignoutOrSilentOidc = useCallback(
    (pathname: string) =>
      pathname === '/signout-oidc' ||
      pathname === '/signin-oidc' ||
      pathname === '/signin-silent',
    []
  );

  const storeRedirectUriIfNecessary = useCallback(
    (pathname: string, search: string) => {
      const currentRedirectUri = sessionStorage.getItem(APP_REDIRECT_URI_KEY);
      const shouldSetRedirectUri =
        !currentRedirectUri || currentRedirectUri === '/';

      if (isSigninOrSignoutOrSilentOidc(pathname)) return;

      if (shouldSetRedirectUri) {
        sessionStorage.setItem(APP_REDIRECT_URI_KEY, pathname + search);
      }
    },
    [isSigninOrSignoutOrSilentOidc]
  );

  const performSignInIfNotAuthenticated = useCallback(
    (auth: AuthContextProps) => {
      const { isLoading, isAuthenticated, activeNavigator } = auth;
      const shouldSignIn = !isLoading && !isAuthenticated && !activeNavigator;

      if (shouldSignIn) {
        auth.signinRedirect();
      }
    },
    []
  );

  useEffect(() => {
    const { pathname, search } = location;

    storeRedirectUriIfNecessary(pathname, search);
    performSignInIfNotAuthenticated(auth);
  }, [
    auth,
    location,
    storeRedirectUriIfNecessary,
    performSignInIfNotAuthenticated,
  ]);

  useEffect(() => {
    if (auth.activeNavigator === 'signoutRedirect') {
      sessionStorage.setItem(RETAILER_SELECTION_LS_KEY, '{}');
    }
  }, [auth.activeNavigator]);

  const isLoading = auth.isLoading || auth.activeNavigator || appLoading;

  if (isLoading) {
    return <Loader active size="fullscreen" />;
  }

  if (!auth.isAuthenticated) {
    return null;
  }

  return <>{children}</>;
};

export default AuthWrapper;
