import { Table, TableColumns } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PriceWithFee } from 'components/PriceWithFee';
import { TravelPassBookingPart } from 'dto/booking';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useMemo } from 'react';
import { useTable } from 'react-table';

interface TravelPassTableProps {
  data?: Array<TravelPassBookingPart['nonTripOffer']>;
}

const useStyles = makeStyles(
  (theme) => ({
    table: {
      '& thead > tr': {
        background: theme.palette.common.white,
      },
      '& tbody > tr': {
        background: theme.palette.background.default,
      },
    },
  }),
  { name: 'TravelPassTable' }
);

export const TravelPassTable: FC<TravelPassTableProps> = ({ data }) => {
  const classes = useStyles();
  const columns = useMemo<TableColumns<TravelPassBookingPart['nonTripOffer']>>(
    () => [
      {
        id: 'status',
        Header: <TransTableHead i18nKey="status" />,
        accessor: ({ fulfillments }) => (
          <TransField i18nKey={fulfillments[0].status} />
        ),
      },
      {
        id: 'number',
        Header: <TransField i18nKey="travelPassNr" />,
        accessor: ({ fulfillments }) => fulfillments[0].controlNumber,
        width: 'auto',
      },
      {
        id: 'price',
        accessor: ({ price }) => <PriceWithFee {...price} />,
        Header: <TransTableHead i18nKey="price" />,
      },
    ],
    []
  );
  const table = useTable<TravelPassBookingPart['nonTripOffer']>({
    data: useMemo(() => data ?? [], [data]),
    columns,
    getRowId: ({ fulfillments }) => fulfillments[0].id,
  });

  return (
    <Stack spacing={0.5}>
      <Typography pl={1} variant="body1" fontWeight="bold">
        <TransSubtitle i18nKey="travelPass" />
      </Typography>
      <Table<TravelPassBookingPart['nonTripOffer']>
        table={table}
        classes={{ table: classes.table }}
      />
    </Stack>
  );
};
