import { FC, ReactNode, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { TransTitle } from 'i18n/trans/title';
import { Checkbox } from '@fleet/shared/mui/Checkbox';
import { TransLabel } from 'i18n/trans/label';
import { setPdfDownload } from 'features/trip/tripActions';
import { EmailFulfillment } from 'components/ticketFulfillment/EmailFulfillment';
import { useDispatch, useSelector } from 'store/utils';
import { selectEmailSendAvailable } from 'features/user/userSelector';
import { downloadPdfFlagSelector } from 'features/trip/tripSelector';
import { SmsFulfillment } from 'components/ticketFulfillment/SmsFulfillment';
import { FormApi } from 'final-form';
import { TicketSelectionPayload } from 'routes/tickets/checkout/Overview';
import { FormProvider } from '@fleet/shared';

interface TicketFulfillmentProps {
  isSaleFlow?: boolean;
  isPassengerDetailsFormInvalid: boolean;
  purchaserInfo: {
    purchaserEmail: string | undefined;
    purchaserPhoneNumber: string | undefined;
  };
  showValidations: () => void;
  ticketFulfillmentForm: FormApi<
    TicketSelectionPayload,
    Partial<TicketSelectionPayload>
  >;
}

interface FulfillmentOptionBase {
  labelKey: 'pdfDownload' | 'email' | 'sms';
  checked: boolean;
  disabled?: boolean;
}

interface TicketFulfillmentMethod extends FulfillmentOptionBase {
  key: string;
  action: (checked: boolean) => void;
  renderComponent: () => ReactNode;
}

export const TicketFulfillment: FC<TicketFulfillmentProps> = ({
  isPassengerDetailsFormInvalid,
  showValidations,
  purchaserInfo,
  ticketFulfillmentForm,
  isSaleFlow,
}) => {
  const dispatch = useDispatch();
  const [isEmailSelected, setEmailSelected] = useState(false);
  const [isSmsSelected, setSmsSelected] = useState(false);
  const downloadPdf = useSelector(downloadPdfFlagSelector);
  const emailSendAvailable = useSelector(selectEmailSendAvailable);
  const ticketFulfillmentMethods: TicketFulfillmentMethod[] = useMemo(
    () => [
      {
        key: 'pdf',
        labelKey: 'pdfDownload',
        checked: downloadPdf,
        action: (value: boolean) => dispatch(setPdfDownload(value)),
        renderComponent: () => null,
      },
      {
        key: 'email',
        labelKey: 'email',
        checked: isEmailSelected,
        action: () => setEmailSelected(!isEmailSelected),
        disabled: isPassengerDetailsFormInvalid || !emailSendAvailable,
        renderComponent: () => (
          <EmailFulfillment
            isSaleFlow={isSaleFlow}
            purchaserEmail={purchaserInfo.purchaserEmail}
            isInline
          />
        ),
      },
      {
        key: 'sms',
        labelKey: 'sms',
        checked: isSmsSelected,
        action: () => setSmsSelected(!isSmsSelected),
        renderComponent: () => (
          <SmsFulfillment
            isInline
            purchaserPhone={purchaserInfo.purchaserPhoneNumber}
          />
        ),
      },
    ],
    [
      downloadPdf,
      isEmailSelected,
      isPassengerDetailsFormInvalid,
      emailSendAvailable,
      isSmsSelected,
      dispatch,
      isSaleFlow,
      purchaserInfo.purchaserEmail,
      purchaserInfo.purchaserPhoneNumber,
    ]
  );

  return (
    <FormProvider {...ticketFulfillmentForm}>
      <Typography variant="h2">
        <TransTitle i18nKey="ticketFulfillment" />
      </Typography>
      <Stack direction="row" spacing={3} onClick={showValidations}>
        {ticketFulfillmentMethods.map((method) => (
          <Checkbox
            key={method.key}
            checked={method.checked}
            label={<TransLabel i18nKey={method.labelKey} />}
            onChange={(e) => method.action(e.target.checked)}
            inline
            disabled={method.disabled}
          />
        ))}
      </Stack>
      {ticketFulfillmentMethods.map(
        (method) =>
          method.checked &&
          !method.disabled && (
            <div key={`content-${method.key}`}>{method.renderComponent()}</div>
          )
      )}
    </FormProvider>
  );
};
