import { searchBookings } from 'features/booking/bookingActions';
import {
  bookingsFilterSelector,
  bookingsSearchSelector,
} from 'features/booking/bookingSelectors';
import { FC, useCallback, useMemo } from 'react';
import { CardContent, Stack, Typography } from '@mui/material';
import {
  Icon,
  SearchResult,
  Table,
  TableColumns,
  Tooltip,
} from '@fleet/shared';
import { useColumnOrder, usePagination, useTable } from 'react-table';
import { makeStyles } from '@mui/styles';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { TableColumnSettings } from 'components/TableColumnSettings';
import { BookingPartStatus, BookingsSearchResult } from 'dto/booking';
import { Link } from 'react-router-dom';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { DaysAfterCount } from 'components/DaysAfterCount';
import { TransLabel } from 'i18n/trans/label';
import { TransParagraph } from 'i18n/trans/paragraph';
import { useDispatch, useSelector } from 'store/utils';
import { PaginationParams } from '@fleet/shared/dto/pagination';

interface BookingsTableProps {}

const useStyles = makeStyles(
  (theme) => ({
    thead: {
      zIndex: 0,
    },
    tableCell: {
      '&:first-of-type > div': {
        paddingLeft: 24,
      },
    },
    passengerCount: {
      whiteSpace: 'nowrap',
      textAlign: 'center',
      marginLeft: '0.5rem',
      cursor: 'pointer',
      width: 20,
      lineHeight: '20px',
      borderRadius: '50%',
      background: theme.palette.divider,
    },
  }),
  {
    name: 'BookingsTable',
  }
);

const mapStatusName = (status: BookingPartStatus, validUntil?: string) => {
  if (status === 'ON_HOLD' && validUntil) {
    return (
      <Tooltip
        placement="top"
        content={
          <TransParagraph
            i18nKey="reservedUntil"
            values={{ date: formatDate(validUntil, currentDateTimeFormat) }}
          ></TransParagraph>
        }
      >
        <Typography variant="body2">
          <TransLabel i18nKey={status} />
        </Typography>
      </Tooltip>
    );
  }
  if (status === 'RELEASED') {
    return (
      <Typography variant="body2">
        <TransLabel i18nKey="CANCELLED" />
      </Typography>
    );
  }

  return (
    <Typography variant="body2">
      <TransLabel i18nKey={status} />
    </Typography>
  );
};

const mapStatusIcon = (status: string) => {
  if (status === 'PAID') {
    return <Icon name="check" />;
  } else if (status === 'ON_HOLD') {
    return <Icon name="save" />;
  }
};

export const BookingsTable: FC<BookingsTableProps> = () => {
  const bookingSearch = useSelector(bookingsSearchSelector);
  const dispatch = useDispatch();
  const classes = useStyles();
  const filter = useSelector(bookingsFilterSelector);
  const columns = useMemo<TableColumns<BookingsSearchResult>>(
    () => [
      {
        id: 'bookingReference',
        accessor: ({ id, code }) => (
          <Stack direction="row" spacing="4px">
            <Link to={`/booking/${id}`}>{code}</Link>
          </Stack>
        ),
        Header: <TransTableHead i18nKey="bookingReference" />,
      },
      {
        id: 'type',
        accessor: () => <Icon name="oneway" />,
        Header: <TransTableHead i18nKey="type" />,
        width: 50,
      },
      {
        id: 'journeyOrigin',
        accessor: ({ journeyOrigin }) =>
          journeyOrigin && (
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography variant="body2">
                {formatDate(journeyOrigin.time, currentDateTimeFormat)}
              </Typography>
              <Typography variant="body2" fontWeight={600}>
                {journeyOrigin.name}
              </Typography>
            </Stack>
          ),
        Header: <TransTableHead i18nKey="journeyOrigin" />,
        width: 'auto',
      },
      {
        id: 'journeyDestination',
        accessor: ({ journeyDestination, journeyOrigin }) =>
          journeyDestination && (
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography variant="body2">
                {formatDate(journeyDestination.time, currentDateTimeFormat)}
              </Typography>
              <DaysAfterCount
                startDate={journeyOrigin.time}
                endDate={journeyDestination.time}
              />
              <Typography variant="body2" fontWeight={600}>
                {journeyDestination.name}
              </Typography>
            </Stack>
          ),
        Header: <TransTableHead i18nKey="journeyDestination" />,
        width: 'auto',
      },
      {
        id: 'passengers',
        accessor: ({ passengers }) => {
          const [first, second, ...rest] = passengers.map(
            ({ firstName, lastName }) => `${firstName} ${lastName}`
          );
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              {[first, second, rest.length && '…'].filter(Boolean).join(', ')}
              {!!rest.length && (
                <Tooltip content={rest.join(', ')}>
                  <div className={classes.passengerCount}>
                    {`+${rest.length}`}
                  </div>
                </Tooltip>
              )}
            </Stack>
          );
        },
        Header: <TransTableHead i18nKey="passengers" />,
        width: 'auto',
      },
      {
        id: 'paymentStatus',
        accessor: ({ status, validUntil }) => (
          <Stack direction="row" spacing={1}>
            {mapStatusIcon(status)}
            {mapStatusName(status, validUntil)}
          </Stack>
        ),
        Header: <TransTableHead i18nKey="paymentStatus" />,
      },
      {
        id: 'createdOn',
        accessor: ({ createdOn }) =>
          formatDate(createdOn, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="createdOn" />,
      },
    ],
    [classes.passengerCount]
  );
  const data = useMemo(() => bookingSearch?.items ?? [], [bookingSearch]);

  const page = useMemo(() => {
    if (bookingSearch) {
      const { limit = 10, offset } = bookingSearch;
      return offset / limit;
    }
    return 0;
  }, [bookingSearch]);

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) => {
      await dispatch(
        searchBookings({
          ...filter,
          pagination: {
            offset: paginationParams.offset ?? 0,
            limit: paginationParams.limit ?? 10,
          },
        })
      ).unwrap();
    },
    [dispatch, filter]
  );
  const initialState = useMemo(() => ({ pageSize: 10 }), []);
  const getRowId = useCallback((row) => row.id, []);
  const table = useTable<BookingsSearchResult>(
    {
      data,
      columns,
      initialState,
      pageCount: -1,
      useControlledState: (state) =>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useMemo(() => ({ ...state, pageIndex: page }), [state, page]),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: bookingSearch?.totalCount,
      getRowId,
    },
    usePagination,
    useColumnOrder
  );

  return (
    <SearchResult results={bookingSearch?.items.length} loading={false}>
      <Table
        classes={{
          thead: classes.thead,
          cell: classes.tableCell,
        }}
        caption={
          <CardContent>
            <Stack direction="row" alignItems="center">
              <Typography variant="subtitle" fontWeight="700">
                <TransSubtitle i18nKey="searchResults" />
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ ml: 2 }}>
                <TransSubtitle
                  i18nKey="bookingQty"
                  values={{ count: bookingSearch?.totalCount }}
                  tOptions={{ postProcess: 'interval' }}
                />
              </Typography>
              {table && <TableColumnSettings table={table} />}
            </Stack>
          </CardContent>
        }
        table={table}
      />
    </SearchResult>
  );
};
