import { OfferCard } from 'components/OfferCard';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { TripOffer, OfferAccommodationType, TripLeg } from 'dto/trip';
import { TransLabel } from 'i18n/trans/label';
import { FC, useCallback, useMemo } from 'react';
import { TabPanel, Tabs } from '@fleet/shared';
import {
  unselectOffer,
  resetTripOffers,
  selectTripOffer,
} from 'features/trip/tripActions';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import { useDispatch, useSelector } from 'store/utils';
import { selectSelectedOffers } from 'features/trip/tripSelector';

interface LegOffersProps {
  reference?: string;
  isShown?: boolean;
  isOutbound: boolean;
  leg: TripLeg;
  offers: TripOffer[];
}

const useStyles = makeStyles(
  () => ({
    offersSelection: {
      justifyContent: 'flex-end',
      '&$hidden': {
        display: 'none',
      },
    },
    hidden: {},
  }),
  { name: 'LegOffers' }
);
export const LegOffers: FC<LegOffersProps> = ({
  isShown,
  offers,
  reference,
  isOutbound,
}) => {
  const dispatch = useDispatch();
  const selectedOffersInfo = useSelector(selectSelectedOffers);
  const classes = useStyles();
  const offersByType = useMemo(
    () =>
      offers.reduce<{ [key in OfferAccommodationType]?: Array<TripOffer> }>(
        (offersMap, offer) => {
          const offerAccommodationType =
            OfferAccommodationType[offer.offerAccommodationType];
          return {
            ...offersMap,
            [offerAccommodationType]: [
              ...(offersMap?.[offerAccommodationType] ?? []),
              offer,
            ],
          };
        },
        {}
      ),
    [offers]
  );
  const offerTypes = useMemo(() => {
    const order = [
      OfferAccommodationType.SEAT,
      OfferAccommodationType.BED,
      OfferAccommodationType.COMPARTMENT,
    ];
    return (Object.keys(offersByType) as OfferAccommodationType[]).sort(
      (a, b) => order.indexOf(a) - order.indexOf(b)
    );
  }, [offersByType]);

  const showOfferTypes = useMemo(
    () =>
      Object.keys(offersByType).some((type) =>
        ['BED', 'COMPARTMENT'].includes(type)
      ),
    [offersByType]
  );

  const getSelectOfferHandler = useCallback(
    (offer: TripOffer) => () => {
      const selectedOffers = selectedOffersInfo.trips;
      if (
        selectedOffersInfo.reference &&
        selectedOffersInfo.reference !== reference
      ) {
        return dispatch(resetTripOffers(reference!));
      }

      const offerForSameLeg = selectedOffers.find(({ coveredLegIds }) =>
        isEqual(offer.coveredLegIds, coveredLegIds)
      );
      const alreadySelectedOffer = find(selectedOffers, offer);
      alreadySelectedOffer && dispatch(unselectOffer(alreadySelectedOffer.id));
      dispatch(
        selectTripOffer({
          reference: reference!,
          isOutbound,
          offers: offerForSameLeg
            ? alreadySelectedOffer
              ? selectedOffers.filter(({ id }) => id !== offer.id)
              : selectedOffers.map((selected) =>
                  selected.id === offerForSameLeg.id ? { ...offer } : selected
                )
            : [...selectedOffers, offer],
        })
      );
    },
    [selectedOffersInfo, reference, dispatch, isOutbound]
  );

  return (
    <Grid
      container
      columns={6}
      spacing={0.5}
      className={classNames(classes.offersSelection, {
        [classes.hidden]: !isShown,
      })}
    >
      {isShown &&
        (showOfferTypes ? (
          <Grid item xs={6}>
            <Tabs>
              {offerTypes.map((type) => (
                <TabPanel
                  value={type}
                  key={type}
                  label={
                    <TransLabel i18nKey={type as OfferAccommodationType} />
                  }
                >
                  <Grid container columns={6} spacing={0.5}>
                    {offersByType[type as OfferAccommodationType]!.map(
                      (offer) => (
                        <OfferCard
                          key={offer.id}
                          {...offer}
                          onClick={getSelectOfferHandler(offer)}
                        />
                      )
                    )}
                  </Grid>
                </TabPanel>
              ))}
            </Tabs>
          </Grid>
        ) : (
          offers.map((offer) => (
            <OfferCard
              key={offer.id}
              {...offer}
              onClick={getSelectOfferHandler(offer)}
            />
          ))
        ))}
    </Grid>
  );
};
