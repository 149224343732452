import { Button, Input, useFormContext } from '@fleet/shared';
import { useFieldArray } from '@fleet/shared/form/hooks/useFieldArray';
import { Icon, Tooltip } from '@fleet/shared/mui';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SearchTabsContext, SearchType } from 'components/SearchTabsContext';
import { resetSearch } from 'features/trip/tripActions';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  FC,
  MouseEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'store/utils';
import { v4 } from 'uuid';
import { PassengerCard } from './PassengerCard';
import { TransLabel } from 'i18n/trans/label';

interface SearchPassengersProps {
  name: string;
}

const useStyles = makeStyles((theme) => ({
  value: {
    alignSelf: 'center',
    '& .MuiButton-text': {
      ...theme.typography.body2,
      textDecoration: 'underline',
      padding: 0,
      minWidth: 0,
    },
  },
  noUnderlineButton: {
    textDecoration: 'none!important',
  },
  control: {
    alignSelf: 'center',
    '& svg': {
      strokeWidth: '2px',
    },
    '&$hidden': {
      display: 'none',
    },
  },
  hidden: {},
}));

const CARD_WIDTH = 262;

export const SearchPassengers: FC<SearchPassengersProps> = ({ name }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentTab } = useContext(SearchTabsContext);
  const isTravelPassSearch = useMemo(
    () => currentTab?.type === SearchType.travelPass,
    [currentTab?.type]
  );
  const [manualEntryValue, setManuelEntryValue] = useState('');
  const [controlsShown, setControlsShown] = useState(false);
  const [isManualEnter, setIsManualEnter] = useState(false);
  const form = useFormContext();
  const initialValue = useMemo(
    () => [
      {
        type: 'PERSON',
        externalReference: v4(),
      },
    ],
    []
  );

  const { fields, meta } = useFieldArray(name, {
    validate: (value) => (value.length ? undefined : 'required'),
    initialValue,
    subscription: { value: true, invalid: true },
    form,
  });
  const { value, push, pop, remove } = fields;

  const handlePassengerChange = useCallback(
    (e: MouseEvent, action: 'add' | 'remove') => {
      e.stopPropagation();
      if (action === 'add') {
        push({ type: 'PERSON', externalReference: v4() });
      } else {
        pop();
      }
      dispatch(resetSearch());
      history.replace('/search');
    },
    [dispatch, history, pop, push]
  );

  const applyManualEntry = useCallback(() => {
    const passengerCount = parseInt(manualEntryValue, 10);
    if (!isNaN(passengerCount)) {
      const diff = passengerCount - value.length;
      if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          push({ type: 'PERSON', externalReference: v4() });
        }
      } else {
        for (let i = 0; i < -diff; i++) {
          pop();
        }
      }
      setIsManualEnter(false);
      setControlsShown(false);
    }
  }, [manualEntryValue, value.length, push, pop]);

  const onClickOutside = useCallback(() => {
    if (meta.invalid && !isManualEnter) {
      form.submit();
    } else if (controlsShown) {
      if (isManualEnter) {
        applyManualEntry();
        requestAnimationFrame(() => setIsManualEnter(false));
        setManuelEntryValue('');
      }
      setControlsShown(false);
    }
  }, [form, controlsShown, meta, applyManualEntry, isManualEnter]);

  return (
    <Tooltip
      arrow={false}
      open={controlsShown}
      theme="light"
      placement="bottom-end"
      disableFocusListener
      offset={[0, 0]}
      PopperProps={{
        sx: {
          visibility: value.length < 1 ? 'hidden' : 'inherit',
          zIndex: 1000,
          '& > .MuiTooltip-tooltipPlacementBottom': {
            marginTop: '24px!important',
            maxWidth: `calc((${CARD_WIDTH}px + 16px) * 3 + 16px)`,
            padding: 0,
          },
        },
      }}
      content={
        <ClickAwayListener onClickAway={onClickOutside}>
          <Grid
            container
            columns={value.length > 3 ? 3 : value.length}
            spacing={2}
            rowSpacing={2}
            p={2}
            pt={0}
          >
            {isManualEnter ? (
              <Input
                sx={{ marginLeft: '8px' }}
                label={
                  <Box sx={{ marginLeft: '8px', marginTop: '8px' }}>
                    <TransLabel i18nKey="numberOfPassengers" />
                  </Box>
                }
                type="number"
                value={manualEntryValue}
                placeholder={value.length ? String(value.length) : '1'}
                onChange={(e) => setManuelEntryValue(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    applyManualEntry();
                    setControlsShown(false);
                    setManuelEntryValue('');
                    requestAnimationFrame(() => setIsManualEnter(false));
                  }
                }}
              />
            ) : (
              controlsShown &&
              value.map((_, idx) => (
                <Grid item xs={1} key={idx}>
                  <PassengerCard
                    totalPassengers={value.length}
                    width={CARD_WIDTH}
                    number={idx + 1}
                    name={`${name}[${idx}]`}
                    onRemove={() => remove(idx)}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </ClickAwayListener>
      }
    >
      <Stack direction="row" justifyContent="space-between" px={1}>
        <IconButton
          disabled={isTravelPassSearch || !value.length}
          className={classes.control}
          onClick={(e) => handlePassengerChange(e, 'remove')}
        >
          <Icon name="minus" size={18} />
        </IconButton>
        <Stack alignItems="flex-start" className={classes.value}>
          <Button
            classes={{
              text: classes.noUnderlineButton,
            }}
            color="secondary"
            disabled={isTravelPassSearch}
            variant="text"
            onClick={() => {
              setIsManualEnter(true);
              setControlsShown(true);
            }}
          >
            <Typography variant="subtitle">
              <TransSubtitle
                i18nKey="passengersQty"
                values={{ count: value.length }}
                tOptions={{ postProcess: 'interval' }}
              />
            </Typography>
          </Button>
          <Button
            variant="text"
            disabled={!value.length}
            onClick={() => setControlsShown(true)}
          >
            <TransButton i18nKey="details" />
          </Button>
        </Stack>

        <IconButton
          className={classes.control}
          disabled={isTravelPassSearch}
          onClick={(e) => handlePassengerChange(e, 'add')}
        >
          <Icon name="plus" size={20} />
        </IconButton>
      </Stack>
    </Tooltip>
  );
};
