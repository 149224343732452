import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTitle = createTrans({
  addComment: <Trans i18nKey="title.addComment" defaults="Add new comment" />,
  addCorporateCode: (
    <Trans
      i18nKey="title.addCorporateCode"
      defaults="Add corporate agreement code"
    />
  ),
  addPromoCode: (
    <Trans i18nKey="title.addPromoCode" defaults="Add promo code" />
  ),
  addons: <Trans i18nKey="title.addons" defaults="Add-ons" />,
  booking: <Trans i18nKey="title.booking" defaults="Booking {{ num }}" />,
  bookingCompleteWithLink: (
    <Trans
      i18nKey="title.bookingCompleteWithLink"
      defaults="Booking <1>{{reference}}</1> complete"
    />
  ),
  bookingSearch: (
    <Trans i18nKey="title.bookingSearch" defaults="Booking search" />
  ),
  bookingSummary: (
    <Trans i18nKey="title.bookingSummary" defaults="Booking summary" />
  ),
  cancelSelected: (
    <Trans i18nKey="title.cancelSelected" defaults="Cancel selected" />
  ),
  checkout: <Trans i18nKey="title.checkout" defaults="Checkout" />,
  chooseRetailer: (
    <Trans i18nKey="title.chooseRetailer" defaults="Choose retailer" />
  ),
  compartmentPreferences: (
    <Trans
      i18nKey="title.compartmentPreferences"
      defaults="Compartment preferences"
    />
  ),
  concessions: <Trans i18nKey="title.concessions" defaults="Concessions" />,
  deleteConfirmation: (
    <Trans i18nKey="title.deleteConfirmation" defaults="Delete confirmation" />
  ),
  detailedAvailability: (
    <Trans
      i18nKey="title.detailedAvailability"
      defaults="Detailed availability"
    />
  ),
  journeyNotifications: (
    <Trans
      i18nKey="title.journeyNotifications"
      defaults="Journey notifications"
    />
  ),
  overview: <Trans i18nKey="title.overview" defaults="Overview" />,
  passengerDetails: (
    <Trans i18nKey="title.passengerDetails" defaults="Passenger details" />
  ),
  paySelectedAdmissions: (
    <Trans
      i18nKey="title.paySelectedAdmissions"
      defaults="Pay selected admissions"
    />
  ),
  payerDetails: <Trans i18nKey="title.payerDetails" defaults="Payer details" />,
  payment: <Trans i18nKey="title.payment" defaults="Payment" />,
  paymentDetails: (
    <Trans i18nKey="title.paymentDetails" defaults="Payment details" />
  ),
  paymentMethod: (
    <Trans i18nKey="title.paymentMethod" defaults="Payment method" />
  ),
  putBookingOnHold: (
    <Trans i18nKey="title.putBookingOnHold" defaults="Put booking on hold" />
  ),
  putBookingOnHoldWithLink: (
    <Trans
      i18nKey="title.putBookingOnHoldWithLink"
      defaults="Booking <1>{{reference}}</1> on hold"
    />
  ),
  refundSelected: (
    <Trans i18nKey="title.refundSelected" defaults="Refund selected" />
  ),
  removeBookingFee: (
    <Trans i18nKey="title.removeBookingFee" defaults="Remove booking fee" />
  ),
  searchResults: (
    <Trans i18nKey="title.searchResults" defaults="Search results" />
  ),
  searchResultSettings: (
    <Trans
      i18nKey="title.searchResultSettings"
      defaults="Search result settings"
    />
  ),
  seatPreference: (
    <Trans i18nKey="title.seatPreference" defaults="Seat preference" />
  ),
  ticketFulfillment: (
    <Trans i18nKey="title.ticketFulfillment" defaults="Ticket fulfillment" />
  ),
  travelPassUsage: (
    <Trans i18nKey="title.travelPassUsage" defaults="Travel pass usage" />
  ),
  viewStops: <Trans i18nKey="title.viewStops" defaults="View stops" />,
});
